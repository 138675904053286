@use "sass:map";
@import "commonstyle";
$darktheme-colors: ("bgcolor": #0D0F12,
	"background-color": #0C1214,
	"bordercolor": #333333,
	"theme-color": #959595,
	"commonbg-color":#0d0f12,
	"textcolor": #FFFFFF,
	"popup-bg-color":#171819);
$whitetheme-colors: ("bordercolor": $whiteborder-color,
	"bgcolor": #EDEDED,
	"color": #4C4F53,
	"commonbgcolor":#F7F7F7,
	"panel-color":#69737A,
	"background-color": #353D4B,
	"textcolor": #666666,
	"base_bgcolor":#EEEEEE);

#ribbon ol.breadcrumb {
	font-size: 13px;
	font-weight: 600;
}
.ng-select.ng-select-disabled>.ng-select-container {
	background: none !important; 
          }
table {
	width: 100%;
	background-color: inherit !important;
	color: $primary-color;
	border-collapse: collapse;
}

.sub-btn {
	font-size: 13px;
	border-radius: 15px;
	background-color: transparent !important;
	padding: 3px 15px;
}

.scroll-dashboard {
	&::-webkit-scrollbar-track {
		// -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $common-color;
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: $common-color;
	}

	// overflow-y: scroll !important;
	overflow-y: auto !important;
	overflow-x: hidden;
	z-index: 1;
	margin-top: 52px;
}

.demo {
	label {
		input[type=checkbox].checkbox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		input[type=radio].radiobox+span {
			&:before {
				border: 1px solid $box-border;
			}
		}

		&:hover {
			input[type=checkbox].checkbox+span {
				&:before {
					border-color: #E77317;
				}
			}
		}

		margin-bottom: 4 !important;
	}

	.checkbox {
		i {
			border-color: $subtext-color;
		}

		padding-left: 22px;
	}

	.radio {
		padding-left: 22px;
	}

	#demo-setting+form {
		display: none;
	}

	position: absolute;
	top: 5px;
	right: 0;
	width: 160px;
	z-index: 902;
	background: #F1DA91;

	span {
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		height: 30px;
		width: 30px;
		border-radius: 5px 0 0 5px;
		background: #F1DA91;
		padding: 2px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;

		i {
			font-size: 17px;
		}

		&:hover {
			i {
				color: #333 !important;
			}
		}
	}

	form {
		section {
			margin: 0 !important;
		}
	}

	.btn {
		font-size: 13px !important;
		line-height: 1.5 !important;
		border-radius: 3px !important;
		padding: 3px 7px !important;
		height: auto !important;
	}
}

.demo.activate {
	right: 0 !important;
	box-shadow: -11px 12px 23px rgba(0, 0, 0, .2);
	padding: 5px 10px 10px;

	#demo-setting+form {
		display: block;
	}

	span {
		i {
			&:before {
				content: "\f00d";
			}
		}

		left: -30px;
	}
}

.demo-liveupdate-1 {
	font-size: 12px !important;
	position: absolute !important;
	left: 33px;
	top: 15px;
	display: block;
	z-index: 2;
	font-weight: 700;
	padding: 1px 61px 3px 7px;
}

.demo-btns {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-bottom: 7px;
	}
}

.demo-icon-font {
	font-size: 14px;
	margin-bottom: 6px;
	padding-top: 4px;
	padding-bottom: 4px;

	&:hover {
		background-color: rgba(86, 61, 124, .1);
		text-decoration: none;
	}
}

.demo-modal {
	width: auto;
	max-width: 600px;
	z-index: 1;
}

// .demo-red {
// 	color: #DF413E;
// }
// .demo-orange {
// 	color: #DAA03E;
// }
.demo-border {
	border: 1px solid rgba(0, 0, 0, .2);
}

.demo-vertical-uislider {
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;

	div {
		height: 200px;
		margin: 0 0 0 10px;
		display: inline-block;
	}

	&:first-child {
		margin-left: 15px;
	}
}

.toggle-demo {
	position: absolute;
	top: 10px;
	right: -8px;
	display: block;
	width: 154px;
	height: auto;
	z-index: 99;
	opacity: .9;

	&:hover {
		opacity: 1;
	}

	.btn-group {
		width: 100px;
		float: left;
	}

	span {
		display: inline-block;
		float: left;
		margin-right: 5px;
		line-height: 21px;
		color: #757575;
	}
}

#header {
	.menu-item {
		margin: 0px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 60px;
		cursor: pointer;
	}

	.menu-item_pading {
		padding: 0 10px;
	}

	.menu-left-item {
		padding: 0 10px;
	}

	.company-title {
		font: normal normal bold 12px/19px Open Sans;
	}

	.company-name {
		font: normal normal normal 14px/19px Open Sans;
	}

	#fullscreen {
		.svg-icon {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 60px;
			height: 45px !important;
			margin-top: -3px;
		}
	}

	.notification-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	#activity.activity-svg {
		background : {
			repeat: no-repeat;
			position: center;
		}

		width: 60px !important;
		height: 45px !important;
	}

	.collapseMenu {
		.collaspe-menu-svg {
			background : {
				repeat: no-repeat;
				position: center;
			}

			width: 40px !important;
			height: 30px !important;
		}

		.collaspe-menu-sub-svg {
			background-repeat: no-repeat;
			width: 45px !important;
			height: 45px !important;
		}
	}

	.header-btn {
		&:hover {
			border-color: $common_base_color !important;
			background: $common_base_color !important;
			color: $common-color !important;
		}
	}

	.dropdown-icon-menu {
		ul {
			li {
				.btn {
					&:hover {
						border-color: $common_base_color !important;
						background: $common_base_color !important;
						color: $common-color !important;
					}
				}
			}
		}
	}
}


.knobs-demo {
	div {
		display: inline-block;
		margin-left: 30px;
	}

	&:first-child {
		margin-left: 0;
	}
}


.widget-box {
	background-color: $primary-color;
	box-shadow: 10px 10px 45px 0 rgb(19 46 72 / 20%);
	border-radius: 5px;
	overflow: hidden;
	padding: 20px;

	.title-box {
		.title {
			.ed-icon {
				transform: translateY(-50%) scale(0.8);
				transform-origin: left center;
			}

			.title-lbl {
				font-size: 18px;
				line-height: 18px;
				padding-left: 35px;

				&:before {
					left: 25px;
				}
			}

			.title-lbl.no-icon {
				padding-left: 0;

				&:before {
					display: none;
				}
			}
		}
	}

	.icon-box {
		width: 70px;
		min-width: 70px;
		height: 70px;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;

		.number {
			font-size: 30px;
		}

		.ed-icon {
			width: 30px;
			height: 30px;
		}
	}

	h3 {
		font-size: 24px;
		line-height: 24px;
	}

	p {
		font-size: 18px;
		line-height: 20px;
	}
}

.activity.ajax-dropdown {
	&:first-child {
		margin: 0;
		padding: 0;
	}
}

.activity {
	.ajax-notifications {
		border-bottom: none;
		border-top: none;
	}
}

.applist-box1 {
	.applist-row {
		padding: 0 15px;
		flex-wrap: wrap;

		.app-box {
			text-align: center;
			padding: 20px 0px;
			font-weight: 500;

			.module-icon {
				width: 45px;
				height: 45px;
				background-repeat: no-repeat;
				display: inline-block;
				background-size: 100%;
			}
		}
	}
}

.fixed-navigation {
	.nav-demo-btn {
		display: none !important;
	}
}

.minified {
	.nav-demo-btn {
		display: none !important;
	}

	#left-panel {
		z-index: 904;
		padding-top: 23px !important;
	}
}

.smart-rtl {
	.demo {
		.margin-right-5 {
			margin-right: 0 !important;
		}
	}
}

.listFinal {
	font-size: 16px;
	padding: 5px;

	&::before {
		content: ">";
		font-size: 20px;
		padding: 5px;
	}
}

.minified {
	#clearCache_text {
		display: none;
	}

	#help_text {
		display: none;
	}

	.userlabel {
		display: none !important;
	}

	.body-image {
		width: 40px !important;
		height: 40px !important;
		margin: auto;
	}

	.colDisplay {
		padding: 12px 0px !important;
	}

	#clearCache .iom_btn {
		padding: 3px 13px !important;
	}
}


.header-dropdown-list {
	li {
		.dropdown-toggle {
			margin-top: 6px;
		}
	}
}




.dash-icon {
	background-image: url(/assets/img/application/dashboard.svg);
}

.purchase-icon {
	background-image: url(/assets/img/application/purchase.svg);
}

.inv-icon {
	background-image: url(/assets/img/application/invoice.svg);
}

.file-icon {
	background-image: url(/assets/img/application/filemanager.svg);
}

.vmi-icon {
	background-image: url(/assets/img/application/vmi.svg);
}

.contract-icon {
	background-image: url(/assets/img/application/contract.svg);
}

.edi-icon {
	background-image: url(/assets/img/application/edi.svg);
}

.header-dropdown-list {
	.dropdown-menu {
		li {
			a {
				border-radius: 0 !important;
				font-size: 14px;
			}
		}

		padding: 4px;
		overflow: auto;
		max-height: 200px;

		&::-webkit-scrollbar {
			width: 0px;
		}

		border-radius: 10px;
	}
}

.nav-demo-btn {
	display: none
}

body:not(.menu-on-top) .nav-demo-btn {
	display: block;
	padding: 6px 5px;
	margin: 5px 10px;
	width: auto;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	font-size: 12px;
	white-space: normal
}

.ajax-dropdown-app>:first-child {
	margin: 0 0 3px;
	padding: 0 0 9px;
}

.page-footer {
	position: fixed;
	// z-index: 1051;
	border-top: transparent;
}

.hidden-menu .page-footer {
	padding-left: 20px;
}

.dashHeadLocation {
	color: $primary-color;
	display: flex;
	list-style: none;
	padding: 0;
}

.listitems {
	font-size: 24px;
}

.main-nav {
	text-align: right;
	font: normal normal normal 16px/22px Open Sans;
	letter-spacing: 0px;
	color: $base-color;
	opacity: 1;
	padding-top: 11px;
}

.main-container {
	display: flex;

	flex : {
		direction: column;
		grow: 1;
	}

	padding : {
		left: 0;
		right: 10px;
	}
}

// .border-line {
//     background: #0C1214 0% 0% no-repeat padding-box;
//     border: 1px solid #3B424C;
//     background: #0C1214 0% 0% no-repeat padding-box;
//     border: 1px solid #3B424C;
//     opacity: 1;
// }
#content {
	background: $table-bgcolor !important;
}

// .footerTextColor {
// 	color: $panel-color !important;
// }

.theme-header-color {
	color: $base-color !important;
}

.theme-text-color {
	color: $base-color !important;
}

.header-menu {
	font: normal normal normal 14px/19px Open Sans;
}

.notification-body>li {
	border: 1px solid #e3e3e3 !important;
}

.flag.flag-en {
	background-position: -144px -154px;
}

#left-panel {
	position: absolute;
	top: 52px;
	z-index: 904;
	padding-top: 2px;

	nav {
		ul {
			li.active {
				>a {
					&:before {
						right: -19px;
						content: none !important;
					}
				}
			}
		}
	}
}

.font-size-14 {
	font-size: 14px !important;
}

.notification-body {
	.subject {
		color: $subtext-color !important;
	}
}


// .userAccountSvg {
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/user.png");
// }
// .tools_Svg{
// 	width: 45px !important;
// 	height: 45px !important;
// 	background-image: url("../img/iconSvg/tools.pngks");
// }

.iombackground {
	background : {
		repeat: no-repeat;
		position: center;
	}

	max-height: 100vh;
	height: 100vh;


	background: #C04848;
	/* fallback for old browsers */
	background: linear-gradient(rgb(102, 102, 102, 0.9), rgb(102, 102, 102, 0.9)),
	url(/assets/img/register.png);
	/* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(rgb(102, 102, 102, 0.9), rgb(102, 102, 102, 0.9)),
	url(/assets/img/register.png);
	/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
	background-size: cover;
	background-repeat: no-repeat;
}

.userAccountSvg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/user.png");
}

.toolsvg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/tools.png");
}

.support-svg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/support.png");
}

.conection-svg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/conections.png");
}

.data-svg {
	background : {
		repeat: no-repeat;
		position: center;
	}

	width: 45px !important;
	height: 45px !important;
	background-image: url("../img/iconSvg/data2x.png");
}

.NextconfirmLabel {
	font-size: 13px;
	letter-spacing: 0px;
	color: $primary-color;
	margin-right: 15px;
	padding: 0px 15px;
	// border: 1px solid #666666;
	border-radius: 20px;
}

.fa-exclamation {
	color: red;
	font-size: x-large;
	padding: 0px 0px;
}

.ui-dialog-titlebar-close:before {
	content: "\f00d";
	font-family: FontAwesome;
	font-style: normal;
	font-weight: 400;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 20px;
}

.ui-dialog .ui-dialog-titlebar-close {
	position: absolute;
	right: 0.3em;
	top: 50%;
	width: 19px;
	margin: -20px 0 0 0;
	padding: 1px;
	height: 18px;
	font-size: 20px;
	font-weight: 400;
	line-height: 19.5px;
	text-shadow: none;
}

.blacktooltip {
	text-align: left;
	z-index: 1000;
	position: fixed;
	width: 150px;
	// min-height: 140px;
	height: auto;
	font: normal normal normal 13px/18px Open Sans;
	border-radius: 10px;

	.insideDivUl {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0rem;

		li {
			height: 38px;
			line-height: 38px;
			cursor: default;
		}
	}
}
.error-blacktooltip {
	text-align: left;
	z-index: 1000;
	position: absolute !important;
	width: 150px;
	// min-height: 140px;
	height: auto;
	font: normal normal normal 13px/18px Open Sans;
	border-radius: 10px;

	.insideDivUl {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		margin-top: 0;
		margin-bottom: 0rem;
	}
}

@media (max-width: 979px) {
	.page-footer {
		padding: 5px 14px 0;
	}
}

@media (min-width: 321px) and (max-width : 479px) {
	#left-panel {
		top: 162px !important;
	}
}

@media (min-width: 480px) and (max-width : 576px) {
	#left-panel {
		top: 162px !important;
	}
}

@media (min-width: 577px) and (max-width : 803px) {
	#left-panel {
		top: 155px !important;
	}

	.comapnyLbl {
		justify-content: flex-start !important;
	}
}

@media (max-width: 320px) {
	#left-panel {
		top: 255px !important;
	}
}

@media (min-width: 577px) and (max-width : 803px) {
	#left-panel {
		top: 155px !important;
	}
}

@media(max-width:992px) {
	#fullscreen {
		.svg-icon {
			width: 40px !important;
		}
	}

	#header #activity.activity-svg {
		width: 40px !important;
	}

	#header .notification-svg {
		width: 40px !important;
	}

	#header .menu-item_pading {
		padding: 0 10px 0 0 !important;
	}

	.comapnyLbl {
		justify-content: flex-start !important;
	}
}

@media (max-width: 500px) {

	.header-formbtn,
	.advace-header-formbtn {
		width: 100% !important;
	}
}

@media (min-width: 1200px) and (max-width:1375px) {

	.header-formbtn // ,.advace-header-formbtn 

		{
		width: 60px !important;
	}
}

@media (max-width:1300px) {
	.comapnyLbl {
		justify-content: flex-start !important;
	}

	.manageHeader {
		// margin-bottom: 15px !important;
	}
}

@media screen and (max-width: 1545px) {
	.scroll-tbs {
		height: 173px !important;
		overflow-y: auto !important;
	}
}

@media (min-width: 1440px) and (max-width : 1800px) {
	.englishText {
		font-size: 8px !important;
	}

	.germanText {
		font-size: 6px !important;
	}

	.englishTextFuture {
		font-size: 10px !important;
	}

	.germanTextFuture {
		font-size: 10px !important;
	}
}

@media (max-width : 1440px) {

	.englishText,
	.englishTextFuture,
	.germanTextFuture,
	.germanText {
		width: 15% !important;
	}
}

.scroll-content::-webkit-scrollbar {
	// display: none;
	width: 1px;
}

.scroll-content {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
	overflow-x: none;
}

.MessageBoxButtonSection button {

	border-radius: 15px;
	background: transparent;
	border: 2px solid $common_base_color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $primary-color !important;
	font-weight: 400 !important;
}

.MessageBoxButtonSection button:hover {
	border-radius: 15px;
	background: $common_base_color !important;
	border: 2px solid $common_base_color !important;
	font-size: 13px;
	padding: 3px 15px;
	color: $common-color !important;
}


.contact_infrmtn_sctn {
	.detailLbl {
		color: $primary-color;
		font-size: 14px;

		.commoninputStyle {
			.form-control {
				height: 40px !important;
				border-radius: 6px !important;
				background-color: map.get($darktheme-colors, "popup-bg-color");
				border: 1px solid map.get($darktheme-colors, "popup-bg-color");
				font-size: 14px;
				color: $primary-color;
			}
		}
	}
}

.comapny_details_form {
	.detailLbl {
		color: $primary-color;
		font-size: 14px;

		.commoninputStyle {
			.form-control {
				height: 40px !important;
				border-radius: 6px !important;
				background-color: map.get($darktheme-colors, "popup-bg-color");
				border: 1px solid map.get($darktheme-colors, "popup-bg-color");
				font-size: 14px;
				color: $primary-color;
			}
		}
	}
}


.detail_save_btn,
.detail_save_Btn,
.approveBtn,
.gotoEdi,
.iom_btn,
.detailInfo_save_btn {
	border-radius: 15px;
	background: transparent;
	border: 2px solid $common_base_color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 19.5px;
	font-weight: 400;

	&:hover {
		border-color: $common_base_color !important;
		background: $common_base_color !important;
	}

}

.iom_btn[disabled] {
	box-shadow: none;
	cursor: not-allowed;
	opacity: .65;
}

.editDisabled {
	background: none;
}

.editEnabled {
	background-color: map.get($darktheme-colors, "popup-bg-color") !important;
}

.border-line {
	background: transparent !important;
	// border: 1px solid map.get($whitetheme-colors, "bordercolor");
	border: none;
}

.nextBtn {
	font-size: 13px;
	// width: 178px;
	background: transparent 0% 0% no-repeat padding-box !important;
	border-color: $common_base_color !important;
	letter-spacing: 0px;
	border: 2px solid $common_base_color !important;
	border-radius: 15px;
	color: $primary-color !important;
	padding: 3px 15px;

	&:hover {
		background: $common_base_color !important;
		border: 2px solid $common_base_color;
		color: $common-color !important;
	}

}

.nextstep {
	font-size: 13px;
	// width: 178px;
	background: transparent 0% 0% no-repeat padding-box !important;
	border-color: $common_base_color !important;
	letter-spacing: 0px;
	border: 2px solid $common_base_color !important;
	border-radius: 15px;
	color: $primary-color !important;
	padding: 3px 15px;

	&:hover {
		background: $common_base_color !important;
		border: 2px solid $common_base_color;
		color: $common-color !important;
	}

}

.cancelBtn {
	// top: 728px;
	// left: 560px;
	// width: 100px;
	// height: 38px;
	border: 2px solid $common_base_color;
	border-radius: 15px;
	color: $primary-color !important;
	font-size: 13px;
	padding: 3px 15px !important;

	&:hover {
		background: $common_base_color !important;
		border: 2px solid $common_base_color;
		color: $common-color !important;
	}

}

.butttonBluecolor {
	background-color: $secondary-color !important;
	border: 2px solid $common_base_color !important;
	color: $primary-color !important;
	// top: 861px;
	// left: 1139px;
	// width: 161px;
	// height: 38px;
	border-radius: 15px;
	padding: 3px 15px;

	&:hover {
		background: $common_base_color !important;
		border: 2px solid $common_base_color;
		color: $common-color !important;
	}
}

.lightBackgroundColor {
	background-color: $secondaryFocus !important;
}

// .linkGenBtn{
// 	width: 105px !important;
// }
.noscroll {
	overflow-y: hidden !important;
}

.outerMainDiv {
	padding: 20px !important;
	max-height: calc(100vh - 100px) !important;
	overflow-y: auto !important;
}

.PaySctn input[type=radio]:checked:after {
	// color: $common_base_color;
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: $common_base_color;
	content: '';
	display: inline-block;
	visibility: visible;
	border: 2px solid map.get($whitetheme-colors, "textcolor");
	cursor: pointer;

}

.gridSectionFirst {
	.payMethodSection {
		padding: 11px 10px 10px 15px;
		margin: 20px 0px 0px 0px;
		width: 100%;
		border-radius: 10px;
		min-height: 123px;

		.payHead {
			span {
				font-size: 16px;
				font-weight: 600;
			}
		}

		.payLink {
			padding: 3px 5px 0px 15px;

			span {
				font-size: 14px;
				color: $common_base_color;
				cursor: pointer;
			}
		}

	}

	.techContactSection {
		padding: 11px 30px 10px 30px;
		margin: 20px 0px 0px 0px;
		width: 100%;
		border-radius: 10px;
		height: auto;

		.techHead {
			font-size: 16px;
			font-weight: 600;
		}

		&>.row {
			display: block;
		}
	}
}

.next_payment {
	padding: 5px 10px;
	border-radius: 20px;
	font-size: 14px;
	// margin: 310px auto 10px auto;
	min-width: 60%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}

.applicationHead {
	margin-top: 15px;
	height: 34px;
	opacity: 1;
	border: unset;
}

.tranhoverTable input[type=radio]:checked:after {
	width: 20px;
	height: 20px;
	border-radius: 15px;
	top: -1px;
	left: -1px;
	position: relative;
	background-color: $common_base_color;
	content: '';
	display: inline-block;
	visibility: visible;

}

.tranhoverTable input[type=radio] {
	-webkit-appearance: none;
	width: 20px;
	height: 20px;
	border: 1px solid darkgray;
	border-radius: 50%;
	outline: none;
	// box-shadow:0 0 5px 0px gray inset;
}

.noBorder {
	border: none !important;
}

.activeUser .dt-toolbar-footer {
	border: none;
	background: none;
}

.userEditTextBox {
	background: $primary-color 0% 0% no-repeat padding-box !important;
}

.nameDiv,
.headDiv {
	color: $secondary-color;
}

.user-table .table tbody tr:first-child {
	td {
		border-top: none !important;
	}

}

.rightBorder {
	border-right: 2px solid $common_base_color !important;
	border-left: 2px solid $common_base_color !important;
}

.topBorder {
	border-top: 2px solid $common_base_color !important;
}

.bottomBorder {
	border-bottom: 2px solid $common_base_color !important;
}

.selected {
	background-color: $common_base_color !important;
	border-color: $common_base_color !important;
}

.gridSectionTwo {
	.billingContactSection {
		height: auto;
		padding: 10px 30px;
		margin: 20px 0px 0px 20px;
		width: 96%;
		border-radius: 10px;

		&>.row {
			display: block;
		}
	}
}

.fixed-head {
	position: sticky;
	top: -20px;
}

.detail-div {
	.fixed-head {
		position: sticky;
		top: 0px;
		z-index: 100;
	}
}

table.table-bordered.dataTable {
	border-collapse: none !important;
}

.user-table {
	.dt-toolbar-footer {
		display: none !important;
	}
}

.dt-toolbar-footer {
	background: none !important;
	width: 100% !important;
	font-size: none !important;
	overflow: hidden;
	padding: none !important;
	border-top: none !important;
	-webkit-box-shadow: none !important;
	-moz-box-shadow: none !important;
	-ms-box-shadow: none !important;
	box-shadow: none !important;
}

.dataTables_processing {
	display: block;
	text-indent: -99999px;
	border: 0px !important;
	box-shadow: none !important;
	height: 55px;
	width: 55px;
	z-index: 999;
	margin-top: 100px !important;
	background-position: center !important;
	margin-left: 35px;
}

.lstposTbale {
	.dataTables_processing {
		display: block;
		text-indent: -99999px;
		border: 0px !important;
		box-shadow: none !important;
		z-index: 999;
		margin-top: 15px !important;
		background-position: center !important;
	}

	.dt-toolbar {
		background: none !important;
		border: none !important;
		display: none;
	}
}

.dt-toolbar {
	background: none !important;
	border: none !important;
}

table.pending-list-table {
	border-collapse: separate !important;

	thead {
		tr {
			th {
				&:first-child {
					border-radius: 5px 0px 0px 0px !important;
				}

				&:last-child {
					border-radius: 0px 5px 0px 0px !important;
				}
			}
		}
	}

	tbody {
		tr {
			&:last-child {
				td {
					&:first-child {
						border-radius: 0px 0px 0px 5px !important;
					}

					&:last-child {
						border-radius: 0px 0px 5px 0px !important;
					}
				}
			}

		}
	}
}

.table {
	thead {
		&:first-child {
			tr {
				&:first-child {
					th {
						border-bottom: none !important;
					}
				}
			}
		}
	}
}

button.disabled {
	cursor: no-drop !important;
	pointer-events: none !important;
	opacity: 0.3;
}



.badge-disable-iom {
	display: inline-block;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	font-size: 13px !important;
	padding: 4px 13px !important;
	border-radius: 10rem;
	cursor: no-drop;
	background: transparent;
	border: 2px solid $common_base_color;
}

.MessageBoxMiddle .MsgTitle {
	letter-spacing: -1px;
	font-size: 24px;
	font-weight: 300;
	margin-left: -28px;
}

.pure-checkbox {
	input[type=checkbox]+label {
		&:after {
			display: none !important;
		}
	}

	input[type=checkbox] {
		position: relative !important;
		height: 15px !important;
		width: 15px !important;
	}
}

.dropdown-list.fixMultiSelect {
	display: block !important;
	position: static !important;
}

.custom-scroll::-webkit-scrollbar {
	height: 5px;
	width: 5px;
}

#userRolePopup {
	max-height: 445px !important;

	footer {
		padding: 1rem 2rem;
		margin-top: 2rem;
	}

	.dropdown-multiselect__caret {
		display: none !important;
	}
}

.serviceTheme {
	border-radius: 10px !important;
}

.rolePopUp {
	max-height: calc(100% - 100px);
	overflow-y: auto;
	border-radius: 10px;
}

.user-role-form {
	.multiselect-dropdown {
		.dropdown-btn {
			border: unset !important;

			.selected-item-container {
				.selected-item {
					border: 1px solid $common_base_color !important;
					background: $common_base_color !important;
					color: $common-color !important;
					max-width: unset !important;
					padding: 3px 10px !important;
					border-radius: 20px !important;
					margin-right: 10px !important;

					a {
						color: $common-color !important;
					}
				}
			}
		}

		.filter-textbox {
			input {
				background: $widget-bgcolor !important;
			}
		}

		.multiselect-item-checkbox {
			input {
				+ {
					div {
						&:before {
							color: $primary-color !important;
							border: 2px solid $primary-color !important;
							border-radius: 5px;
						}

						&:focus {
							color: $primary-color !important;
							border: 2px solid $primary-color !important;
						}
					}
				}
			}

			input[type=checkbox] {
				&:checked {
					+ {
						div {
							&:before {
								background: transparent !important;
							}
						}
					}
				}
			}
		}
	}
}

.price_calcultation_form {
	.multiselect-dropdown {
		width: 98.5% !important;
		height: 40px !important;
		padding: 4px 0px 0px;
		font: 13px/16px "Open Sans", Helvetica, Arial, sans-serif;

		.dropdown-btn {
			border-radius: 10px !important;
			padding: 8px 12px !important;

			span {
				padding-left: 5px;
			}

			.selected-item-container {
				.selected-item {
					border: 1px solid $common_base_color !important;
					background: $common_base_color !important;
					color: $common-color !important;
					max-width: unset !important;
					padding: 0px 5px !important;
					border-radius: 20px !important;
					box-shadow: 1px 1px $common_base_color !important;

					a {
						color: $common-color !important;

					}
				}
			}

			.dropdown-multiselect__caret {
				display: none !important;
			}
		}

		.filter-textbox {
			input {
				background: $widget-bgcolor !important;
			}
		}

		.multiselect-item-checkbox {
			input {
				+ {
					div {
						&:before {
							color: $primary-color !important;
							border: 2px solid #a09d9d !important;
							border-radius: 5px;
						}

						&:focus {
							color: $primary-color !important;
							border: 2px solid #a09d9d !important;
						}
					}
				}
			}

			input[type=checkbox] {
				&:checked {
					+ {
						div {
							&:before {
								background: transparent !important;
							}
						}
					}
				}
			}
		}

		.dropdown-multiselect__caret {
			top: 5px !important;
		}
	}
}

.btn.focus {
	box-shadow: 0 0 0 0.2rem rgb(182 235 0 / 25%) !important;
}

.btn {
	&:focus {
		box-shadow: 0 0 0 0.2rem rgb(182 235 0 / 25%) !important;
	}
}

.toolTipHideUserRole {
	width: 285px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-bottom: 0;
}

.roleLabel {
	width: 340px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: justify;
}

.tooltipRole {
	&:hover {
		cursor: pointer;

		.tooltiptextRole {
			visibility: visible;

			.insideDivUl {

				li:last-child {
					border-bottom: unset;
				}
			}
		}
	}

	position: relative;
	display: inline-block;

	.tooltiptextRole {
		visibility: hidden;
		// width: 125%;
		text-align: center;
		border-radius: 6px;
		padding: 5px 5px;
		word-break: break-all;
		position: fixed;
		z-index: 1;
		left: 38%;
		top: 0%;
		text-align: left;
		width: auto;
		// min-height: 140px;
		font: normal normal normal 13px/18px Open Sans;
		border-radius: 10px;
		height: auto;

		.insideDivUl {
			list-style: none;
			padding: 5px;
			display: flex;
			flex-direction: column;
			margin-top: 0;
			margin-bottom: 0rem;

			li {
				height: 38px;
				line-height: 38px;
				cursor: default;
			}
		}
	}

	.toolTipRoleTitle {
		padding: 5px 5px !important;
		left: inherit !important;
		width: max-content;
		z-index: 1000;
	}
}

.minified {
	.profile-image-box {
		display: none;
	}

	ul.navList {
		margin-top: -25px !important;
	}
}

.spin-loader {
	height: 25px;
	width: 25px;
	display: inline-block;
	margin: 13px 0px 0px 10px;
}

.big-spin-loader {
	width: 50px;
	height: 50px;
	position: absolute;
	left: 0px;
	right: 0px;
	margin: 5% auto;
	-ms-transform: translate(0, -50%);
	-webkit-transform: translate(0, -50%);
	transform: translate(0, -50%);
	top: 50%;
	z-index: 99;
}

.serviceTheme {
	.modal_header {
		padding: 0 13px;
		text-align: left;
		// font: normal normal 700 16px/22px Open Sans !important;
		letter-spacing: 0px;
		opacity: 1;
		color: $primary-color;
		font-size: 24px;
	}
}

.card-fields {
	.form-fields-wrapper {
		.form-fields {
			width: calc(100% - 35px);
			margin-left: auto;
		}
	}
}

.cardNumber {
	margin-bottom: 7px;
}

.cvv {
	margin-top: 22px;
}

/* Checkbox style */
.checkbox-container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 17px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 7px;
	background-color: transparent;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
	background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
	background-color: transparent;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
//   .checkbox-container .checkmark:after {
// 	left: 7px;
//     top: 1px;
//     width: 8px;
//     height: 16px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     transform: rotate(45deg);
//   }
.pagination>li:last-child>a,
.pagination>li:last-child>span {
	border-bottom-right-radius: 5px !important;
	border-top-right-radius: 5px !important;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
	border-bottom-left-radius: 5px !important;
	border-top-left-radius: 5px !important;
}

button[disabled] {
	cursor: no-drop !important;
}

html {
	input[disabled] {
		cursor: no-drop !important;
	}
}

.modalContent {
	padding: 20px;
	height: 200px;

	.modalBody {
		border-radius: 10px !important;
		padding: 0px;
	}

	.modalHead {
		border: unset !important;
		padding: 0px;
		margin-bottom: 10px;
		display: inline-block;

		.modalTitle {
			text-align: left;
			font: normal normal bold 16px/22px Open Sans;
			letter-spacing: 0px;
		}
	}
}

.table-wrapper table {
	margin-bottom: 0px !important;
}

.dt-toolbar-footer {
	padding: 5px 0px;
}

.footer-padding {
	.dt-toolbar-footer {
		padding: 5px 10px;
	}
}

.dropdown-list {
	box-shadow: none !important;
}

.header-formbtn {
	width: auto;
	margin-top: 1px !important;
	border: none;
	background: $primary-color !important;
	text-align: center !important;
	letter-spacing: 0;
	color: $tablehover-color !important;
	font-weight: bold;
	font: normal normal normal 13px/20px Open Sans !important;
	border-radius: 15px;
	margin-left: 10px;
}

.subPlanData {
	padding: 20px 0 0;

	.content {
		margin-top: 10px;

		p {
			font-size: 16px !important;
		}
	}
}

.comingSoon {
	text-align: left;
	z-index: 1001;
	position: absolute;
	width: auto;
	max-width: max-content;
	height: auto;
	padding: 10px;
	font: normal normal normal 13px/18px Open Sans;
	border-radius: 10px;
	opacity: 1;
	font-weight: bold;
	background-color: $common_base_color !important;
	margin-top: -10px;
}

.crossBtn {
	text-align: right;
	// font: normal normal normal 16px/20px Font Awesome 5 Free;
	letter-spacing: 0px;
	color: $common_base_color;
	opacity: 1;
	font-size: 20px;
	font-weight: bold;
	// padding:10px
}

// .crossDiv{
//     margin-top: -14px;
//     margin-right: -10px;
// }
input {
	&:-internal-autofill-selected {
		background-color: red !important;
	}
}

.borderClassForm input,
.borderClassForm select {
	border-color: #dc3545 !important;
}

.radioMargin {
	margin-top: -2px;
}

.pe-none {
	pointer-events: none;
}

.greentext {
	color: $common_base_color !important;
}

.textalignlabel {
	text-align: center;
	width: auto;
	padding: 10px 15px;
}

.user-list {
	font-size: 14px;
	min-height: 170px;
	max-height: 320px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	&::-webkit-scrollbar-track {
		// border: 1px solid #202124;
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #CCC;
		// border: 1px solid #202124;
		border-radius: 5px;
	}
}

.ui-datepicker {
	border-radius: 5px !important;
	z-index: 2000 !important;

	select.ui-datepicker-month {
		margin: 0 2px;
		width: 47%;
	}

	select.ui-datepicker-year {
		margin: 0 2px;
		width: 47%;
	}
}

.ui-datepicker {
	td {
		.ui-state-default {
			color: $primary-color;
		}
	}

	.ui-datepicker-next {
		span {
			background: none !important;
		}
	}

	.ui-datepicker-prev {
		span {
			background: none !important;
		}
	}
}

.ui-datepicker-prev {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-next {
	span {
		background-image: none !important;
	}
}

.ui-datepicker-prev {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f053";
	}
}

.ui-datepicker-next {
	&:before {
		font-family: FontAwesome;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		font-weight: normal;
		align-items: center;
		justify-content: center;
		background: $primary-color;
		z-index: 9;
		padding-top: 4px;
		content: "\f054";
	}
}

.setCircleTest {
	color: #F1DA91;
	font-size: 10px !important;
}

.filter-top {
	font-size: 16px !important;
	letter-spacing: 0px;
	font-weight: 400;
	font-family: "Open Sans", Arial, Helvetica, Sans-Serif;

	.link {
		color: $common_base_color !important;
		font-weight: 400;
		font-size: 14px;
	}
}

.dashbaordlabel {
	background-color: unset !important;
	border: 1px solid $header-bgcolor !important;
	border-radius: 5px !important;
}
.companyslabel {
	background-color: unset !important;
	border: 1px solid #0C1214 !important;
	border-radius: 5px !important;
}
.backdrop {
	position: absolute;
	// left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: black;
	// opacity: 0.5;
}

#overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	bottom: 0;
	z-index: 1060;
}

.search_status {
	color: $content-bg-color;
	background-color: unset !important;
}

.user-list-select {
	color: $content-bg-color;
	background-color: unset !important;
}

.select_search_status {
	color: $content-bg-color;
	background-color: unset !important;
}

.drop {
	color: #000 !important;
}

table.positionTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.bulkTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.dataTable tbody tr.selected,
table.dataTable tbody tr.selected:hover {
	background-color: $common_base_color !important;
	border-color: $whiteborder-color;
}


.setCirclerej {
	color: #eb0042;
	font-size: 10px !important;
}

.algntblTranscation {
	border-radius: 8px;
}

.transactionsHeads {
	top: 359px;
	left: 233px;
	height: 36px;
	background: #3B424C;
	border-color: #3B424C;
	opacity: 1;
	padding-left: 10px;
	border-left: transparent;
	border-right: transparent;
}

.amount-div {
	width: 30%;
	float: right;
	height: 100px;
	padding: 10px;
	position: relative;
	top: 148px;
	border-radius: 5px;

	.total-amount {
		font-size: 16px;
		font-weight: 600
	}

	.amountDiv {
		float: right;
		font-size: 16px;
		font-weight: 500;
		text-align: right;
	}
}

.scroll-tbs {
	height: 170px !important;
	overflow-y: auto !important;

	margin : {
		top: 10px;
		left: -3px;
	}

	// -ms-overflow-style: none;
	// scrollbar-width: none;

	&::-webkit-scrollbar {
		width: 5px !important;
		height: 5px !important;
	}

	&::-webkit-scrollbar-track {
		// border: 1px solid #202124;
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #CCC;
		// border: 1px solid #202124;
		border-radius: 5px;
	}
}

.idTooltip,
.btnTooltip {
	text-align: center;
	z-index: 99999;
	position: absolute;
	// width: auto;
	// height:auto;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		z-index: 99999;
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: 150px;
		word-break: break-all;
	}

}
.companyTooltip {
	text-align: center;
	z-index: 1000;
	position: fixed;
	// width: auto;
	// height:auto;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: fit-content;
		word-break: break-all;
		height: auto;
	}

}
.advace-header-formbtn {
	// height: 41px;
	margin-top: 18px !important;
	// width: 100px;
	border: none;
	background: $primary-color !important;
	text-align: center !important;
	letter-spacing: 0;
	color: $tablehover-color !important;
	font-weight: bold;
	font: normal normal normal 13px/20px Open Sans !important;
	border-radius: 30px;
}

.error_text {
	font-size: 13px;
}

.error-desc {
	font-size: 13px !important;
}

li.active {
	a.error {
		color: $btndanger-color !important;
	}
}

.CodeMirror-wrap pre {
	word-break: break-word;
}

.codePopup {
	width: 1500px !important;
}

.CodeMirror {
	height: 600px !important;
	font-size: 16px;
}

// *********************Ebill css********************************************

.wizard .plusBtn {
	position: absolute;
	right: 20px;
	cursor: pointer;
	top: 13px;
}

.wizardCircleWidth {
	width: 20%;
	flex: 1 0 20% !important;
}

.wizardTextWidth {
	width: 75%;
	flex: 1 0 75% !important;
	// padding: 0px 10px;
	display: flex;
	align-items: flex-start !important;
}

.wizardDiv {
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50%;
	font-size: 24px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
}

b.badges {
	position: absolute;
	cursor: pointer;
	font-size: 10px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	padding: 2px 4px 3px;
	text-align: center;
	line-height: normal;
	content: "";
	top: -1px;
	right: -1px;
	transform: translate(25%, -25%);
	width: 25px;
	height: 25px;
	color: #111;
	background-color: $common_base_color;
	font-weight: 800;
	display: flex;
	justify-content: center;
	align-items: center;
}

.accordianXpand {
	transition: all 0.2s ease-in-out 0s !important;
}

.wizard#accordionExpandWizard {
	border-radius: 10px;
	color: $header-bgcolor;
	padding: 20px 20px 0;
}

.wizardCircleWidth {
	width: 20%;
	flex: 1 0 20% !important;
}

.line {
	width: 5%;
	z-index: 1;
	position: relative;
}

.common_wizardItemContainer .common_wizardItems {
	// width: 35% !important;
}

.wizard-items {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;
}

.green-border {
	border: 1px solid $common_base_color!important;
}

#wizard .wizard-container {
	display: flex;
	flex-direction: row;
}

#wizard {
	border-radius: 15px;
	// padding: 10px 20px;
}


.wizardTextWidth {
	width: 99%;
	flex: 1 0 60% !important;
	// padding: 0px 10px;
	display: flex;
	align-items: flex-start !important;
}

.form-group .form-control {
	position: relative;
	float: left;
	width: 100%;
	margin-bottom: 0;
	font-size: 12px;
	height: 40px;
}

.wizard-billeritem {
	width: 30%;
	border-radius: 10px;
	z-index: 100;
	position: relative;
	min-height: 90px;
	display: flex;
}

.line-1 {
	height: 1px;
	width: 100%;
	z-index: 200;
	position: relative;
	top: 50% !important;
}

.billerFormSection formly-wrapper-form-field div {
	font-size: 13px !important;
}

.biller_tabSection .nav-item {
	font-size: 14px !important;
}

.form-group {
	.form-control {
		position: relative;
		// z-index: 2;
		float: left;
		width: 100%;
		margin-bottom: 0;
		font-size: 12px;
		height: 40px;
	}

	textarea {
		&.form-control {
			height: unset !important;
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
		}
	}

	select {
		border-top-left-radius: 10px !important;
		border-bottom-left-radius: 10px !important;
	}
}

.communicationPanel {
	display: block !important;
}

.billerFormSection formly-wrapper-form-field div {
	font-size: 13px !important;
}

.includePdf formly-field-radio div .custom-radio {
	padding-left: 2.5rem;
}

.includePdf formly-field-radio div {
	display: flex;
}

.ebillForm {
	formly-field-select {
		select {
			option {
				background-color: #202124 !important;
			}
		}
	}

	.commmonWrap {
		formly-group {
			width: 100% !important;
		}
	}
}

.commmonWrap {
	formly-group {
		width: 100% !important;
	}
}

table.positionTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.bulkTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px;
}

table.dataTable thead>tr>th.sorting_disabled {
	padding-right: 9px !important;
}

// *********************end Ebill css********************************************
.searchFilterSection,
.searchFilters {
	.commonfield {
		.company_nameField {
			.ng-select-container {
				background: unset !important;
				border: unset !important;
			}
		}

		.ng-select.ng-select-opened>.ng-select-container {
			background: unset !important;
		}

		.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
			box-shadow: unset !important;
		}
	}
}

.disableForm {
	.commonfield {
		.company_nameField {
			.ng-select-container {
				background: unset !important;
				// border: unset !important;
			}
		}

	}

	.panelSector {
		.ng-select.ng-select-opened>.ng-select-container {
			background: unset !important;
		}

		.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
			box-shadow: unset !important;
		}

		.ng-select.ng-select-single .ng-select-container {
			height: 41px !important;
		}
	}
}

.agents {


	.ng-select.ng-select-single .ng-select-container {
		width: 395px;
	}
}

.descriptionagents {
	.ng-select.ng-select-single .ng-select-container {
		width: 395px;
	}
}

nav {
	ul {
		li {
			a b {
				right: 17px !important;
				top: 12px !important;
				font-size: 14px !important;
			}
		}
	}
}

nav ul ul {
	padding: 0px;

	li {
		padding: 6px;
	}
}

nav ul ul li>a:hover {
	background: $common_base_color!important;
}

nav ul ul li>a:hover {
	background: #666 !important;
}

nav ul b .fa-plus-square-o:before {
	content: "\f107" !important;
}

nav ul b .fa-minus-square-o:before {
	content: "\f106" !important;
}

.text-area {
	label.col-form-label {
		margin-right: -20px;
	}
}

.requiredSection {
	.ng-select-container {
		border-color: #dc3545 !important;
	}

}

.requiredSector {
	color: #dc3545 !important;
	font-size: 13px !important;
}

.infoIcon {
	height: 20px;
	width: 20px;
	cursor: pointer;
	filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}

.dataTable tbody tr td {
	font-size: 13px;
}

.emailMessage {
	border-radius: 10px !important;
	height: 100px;
	padding: 15px 7px;
	margin-top: 27px;
}

.green-label {
	color: #beeb20 !important;
}

.form-control.ng-invalid.is-invalid {
	border-color: #dc3545 !important;
}

.companySelect {
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		border: 1px solid #6666 !important;
		font-size: 13px !important;
	}

	.requiredSector {
		color: #dc3545;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
		color: #beeb20 !important;
		font-size: 13px !important;
	}
}

.select-div {
	display: flex !important;
	align-items: flex-start !important;
	height: 20px;

	span.checkbox-text {
		display: inline-block !important;
		width: 80px !important;
		text-align: left;
	}
}

.defaultDot {
	height: 15px;
	width: 15px;
	border-radius: 50%;
	display: inline-block;
	position: relative;
}

.stepsinout-form select,
.businessconnect-form select {
	-moz-appearance: none;
	/* Firefox */
	-webkit-appearance: none;
	/* Safari and Chrome */
	appearance: none;
}

.stepsinout-form,
.businessconnect-form {
	.input-group-append {
		.input-group-text:before {

			margin-left: -6px;
			padding-left: 6px;
		}
	}
}

.stepsinout-form {
	.stepsDown {
		.form-group {
			input {
				// margin-top: -9px !important;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
			}
		}
	}
}

.btn-primary {
	&:focus {
		box-shadow: none;
	}
}

.stepsinout-form.formly,
.businessconnect-form.formly {
	.input-group-text {
		font-size: 13px;
		// width: 29px;
		font-weight: 400;
		color: $secondaryFocus;
		text-align: center;
		border: unset !important;
	}
}

.stepsinout-form {
	.custom-checkbox {
		.custom-control-label {
			&::before {
				width: 1.2rem;
				height: 1.3rem;
				top: .5rem;
			}

			&::after {
				top: 0.4rem;
				left: -1.7rem;
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
}

.fileExport {
	.form-group {
		display: flex;
	}
}

.stepsinout-form {
	.fileExport {
		formly-field-input {
			margin-left: 20px;
		}
	}

	.custom-radio {
		.custom-control-label {
			// &::before {
			// 	top: .6rem;
			// }

			margin-right: 20px;
		}

		.custom-control-input {
			&:checked~.custom-control-label {
				&::after {
					background: none;
				}
			}
		}
	}

	.custom-radio.custom-control {
		display: inline-block;
	}
}

.invoice-tab-header {
	.nav-tabs {
		>li {
			text-align: center;

			>a {
				margin: auto;
			}
		}
	}
}

.transactions-detail-document {
	table {
		table-layout: fixed !important;

		td {
			overflow: hidden !important;
			text-overflow: ellipsis !important;

			.tr-filename {
				word-break: break-all !important;
			}
		}
	}
}

.error-div {
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 30px;

	div.error-div-div {
		width: 300px;
		width: 300px;
		height: 200px;
		border-radius: 8px;
		padding: 5px;
	}
}

table.dataTable {
	clear: both;
	// margin-top: 6px !important;
	margin-bottom: 6px !important;
	max-width: none !important;
	border-collapse: separate !important;
}

table.positionTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px !important;
}

table.positionTable thead tr th:last-child {
	border-radius: 0px 5px 0px 0px !important;
}

table.bulkTable thead tr th:first-child {
	border-radius: 5px 0px 0px 0px !important;
}

table.bulkTable thead tr th:last-child {
	border-radius: 0px 5px 0px 0px !important;
}

.englishText {
	font-size: 10px !important;
	padding: 2px 5px !important;
	// width: 15% !important;
}

.germanText {
	font-size: 8px !important;
	padding: 3px 5px !important;
	// width: 16% !important;
}

.englishTextFuture {
	width: 15% !important;
}

.germanTextFuture {
	width: 15% !important;
}

.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list {
	position: fixed;
	width: auto;
}

.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list[hidden] {
	position: absolute;
}

.includePdf {
	formly-field-radio {
		div {
			display: flex;

			.custom-radio {
				padding-left: 2.5rem;
			}
		}
	}
}

.minified nav>ul>li>a>.menu-item-parent {
	top: 1px !important;
}

.minified nav>ul>li>ul {
	min-height: fit-content;
}

.minified nav ul ul li>a:hover {
	background: $common_base_color !important;
}

.page-title {
	font-size: 16px !important;
	margin-left: unset !important;
	font-weight: 400;
	font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
}

.itemEditButton {
	font-size: 37px;
	text-align: center;
	font: normal normal normal 30px/20px Open Sans;
	letter-spacing: -0.3px;
	border: 2px solid $common_base_color;
	border-radius: 19px;
	font-size: 13px;
	padding: 3px 15px;

	&:hover {
		background: $common_base_color !important;
		border-color: $common_base_color !important;
		box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
	}

	&:active {
		background: $common_base_color !important;
		border-color: $common_base_color !important;
	}
}

.tooltipA {
	position: relative;
	display: inline-block;
}

/* Tooltip text */
.tooltipA .tooltiptext {
	visibility: hidden;
	min-width: 180px;
	text-align: center;
	padding: 5px 10px;
	border-radius: 6px;
	/* Position the tooltip text - see examples below! */
	position: absolute;
	z-index: 100;
	top: -5px;
	right: 40%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
	visibility: visible;
}

.owl-dt-calendar-table .owl-dt-calendar-header {
	display: none;
}

.NextconfirmLabels {
	font: normal normal normal 13px/24px Open Sans;
	letter-spacing: 0px;
	margin-right: 15px;
	padding: 4px 10px;
	border-radius: 20px;
	margin-top: -7px;
	margin-left: 10px;
}

.disabledProp {
	.input-group {
		cursor: not-allowed;
	}

	select {
		cursor: not-allowed;
		pointer-events: none;
	}

	.custom-radio {
		cursor: not-allowed;
		pointer-events: none;
	}
}

.disableall {
	.input-group {
		cursor: not-allowed;
	}

	select {
		cursor: not-allowed;
		pointer-events: none;
	}

	.custom-radio {
		cursor: not-allowed;
		pointer-events: none;
	}

	input {
		cursor: not-allowed;
		pointer-events: none;
	}
}

.modal-footer {
	border-top: none;
}

// .pdf2xml-radio .custom-control {
//     display: inline-block !important;
// 	&:first-child {
// 		margin-right: 10px;
// 	}
// }
.ebillForm header {
	display: block;
	padding: 8px 0px;
	border-bottom: 1px dashed #333;
	background: transparent !important;
	font-size: 15px;
	font-weight: 500;
	margin: 0 0px 20px;
}

.formatIcon {
	display: flex !important;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}


.user-ebill {
	.commmonWrap {
		margin-bottom: 10px !important;
	}
}

.has-danger .form-control {
	border-color: #f86c6b !important;
}

.form-control.ng-invalid.ng-touched {
	border-color: $invalid-bordercolor !important;
}

.xslt-input {
	width: 92%;
	font-size: 12px;
}

table.bulkList {

	margin-top: 10px !important;

}

.setCirclerej {
	color: #eb0042;
	font-size: 10px !important;
}
.setCirclereg{
	color: #3276b1;
	font-size: 10px !important;
            }
.setCircleSend{
              color: #d9720f;
	font-size: 10px !important;
} 
.archiveImport {
	border-radius: 15px;
	border: 2px solid $common_base_color;
	font-size: 13px;
	padding: 3px 15px;
	line-height: 19.5px;
	font-weight: 400;
}

li.active .last-li-child {
	border-top-right-radius: 8px;
}

.transaction {
	li {
		&:first-child {
			a {
				border-top-left-radius: 8px !important;
			}
		}
	}
}

.fixed-header {
	position: sticky;
	top: 0px;
	z-index: 100;
}

.field_without_addon {
	.form-control {
		border-top-right-radius: 10px !important;
		border-bottom-right-radius: 10px !important;
	}
}

.selectedFormats {
	position: relative;
	border-radius: 10px !important;
	height: 40px;
	padding: 0 44px 0 10px;
}

.formatRow {
	margin: 0px 10px 0px 0px;
}

.copyIcon {
	position: absolute;
	font-size: 16px;
	right: 20px;
	top: 20px;
}

.fileFormat_toolTip {
	//   visibility:hidden;
	width: 100%;
	//   max-width: 270px;
	//   height: 130px;
	//   background-color: black;
	color: #fff;
	//   text-align: center;
	padding: 5px 35px;
	//   border-radius: 20px;
	//   position: absolute;
	//   z-index: 1;
	//   top: -65px;
	//   right: -295px;
	overflow-y: scroll;
}

.fileFormat_toolTip::-webkit-scrollbar {
	display: none !important;
}

.fileFormat_toolTip {
	-ms-overflow-style: none !important;
	/* IE and Edge */
	scrollbar-width: none !important;
	/* Firefox */
}

.fileFormatContent {
	border-radius: 10px !important;
	border: none !important;

	.modal-header {
		border-bottom: unset !important;
	}

	.modal-footer {
		border-top: unset !important;
		padding: 10px 12px 10px 12px;
	}
}

.fileFormatfooter {
	border-top: unset !important;
}

.commonSeperator {
	padding: 0 10px 0 10px;
}

.commonAchor {
	width: 30%;
	color: $common_base_color !important;
}

.foramatDetail {
	width: 64%;
}

.commonFileFormat {
	font-size: 14px;
}

.fileFormatContent .modal-footer {
	border-top: unset !important;
	padding: 10px 12px !important;
}

.setCirclerej {
	color: #eb0042;
	font-size: 10px !important;
}

.setCircleTest {
	color: #F1DA91;
	font-size: 10px !important;
}

.setCircleactive {
	color: #00eba9;
	font-size: 10px !important;
}
.setCirclereg{
	color: #3276b1;
	font-size: 10px !important;
            }
.setCircleSend{
              color: #d9720f;
	font-size: 10px !important;
} 
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
	font-size: 13px;
}

.stepsinout-form.formly,
.fileuploadform-form .businessconnect-form.formly {
	.input-group-text {
		font-size: 13px;
		// width: 29px;
		font-weight: 400;
		color: $secondaryFocus;
		text-align: center;
		border: unset !important;
	}
}

.cdk-global-scrollblock {
	overflow-y: auto !important;
}
.iom-button {
	border-radius: 20px;
	background: transparent;
	border: 2px solid $common-base-color;
	font-size: 13px;
	padding: 7px 28px;

	&:hover {
		background: $common-base-color !important;
		color: $fontcommon-color !important;
	}
}
.searchRecipientForm {
	formly-field {
		label {
			font-size: 13px;
		}

		formly-field-input {
			input {
				border-radius: 10px !important;
			}
		}

		formly-validation-message {
			font-size: 12px;
		}

	}
}
.dividerOr {
	.alignOr {
		display: flex !important;
		justify-content: center !important;
		margin: 0px !important;
		padding-top: 10px !important;
		font-size: 14px;
	}
}
/* .companyTooltip {
	text-align: center;
	z-index: 1000;
	position: fixed;
	width: 150px;
	height: 86px;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: fit-content;
		word-break: break-all;
		height: auto;
	}

} */
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/

.smart-style-1 {
	.login-page {
		.iom_btn {
			color: #000 !important;
		}
	}

	.successchangepassDiv {
		background-color: #171819;
	}

	.connectorBorder select {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.stepsinout-form.formly {
		.form-control {
			color: #fff !important;
			background-color: #0c1214 !important;
			border-color: #0c1214;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			transition: border 0s ease-in-out 0s;
		}
	}
	.templatest-form {
		.form-control {
			color: #fff !important;
			background-color: #202124 !important;
			border: 1px solid #333333 !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
			border-top-right-radius: 10px !important;
			transition: border 0s ease-in-out 0s;
		}
	}

	.connector-form {

		.inputSection,
		.selectSection {
			.form-control {
				background-color: #0C1214 !important;
				border: 1px solid #0C1214 !important;
			}
		}
	}


	.btn-primary {
		background: transparent !important;
		border: 2px solid $common_base_color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color;
		}
	}

	.CodeMirror {
		background: lightgrey;
	}

	#main {
		// background-color: $navigation-border !important;
		// padding-bottom: 115px !important;
		height: 100% !important;
	}

	.NextconfirmLabels {
		color: $primary-color;
		border: 1px solid $header-bgcolor;
	}

	.NextconfirmLabel {
		border: 1px solid $header-bgcolor;
	}

	// .profile-pic{
	// 	text-align: left !important;
	// 	position: absolute !important;
	// 	top: 36px;
	//           }
	body {
		background-color: $table-bgcolor !important;
	}

	#ribbon .breadcrumb li:last-child {
		color: $common-color !important;
	}

	.breadcrumb>li+li:before {
		color: $common-color;
	}

	.auth-text-color {
		color: map.get($darktheme-colors, "bgcolor") !important;
	}

	#left-panel {
		nav {
			ul {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;
						color: $common-color !important;
						// border-top: 1px solid #666666;
						// border-bottom:1px solid #3B3B3B33;
						border: 1px solid #3B3B3B33;
						border-bottom: 1px solid #3B3B3B33 !important;

						a {
							color: $common-color !important;
						}

						ul {
							li {
								a {
									color: $primary-color !important;
								}

								&:hover {
									color: $common-color !important;
									a{
										color: $common-color !important;
									}
								}
							}
						}
					}
				}

				li.active {
					background-color: $common_base_color !important;

					// margin-right: -1px !important;
					a {
						color: #202427 !important;
					}

					ul {
						li {
							color: #202427 !important;

							&:hover {

								a {
									color: #202427 !important;
									background: transparent !important;
								}
							}

							a {
								color: $primary-color !important;
								font-weight: 400 !important;
							}

						}

						li.active {
							a {
								color: #202427 !important;
							}
						}
					}
				}

				li.open {
					&:hover {
						a b {
							color: $common-color !important;
						}
					}

					li:hover {
						a {
							color: #202427 !important;
						}
					}

					a b {
						color: #ffffff !important;
					}

					li.open.active a b {
						color: #202427 !important;
					}

					li.open:hover>a {
						color: #202427 !important;
					}

					li.open:hover>a b {
						color: #202427 !important;
					}
				}

				li.open.active {
					a b {
						color: #202427 !important;
					}
				}
			}
		}
	}

	nav ul ul {
		li {
			a {
				// padding-left: 40px !important;
				padding-top: 6px !important;
				padding-bottom: 6px !important;
				font-size: 14px !important;
			}
		}
	}


	#ribbon {
		.breadcrumb {
			li {
				&:last-child {
					color: $primary-color !important;
				}

				color: $panel-color;
			}
		}
	}

	.theme-header-text-color {
		color: $primary-color !important;
	}

	.theme-subtext-color {
		color: map.get($darktheme-colors, "theme-color") !important;
	}

	#content {
		background: $content-bg-color !important;
	}

	.outerMainDiv {
		background: $content-bg-color !important
	}

	.dashabord {
		.jarviswidget {
			div {
				background-color: map.get($darktheme-colors, "background-color") !important;
				border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
			}
		}
	}

	.theme-icon {
		color: $primary-color !important;
	}

	.theme-text-color {
		color: $base-color !important;
	}

	.ajax-dropdown {
		border: 1px solid #313840 !important;
	}

	.notification-body {
		li {
			border: 1px solid #252b31 !important;
		}
	}

	.dropdown-menu {
		background-color: map.get($darktheme-colors, "background-color");
		border: 1px solid map.get($darktheme-colors, "bordercolor");

		li {
			a {
				color: $primary-color;
			}
		}
	}

	.header-dropdown-list {
		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: $table-bgcolor !important;
					}
				}
			}

			.active {
				a {
					background-color: $table-bgcolor !important;
				}
			}
		}
	}

	.applicationHead {
		background: $content-bg-color;
		color: $primary-color;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/Path 222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				cursor: pointer;
				// filter: brightness(0) invert(1);
			}

			&:hover {
				background-color: map.get($darktheme-colors, "bgcolor");
			}
		}

		.header-btn {
			background-color: $table-bgcolor;
		}

		.header-btn-list {
			a {
				background-color: $table-bgcolor !important;
			}
		}

		.menu-item {
			// .webComponetsBorder {
			// 	border-right: 1px solid map.get($darktheme-colors, "bgcolor");
			// }

			// &:not(:last-child) {
			// 	border-right: 1px solid map.get($darktheme-colors, "bgcolor") !important;
			// }

			&:hover {
				background-color: $header-bgcolor;
			}

			// .webComponetsHoverColor {
			// 	&:hover {
			// 		background-color: map.get($darktheme-colors, "bgcolor");
			// 	}
			// }
		}

		.theme-text-color {
			color: $primary-color !important;
		}

		.menu-item.no-hover {
			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.menu-left-item {
			// border-left: 1px solid map.get($darktheme-colors, "bgcolor");
			border-left: unset !important;
		}
	}


	.jarviswidget-color-darken {
		header {
			background: map.get($darktheme-colors, "popup-bg-color") !important;
		}
	}

	.upgradebtn {
		color: $primary-color !important;

	}

	.spanBoderBottom {
		border-bottom: 1px solid map.get($darktheme-colors, "bordercolor") !important;
	}

	.modalBodyBgColor {
		background-color: map.get($darktheme-colors, "background-color") !important;
	}

	.applicationHeadModal {
		background: map.get($darktheme-colors, "bgcolor") !important;
	}

	.theme-fade-color {
		color: map.get($darktheme-colors, "theme-color") !important;
	}

	.subList {
		&:not(:last-child) {
			border-bottom: 1px solid $content-bg-color !important;
		}
	}

	.border {
		border: 1px solid $header-bgcolor !important;
		border-radius: 10px;
	}

	.userProfile {
		.applicationBackGround {
			background-color: $content-bg-color !important;
			// margin-left: 5px;
			// margin-right: -5px;
			padding: 0;
		}
	}

	.menuSelected {
		background-color: $header-bgcolor;
	}

	.jarviswidget {
		div {
			// border: none;
		}
	}

	.border-line {
		// border: 1px solid $subborder-color;
		border: none;
		background-color: $content-bg-color !important;

	}

	.account-table {
		td {
			color: $primary-color !important;
		}

		th {
			color: $primary-color !important;
			background-color: $table-bgcolor !important;
		}
	}

	table.account-table {
		margin-bottom: 0px !important;
	}

	.account-table {
		th {
			background-color: map.get($darktheme-colors, "commonbg-color") !important;
			border-color: map.get($darktheme-colors, "bordercolor") !important;
			border-top: none;
			border-bottom: none;
			text-align: left;
			font: normal normal bold 12px/17px Open Sans;
			letter-spacing: 0px;
			color: $base-color;
			color: $primary-color !important;
			padding-left: 24px !important;
		}

		tr {
			&:nth-child(odd) {
				background-color: map.get($darktheme-colors, "commonbg-color") !important;
			}
		}

		border-color: map.get($darktheme-colors, "bordercolor") !important;

		td {
			border-color: map.get($darktheme-colors, "bordercolor") !important;
			font: normal normal normal 13px/18px Open Sans;
			letter-spacing: 0px;
			color: $base-color;
			color: $primary-color !important;
			padding-left: 24px !important;
		}

		.history_action {
			text-decoration: underline !important;
		}
	}

	.account-table-div {
		.dt-toolbar-footer {
			background-color: map.get($darktheme-colors, "commonbg-color") !important;
			border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
			margin-top: -6px;
			box-shadow: none !important;
			width: 100% !important;

			.dataTables_info {
				text-align: left !important;
				font: italic normal 600 13px/18px Open Sans !important;
				letter-spacing: 0px !important;
				color: $card-color !important;
			}

			.pagination {
				.paginate_button {
					a {
						text-align: left !important;
						font: normal normal normal 11px/15px Open Sans !important;
						letter-spacing: 0px !important;
						color: $common-color !important;
						background: map.get($darktheme-colors, "commonbg-color") !important;
						border: 1px solid $search-border !important;
					}
				}

				.paginate_button.active {
					a {
						background-color: $base-color !important;
						color: $widget-bgcolor !important;
					}
				}
			}
		}
	}

	nav ul li a {
		color: $primary-color;
		font-size: 16px !important;

		&:focus,
		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
		}
	}

	.model-7 .checkbox label:after {
		background: $common-color !important;
	}

	.body-image {
		border-radius: 100%;
		height: 200px;
	}


	.table {
		tbody {
			tr {
				td {
					padding: 8px;
					line-height: 1.42857143;
					vertical-align: top;

					border : {
						top: 1px solid map.get($darktheme-colors, "bordercolor");
						color: map.get($darktheme-colors, "bordercolor");
					}
				}
			}
		}
	}

	.lstTrncnTbl {
		thead {
			tr {
				th {
					background-color: $table-bgcolor !important;
					color: $primary-color;
					border-color: map.get($darktheme-colors, "bordercolor");
				}
			}
		}

		tbody {
			tr.odd {
				color: $primary-color;
				/*background-color: $table-backgroundcolor;*/
				// border-bottom: 1px solid $tabsborder-color !important;
			}

			tr.even {
				color: $primary-color;
				/*background-color: map.get($darktheme-colors, "bgcolor");*/
				border-bottom: 1px solid $tabsborder-color !important;
			}

			td {
				a {
					color: $common_base_color;
				}
			}
		}

		.table-bordered {
			border-color: $tabsborder-color !important;
		}

		.dt-toolbar {
			background: none !important;
			border-bottom: none;
		}
	}

	// <!-------Subscripition scss------------------>
	// .table-bordered>tbody>tr>td {
	// 	border: 1px solid $header-bgcolor;
	// }

	.emptyCol {
		border-top-right-radius: 0px !important;
		border-top: 1px solid transparent !important;
		border-left: 1px solid transparent !important;
		background-color: $content-bg-color !important;
		border-right: 1px solid transparent !important;
		border-bottom: 1px solid transparent !important;

	}

	.currentPlan {
		background-color: #CCCCCC !important;
		color: $secondary-color !important;

		&:hover {
			background: #CCCCCC !important;

		}
	}

	.currentButton {
		background-color: #404040 !important;
		color: map.get($whitetheme-colors, "textcolor") !important;
		// color:map.get($whitetheme-colors, "textcolor") !important;
		border-color: $header-bgcolor !important;
		cursor: not-allowed;
	}

	.currentButtonent {
		border-color: $header-bgcolor !important;
		background-color: #404040 !important;
	}

	.model-7 .checkbox label:after {
		background: $common-color !important;
	}

	.pagination>.active>a {
		background-color: $common_base_color !important;
		border-color: $common_base_color !important;
		color: $common-color !important;
		border-color: $header-bgcolor;
	}

	.pagination>li>a {
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 2px;
		border-top-left-radius: 2px;
		background-color: transparent !important;
		color: $primary-color;
		border-color: $header-bgcolor;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: transparent !important;
		border-color: $header-bgcolor;

	}

	.selectButton {
		border: 2px solid $common_base_color;
		border-radius: 15px;
		padding: 3px 15px;
		background-color: $content-bg-color;
		border-color: $common_base_color;
		font-size: 14px;
		outline: none;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			// border: 1px solid #3B3B3B33;
		}
	}

	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		border-radius: 15px;
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $common_base_color !important;
		font-size: 13px;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 15px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $primary-color;
		opacity: 1;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
	}

	.lightBackgroundColor {
		border-radius: 10px;
		background-color: $content-bg-color !important;
		border: 1px solid $header-bgcolor !important;
		margin-left: 13px;
	}

	.left-div {
		border-radius: 10px;
		background-color: $content-bg-color !important;
		// border: 1px solid $header-bgcolor !important;
	}

	.card-div {
		border-radius: 5px;
		background-color: $content-bg-color !important;
		border: 1px solid $header-bgcolor !important;
		padding-bottom: 0px;

		&:hover {
			background-color: map.get($darktheme-colors, "popup-bg-color") !important;
		}
	}

	.pricingTable {
		table {
			color: $primary-color !important;
		}
	}

	.theme-bg-color {
		background-color: $content-bg-color !important;
	}

	.theme-subtext-color {
		color: $card-color !important;
	}

	#content {
		background: $content-bg-color !important;
	}

	.jarviswidget>div {
		background: transparent !important;
		border: none
	}

	.bootstrapWizard li.active .title,
	.bootstrapWizard li.complete .title {
		color: $primary-color;
	}

	.bootstrapWizard li .step {
		background-color: $content-bg-color;
		border: 1px solid $header-bgcolor;
		color: $fontbackground-color;
	}

	// .bootstrapWizard {
	// 	li.active.complete {
	// 		.step {
	// 			border-radius: 70%;
	// 			border: 1px solid  $btndanger-color !important;
	// 			background-color: $btndanger-color !important;


	// 		}
	// 	}
	// }

	// .textColor {
	// 	color: $primary-color !important;
	// }

	.headData {
		background-color: #202124;
		color: $primary-color;
		margin-top: 10px;
		font-size: 14px;
	}

	.modal-body {
		background-color: $widget-bgcolor !important;
	}

	.modal-content {
		background-color: $widget-bgcolor !important;
		border: 1px solid $header-bgcolor;
		color: $primary-color;
		border-radius: 10px;
		// width: 900px;
	}

	.modal-footer {
		padding: 20px 0px 0px 0px;
		border-top: none !important;
	}

	.back-btn {

		background: $content-bg-color 0% 0% no-repeat padding-box;
		border: 2px solid $common_base_color !important;
		font-size: 13px;
		border-radius: 20px;

	}

	.jarviswidget-color-darken>header {
		// background: map.get($darktheme-colors, "popup-bg-color") !important;
		background: none !important;
		// border-bottom: 1px solid #666666 !important;
		// border-color: #303030 !important;
		border: none;
	}

	// <!-------end Subscripition scss------------------>
	.theme-content-color {
		color: map.get($darktheme-colors, "textcolor");
	}

	.theme-button-color {
		background-color: $content-bg-color;
		color: $primary-color !important;
	}

	.tranhoverTable select {
		background-color: inherit;
		color: inherit;
		border: 1px solid $header-bgcolor;
		border-radius: 5px;

		option {
			background: $secondary-color 0% 0% no-repeat padding-box;
			color: inherit;
		}
	}

	.tranhoverTable input[type=radio]:checked:after {
		border: 2px solid map.get($whitetheme-colors, "textcolor");
	}

	.contact_infrmtn_sctn {
		.detailLbl {
			color: $primary-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: map.get($darktheme-colors, "popup-bg-color") !important;
					border: 1px solid map.get($darktheme-colors, "popup-bg-color") !important;
					font-size: 14px;
					color: $primary-color;
				}
			}
		}
	}

	.comapny_details_form {
		.detailLbl {
			color: $primary-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background: map.get($darktheme-colors, "popup-bg-color") !important;
					border: 1px solid map.get($darktheme-colors, "popup-bg-color");
					font-size: 14px;
					color: $primary-color;
				}
			}
		}
	}

	.editEnabled {
		.detailLbl {
			color: $primary-color !important;
			font-size: 14px !important;

			.commoninputStyle {
				.form-control {
					background-color: $content-bg-color !important;
					// border: 1px solid map.get($whitetheme-colors, "textcolor") !important;
				}
			}
		}
	}

	.detail_save_btn,
	.detail_save_Btn,
	.detailInfo_save_btn,
	.approveBtn,
	.gotoEdi,
	.iom_btn {
		color: $primary-color;
	}

	.editDisabled {
		background: none;
	}


	.gridSectionFirst {
		.payMethodSection {
			border: 1px solid map.get($whitetheme-colors, "textcolor");

			.payHead {
				span {
					color: $primary-color;
				}
			}
		}

		.techContactSection {
			border: 1px solid map.get($whitetheme-colors, "textcolor");

			.techHead {
				color: $primary-color;
			}
		}
	}

	.technical_details_form {
		.detailLbl {
			color: $primary-color;
			font-size: 14px;

			// .commoninputStyle {
			.form-control {
				height: 40px !important;
				border-radius: 6px !important;
				background-color: map.get($darktheme-colors, "popup-bg-color") !important;
				border: 1px solid map.get($darktheme-colors, "popup-bg-color");
				font-size: 14px;
				color: $primary-color;
			}

			// }
		}
	}

	.gridSectionTwo {
		.billingContactSection {
			border: 1px solid map.get($whitetheme-colors, "textcolor");


			.billHead {
				font-size: 16px;
				color: $primary-color;
				font-weight: 600;
			}
		}
	}

	.billing_details_form {
		.detailLbl {
			color: $primary-color;
			font-size: 14px;

			// .commoninputStyle {
			.form-control {
				height: 40px !important;
				border-radius: 6px !important;
				background-color: map.get($darktheme-colors, "popup-bg-color") !important;
				border: 1px solid map.get($darktheme-colors, "popup-bg-color");
				font-size: 14px;
				color: $primary-color;
			}

			// }
		}
	}

	.gridSectionThree {
		.estimatedBillSection {
			padding: 10px 30px;
			border: 1px solid map.get($whitetheme-colors, "textcolor");
			margin: 20px 0px 20px 20px;
			width: 96%;
			border-radius: 10px;
			height: 584px;

			hr {
				border-top: 1px solid map.get($whitetheme-colors, "textcolor") !important;
			}

			.estBillHead {
				font-size: 16px;
				color: $primary-color;
				font-weight: 600;
			}

			.billItemDesc {
				font-size: 14px;
				color: $primary-color;
			}

			.billItemPrice {
				font-size: 14px;
				color: $primary-color;
			}
		}
	}

	.edit_details_form {
		.detailLbl {
			color: $primary-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: $content-bg-color;
					border: 1px solid map.get($whitetheme-colors, "textcolor");
					font-size: 14px;
					color: $primary-color;
				}
			}
		}
	}

	.underline {
		border-color: map.get($whitetheme-colors, "textcolor");
	}

	.subscription-sum {
		border-top: 2px solid #32383e !important;
	}

	.card-fields {
		.form-fields-wrapper {
			border-bottom: 1px solid #d5e1ec;

			.icon {
				width: 25px;
				height: auto;
			}

			.form-fields {
				width: calc(100% - 35px);
				margin-left: auto;

				input {
					outline: none;
					border: none;
					width: 100%;
					line-height: 50px;
					padding: 0;
					color: $primary-color !important;
					font-weight: 500;
					font-size: 20px;
					background: transparent;

					&::-webkit-input-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					&::-moz-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					&:-ms-input-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					& :-moz-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}
				}
			}
		}

		.checkbox {
			color: #005397;
		}

		.card-errors {
			color: #f34646;
			font-size: 10px;
			margin: 10px 0 0 0;
		}
	}

	.ElementsApp,
	.ElementsApp .InputElement {
		color: $primary-color !important;
		font-weight: 300;
		font-family: "Helvetica Neue", Helvetica, sans-serif;
		font-size: 18px;
	}

	// .card-fields {
	// 	.form-fields-wrapper {
	// 		border-bottom: 1px solid #d5e1ec;

	// 		.icon {
	// 			width: 25px;
	// 			height: auto;
	// 		}

	// 		.form-fields {
	// 			width: calc(100% - 35px);
	// 			margin-left: auto;

	// 			input {
	// 				outline: none;
	// 				border: none;
	// 				width: 100%;
	// 				line-height: 50px;
	// 				padding: 0;

	// 				font: {
	// 					weight: 500;
	// 					size: 20px;
	// 				}

	// 				background-color: $widget-bgcolor;

	// 				&::-webkit-input-placeholder {
	// 					color: $formcolor;
	// 					font-size: 16px;
	// 				}

	// 				&::-moz-placeholder {
	// 					color: $formcolor;
	// 					font-size: 16px;
	// 				}

	// 				&:-ms-input-placeholder {
	// 					color: $formcolor;
	// 					font-size: 16px;
	// 				}

	// 				& :-moz-placeholder {
	// 					color: $formcolor;
	// 					font-size: 16px;
	// 				}
	// 			}
	// 		}
	// 	}

	// 	.checkbox {
	// 		color: $payment-color;
	// 	}

	// 	.card-errors {
	// 		color: $invalid-color;
	//         font-size: 13px;
	// 		margin: 10px 0 0 0;
	// 	}
	// }

	.payment-detail {
		width: 100%;
		max-width: 1010px;
		background-color: $widget-bgcolor;
		margin: auto;
		padding: 10px 10px 6px 10px;
	}

	.nav ul .active>a {
		color: $primary-color !important;
		font-weight: 700;
	}

	.ElementsApp,
	.ElementsApp .InputElement {
		color: $primary-color;
		font-weight: 300;
		font-family: "Helvetica Neue", Helvetica, sans-serif;
		font-size: 18px;
	}

	.sub-btn {
		border: 2px solid $common_base_color !important;
		color: $primary-color !important;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
		}
	}

	.manage-btn {
		font-size: 13px;
		border: 2px solid $header-bgcolor !important;
		border-radius: 15px;
		background-color: $secondary-color !important;
		color: $primary-color !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color !important;

		}
	}

	// }
	.ganerateButton {
		background: map.get($darktheme-colors, "popup-bg-color") !important;
		color: $primary-color;
	}

	.stepsChecked {
		font-weight: 900;
		background-color: $header-bgcolor;
	}

	.next_payment {
		background-color: map.get($darktheme-colors, "popup-bg-color") !important;
		color: $primary-color;
	}

	.userEditTextBox {
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
		color: $primary-color;
	}

	.nameDiv,
	.headDiv {
		color: $primary-color;
	}

	.selectExpiry {
		.form-control {
			height: 40px !important;
			border-radius: 6px !important;
			background-color: $content-bg-color;
			border: 1px solid map.get($whitetheme-colors, "textcolor");
			font-size: 14px;
			color: $primary-color;
		}

		span.ng-star-inserted {
			visibility: hidden;
		}
	}

	.form-control.is-invalid {
		background-image: none !important;
		border-color: #dc3545 !important;
	}

	.profileEditTextBox {
		background: #202124 0% 0% no-repeat padding-box !important;
	}

	.profileModalBtn {
		padding: 10px;
		// margin-left: -15px;
		background-color: transparent !important;
		border: 2px solid $common_base_color !important;
		color: $primary-color !important;
		border-radius: 19px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
		}
	}

	.sub-img {
		background: #404040 0% 0% no-repeat padding-box;
	}

	.row-divider {
		border-color: $header-bgcolor !important;
	}

	.dot-div {
		color: map.get($darktheme-colors, "textcolor") !important;
	}

	// BUY-ADDON start

	// <!-------Subscripition scss------------------>
	.table-bordered>tbody>tr>td {
		border: 1px solid $header-bgcolor;
	}

	.subscriptions-page {
		.table-bordered {

			// border: 1px solid $header-bgcolor !important;
			>thead {
				>tr {
					>th {
						&:not(:first-child) {
							border: 1px solid $header-bgcolor;
						}
					}
				}
			}
		}

		table.noFeature.table-bordered {
			>thead {
				>tr {
					>th {
						border: 1px solid $header-bgcolor;
					}
				}
			}
		}
	}

	.table-bordered>tbody>tr>td:first-child {
		// border-left: 2px solid $header-bgcolor;
	}

	.table-bordered>tbody>tr>td:last-child {
		// border-right: 2px solid $header-bgcolor;
	}

	.table-bordered>tbody>tr:last-child>td {
		// border-bottom: 2px solid $header-bgcolor;
	}

	.table-bordered>tbody.headData>tr:first-child>td,
	.table-bordered>tbody.table-headData>tr:first-child>td {
		// border-top: 2px solid $header-bgcolor;
	}

	.table-bordered>tbody.headData>tr>td.switchCol,
	.table-bordered>thead.headData>tr>th.switchCol,
	.table-bordered>tbody.table-headData>tr>td.switchCol {
		box-shadow: -1px 0 0 0 $header-bgcolor;
	}

	.table-bordered>tbody.headData>tr>td:first-child.switchCol,
	.table-bordered>tbody.table-headData>tr>td:first-child.switchCol {
		box-shadow: 0 -1px 0 0 $header-bgcolor;
	}


	.emptyCol {
		border-top: 1px solid transparent !important;
		border-left: 1px solid transparent !important;
		background-color: $content-bg-color !important;
		border-right: 1px solid transparent !important;
		border-bottom: 1px solid transparent !important;
		background: $content-bg-color !important;
	}

	.currentPlan {
		background-color: #CCCCCC !important;
		color: $secondary-color !important;

		&:hover {
			background: #CCCCCC !important;

		}
	}

	.model-7 .checkbox label:after {
		background: $common-color !important;
	}

	// .pagination>.active>a {
	// 	background-color: $common_base_color !important;
	// 	border-color: $common_base_color !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li>a {
	// 	background-color: transparent !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:first-child>a,
	// .pagination>li:first-child>span {
	// 	margin-left: 0;
	// 	border-bottom-left-radius: 2px;
	// 	border-top-left-radius: 2px;
	// 	background-color: transparent !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:last-child>a,
	// .pagination>li:last-child>span {
	// 	border-bottom-right-radius: 5px;
	// 	border-top-right-radius: 5px;
	// 	background-color: transparent !important;
	// 	border-color: $header-bgcolor;

	// }


	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		border-radius: 15px;
		background: $content-bg-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $common_base_color !important;
		font-size: 13px;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 15px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $primary-color;
		opacity: 1;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		// background-color: $secondaryFocus !important;
		border-radius: 10px;
	}

	.lightBackgroundColor {
		border-radius: 5px;
		background-color: $content-bg-color !important;
		border: 1px solid $header-bgcolor;
		margin-left: 13px;
	}

	.pricingTable {
		table {
			color: $primary-color !important;
		}
	}

	.theme-bg-color {
		background-color: $content-bg-color !important;
	}

	.theme-subtext-color {
		color: $card-color !important;
	}

	#content {
		background: $content-bg-color !important;
	}

	.jarviswidget>div {
		// background-color: $content-bg-color !important;
		border: none
	}

	.bootstrapWizard li.active .title,
	.bootstrapWizard li.complete .title {
		color: $primary-color;
	}

	.bootstrapWizard li .step {
		background-color: $content-bg-color;
		border: 1px solid $header-bgcolor;
		color: #eee;
	}

	.textColor {
		color: $primary-color !important;
	}

	// .headData {
	// 	background-color: inherit !important;
	// 	color: $primary-color;
	// 	margin-top: 10px;
	// }

	// .modal-body {
	// 	background-color: $widget-bgcolor !important;
	// }

	// .modal-content {
	// 	background-color: $widget-bgcolor !important;
	// 	border: 1px solid $header-bgcolor;
	// 	color: $primary-color;
	// 	border-radius: 10px;
	// 	// width: 900px;
	// }

	// .modal-footer {
	// 	padding: 20px 0px 0px 0px;
	// 	border-top: none;
	// }

	.back-btn {
		background: $subscribe-bgcolor 0% 0% no-repeat padding-box;
		border-color: $subscribe-bgcolor;
		font-size: 13px;
	}

	.jarviswidget-color-darken>header {
		background: none !important;
		// border-color:#202124   !important;
	}

	.yearlabel {
		border: 1px solid #666666;
		background-color: #202124;
	}

	.cancellabel {
		border: 1px solid #202124;
		background-color: #202124;
	}

	.buy-addon {
		.navigationList {
			.nav.nav-tabs {
				li {
					a {
						span {
							color: $primary-color !important;
						}

						&:hover {
							background-color: $common_base_color !important;
							border: 1px solid $common_base_color !important;

							span {
								color: $common-color !important;
							}
						}
					}
				}

				li.active {
					a {
						span {
							color: $common-color !important;
						}
					}
				}
			}
		}
	}

	// .NextconfirmLabel {
	// 	color: $primary-color;
	// }

	.buy-addon {
		.checkout {
			background-color: $secondary-color;
		}
	}

	.table-headData {
		background-color: #202124;
		color: $primary-color;
		margin-top: 10px;
	}

	.commoninputStyle {
		.form-control {
			height: 40px !important;
			border-radius: 6px !important;
			background-color: $content-bg-color;
			border: 1px solid map.get($whitetheme-colors, "textcolor");
			font-size: 14px;
			color: $primary-color !important;
		}
	}

	.child {
		.dtr-details {
			.dtr-title {
				color: $base-color !important;
			}

			.dtr-data {
				color: $base-color !important;
			}
		}
	}

	.cart-btn {
		background-color: $primary-color;

		.fas {
			color: #202528;
		}
	}

	// BUY-ADDON end

	.blacktooltip {
		background: $content-bg-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;

		&:hover {
			background: $content-bg-color 0% 0% no-repeat padding-box !important;
		}

		.insideDivUl {
			li {
				border-bottom: 1px solid map.get($darktheme-colors, "bordercolor");
			}

			li:last-child {
				border-bottom: unset;
			}
		}
	}
	.error-blacktooltip{
		background: $content-bg-color 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;

		&:hover {
			background: $content-bg-color 0% 0% no-repeat padding-box !important;
		}

		.insideDivUl {
			li {
				border-bottom: 1px solid map.get($darktheme-colors, "bordercolor");
			}

			li:last-child {
				border-bottom: unset;
			}
		}
	}

	.optionSelect {
		background-color: #202124 !important;
		color: map.get($darktheme-colors, "textcolor") !important;
	}

	.table {
		width: 100%;
		color: $primary-color;
		border-collapse: collapse;
		background-color: #666666;
		border-color: $header-bgcolor;
		border-radius: 5px;
	}

	.subscriptions-page {
		.table {
			border-color: #202124 !important;

			thead {
				tr {
					background: #202124 !important;
				}
			}
		}

		td {
			background-color: $content-bg-color;
		}
	}

	.history-table th {
		border-color: $header-bgcolor !important;
		background-color: #404040 !important;
		color: $primary-color;
	}

	.commonHead {
		background-color: #202124 !important;
		color: $base-color;
		font-size: 14px;
		border: unset !important;
	}

	.algntblTranscation {
		// border: 1px solid #666!important;
		background: #202124 !important;
	}

	.historyLabel {
		color: $primary-color;
	}

	table.history-table {
		margin-top: 0px !important;
		// border: 1px solid #666!important;
		border-radius: 8px !important;

		tr {
			&:hover {
				background-color: #404040 !important;
				color: $primary-color;
			}
		}

	}

	.history-table .history_action {
		text-decoration: underline !important;
	}

	.table-bordereds>tbody>tr>td {
		border: 1px solid $header-bgcolor;
		z-index: 10px;
	}

	// OWL datepicker Inline starts here

	.owl-dt-calendar-control {
		color: $primary-color !important;
		border: 1px solid #333333 !important;
		background: #202124;
		margin-top: 11px;
	}

	.owl-dt-container {
		background: #202124 !important;
		border: #202124 !important;
		border-radius: 10px !important;
	}

	.owl-dt-calendar-table .owl-dt-calendar-header {
		color: #fff !important;
		// display: none;
	}

	.owl-dt-calendar-table .owl-dt-calendar-cell {
		color: #fff !important;
	}



	.timeZoneBtn {
		color: $common-color !important;
	}

	.gradientDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color") !important;
		border: 1px solid $whiteborder-color;

		&:hover {
			background-color: map.get($darktheme-colors, "popup-bg-color") !important;
		}
	}

	.hoverBtn {
		background: map.get($darktheme-colors, "popup-bg-color") !important;
	}

	// .blacktooltip {
	// 	background: $widget-bgcolor 0% 0% no-repeat padding-box;
	// 	border: 1px solid map.get($darktheme-colors, "bordercolor");
	// 	color: $base-color;
	// }
	// .dataTables_processing {
	// 	background: url(../img/spin-loader_small_white.svg) no-repeat !important;
	// }
	.dataTables_processing {
		background: url(../img/spin-loader_big_white.svg) no-repeat !important;
	}

	// .pagination>.active>a {
	// 	background-color: $common_base_color !important;
	// 	border-color: $common_base_color !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li>a {
	// 	background-color: transparent !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:first-child>a,
	// .pagination>li:first-child>span {
	// 	margin-left: 0;
	// 	border-bottom-left-radius: 2px;
	// 	border-top-left-radius: 2px;
	// 	background-color: transparent !important;
	// 	color: $primary-color;
	// 	border-color: $header-bgcolor;
	// }

	// .pagination>li:last-child>a,
	// .pagination>li:last-child>span {
	// 	border-bottom-right-radius: 5px;
	// 	border-top-right-radius: 5px;
	// 	background-color: transparent !important;
	// 	border-color: $header-bgcolor;

	// }
	table.pending-list-table {
		// margin-top: 15px !important;
		margin-top: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
	}

	.table {
		thead {
			tr {
				th {
					border-color: $header-bgcolor;
				}
			}
		}

		tbody {
			tr {
				td {
					border-color: $header-bgcolor;
				}
			}
		}
	}

	.history-btn {
		color: $primary-color;

		&:hover {
			background-color: $common_base_color !important;
			color: $common-color;
		}
	}

	.list-area {
		background-color: map.get($darktheme-colors, "bgcolor");
		border-radius: 0px;
	}

	.serviceTheme {
		background-color: $darktheme-bgcolor !important;
		border: 1px solid $header-bgcolor !important;
		// width: 1200px !important;

		.ui-dialog-titlebar {
			background-color: $darktheme-bgcolor !important;
			border-bottom: none !important;
			width: 100% !important;

			.ui-dialog-title {
				width: 100% !important;

				.widget-header {
					display: flex;
					justify-content: normal;
				}

				.shortDescription {
					text-align: center;
				}

			}

			.ui-dialog-titlebar-close {
				position: absolute;
				top: 25px;
				right: 5px;
			}

			h5 {
				color: $primary-color !important;
			}

			h1 {
				color: $primary-color !important;
			}

			.ui-button {
				color: $common_base_color;
				opacity: 1;
			}
		}

		.ui-dialog-content {
			background-color: $darktheme-bgcolor !important;
			;

			.service_modal {
				background-color: $darktheme-bgcolor !important;
				;

				.modal-content {
					box-shadow: unset !important;
					-webkit-box-shadow: unset !important;
					border: unset !important;
					background-color: $darktheme-bgcolor !important;
					;
				}
			}
		}

		.chip_Section {
			border: 1px solid #1B1E24 !important;
			background-color: #7c7b7b !important;
			color: $primary-color !important;
			margin-left: 0px !important;
		}

		// .serviceSection:hover {
		// 	// border: 1px solid #1B1E24 !important;
		// 	background-color: #303030 !important;
		// }

		.toggleMore {
			background-color: $tabsborder-color !important;
		}

		.serachService {
			background-color: $fontColor !important;
		}
	}

	.dropdown-list.fixMultiSelect {
		background-color: $widget-bgcolor !important;
		box-shadow: 0 1px 5px $widget-bgcolor !important;
		border: 1px solid $tabsborder-color !important;

		.filter-textbox {
			border-bottom: 1px solid $tabsborder-color !important;
		}

		.item1 {
			.multiselect-item-checkbox {
				// border-bottom: 1px solid $tabsborder-color !important;
				border-bottom: none !important;
			}
		}

	}

	.user-role-form {
		.multiselect-item-checkbox {
			input[type=checkbox] {
				+ {
					div {
						color: $primary-color !important;
					}
				}
			}
		}

		.item1 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;

					input[type=checkbox] {
						+ {
							div {
								color: #111 !important;
							}
						}
					}
				}
			}
		}

		.item2 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;
				}
			}
		}

		.multiselect-dropdown {
			.dropdown-btn {
				border: unset !important;
				padding: 6px 0px !important;
			}
		}
	}

	.price_calcultation_form {
		.dropdown-list {
			background-color: $content-bg-color !important;
			box-shadow: 0 1px 5px $widget-bgcolor !important;
			border: 1px solid #333 !important;
		}

		.multiselect-item-checkbox {
			input[type=checkbox] {
				+ {
					div {
						color: $primary-color !important;
					}
				}
			}
		}

		.item1 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;

					input[type=checkbox] {
						+ {
							div {
								color: #111 !important;
							}
						}
					}
				}
			}
		}

		.item2 {
			.multiselect-item-checkbox {
				&:hover {
					background-color: #7c7b7b !important;
				}
			}
		}

		.multiselect-dropdown {
			.dropdown-btn {
				border: 1px solid #333 !important;
			}
		}
	}

	.reMark {
		color: $primary-color;
		border: 1px solid #666666;
	}

	.spin-loader {
		background: url(../img/spin-loader_small_white.svg) no-repeat !important;
	}

	.big-spin-loader {
		background: url(../img/spin-loader_big_white.svg) no-repeat !important;
	}

	.btnModalSave {
		float: right;
		background-color: $content-bg-color;
		border: none;
		color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color;

		&:hover {
			color: $common-color !important;
		}
	}

	.btnModalClose {
		float: left;
		background-color: $content-bg-color;
		border: none;
		color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;

		&:hover {
			color: $common-color !important;
		}
	}

	.successDiv {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successinviteDiv {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successappDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successcmpimgDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successcmpprofileimgDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.successuserdeleteDiv {
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.disabledColor {
		background-color: #404040 !important;
		color: #666666 !important;
		border-color: #666666 !important;
	}

	.inv-img {
		content: url('../img/invoice_orange.svg') !important;
	}

	.badge-disable-iom {
		color: $primary-color !important;
	}

	.checkmark {
		border: 2px solid $primary-color;
	}

	// .checkbox-container .checkmark:after {
	// 	border: solid #fff;
	// }
	.checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid $primary-color;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}

	.vertical-line {
		background-color: #666666;
	}

	.card_details_nocard {
		p {
			color: $primary-color;
			font-size: 14px;
		}
	}

	.subPlanData {
		color: $primary-color;
	}

	.table-wrapper {
		background-color: #404040 !important;

		table {
			tbody {
				tr {
					td {
						background-color: #202124 !important;
					}
				}
			}
		}
	}

	.tooltipRole {
		&:hover {

			.tooltiptextRole {
				border: 1px solid map.get($darktheme-colors, "bordercolor");
				color: $base-color;

				&:hover {
					background: #202124 0% 0% no-repeat padding-box !important;
				}

				.insideDivUl {
					li {
						border-bottom: 1px solid map.get($darktheme-colors, "bordercolor");
					}

					li:last-child {
						border-bottom: unset;
					}
				}
			}
		}

		.tooltiptextRole {

			background-color: #202124;
			color: $primary-color;
		}

	}

	.cardconfirmLabel {
		color: $base-color !important;
		border: 1px solid map.get($darktheme-colors, "bordercolor");

		.header-formbtn {
			background-color: unset !important;
			border: 2px solid $common_base_color !important;
			color: $primary-color !important;
			padding: 3px 15px;

			&:hover {
				background: $common_base_color !important;
				color: $secondary-color !important;
			}
		}

	}

	.MessageBoxButtonSection button {
		color: $primary-color;
	}

	.manageHeader,
	.comapnyHeaderLbl {
		color: $primary-color !important;
	}

	.top-div {
		border-bottom: 1px solid map.get($darktheme-colors, "bordercolor") !important;

		.description-div {
			.description-content {
				border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
			}
		}
	}

	.step-div {
		background: #202124;
	}

	.footer-div {
		background: #202124;
	}

	.fieldForm {
		.form-control {
			background-color: $content-bg-color !important;
			color: $primary-color !important;
		}
	}

	.algntblTranscation {
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		overflow: hidden;
		background: #202124 !important;
	}

	.setselectHeight {

		background-color: $content-bg-color;
		color: $primary-color !important;
		border-color: #666666;
	}

	.backtopagewrapper {
		color: $primary-color;
	}

	.successdeleteDiv {
		// background-color: #171819;
		background-color: map.get($darktheme-colors, "popup-bg-color");
	}

	.btnModalClose {
		float: left;
		background-color: $content-bg-color;
		border: none;
		// color: $primary-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;
	}

	.search_status {
		background-color: $content-bg-color !important;
		color: $primary-color;
	}

	.user-list-select {
		background-color: $content-bg-color !important;
		color: $primary-color;
	}

	.select_search_status {
		background-color: $darktheme-bgcolor !important;
		color: $primary-color;
	}

	.drop {
		color: #fff !important;
	}

	.ui-datepicker {
		background: #202124 !important;
		border-color: #666666 !important;
		z-index: 2000 !important;

		.ui-datepicker-header {
			background-color: #202124;
		}

		select.ui-datepicker-month {
			border-color: map.get($darktheme-colors, "datepicker-bgcolor");
			background: #202124 !important;
		}

		select.ui-datepicker-year {
			border-color: map.get($darktheme-colors, "datepicker-bgcolor");
			background: #202124 !important;
		}

		th {
			color: $primary-color !important;
		}

		td {
			.ui-state-active {
				background: $tabsborder-color;
			}
		}
	}

	.ui-datepicker-header {
		a {
			color: $primary-color !important;
		}
	}

	.ui-datepicker-month {
		color: $primary-color !important;
	}

	.ui-datepicker-year {
		color: $primary-color !important;
	}

	.ui-datepicker-prev {
		&:before {
			background: #202124 !important;
		}
	}

	.ui-datepicker-next {
		&:before {
			background: #202124 !important;
		}
	}

	.ui-datepicker {
		td {
			.ui-state-default {
				color: $primary-color;
			}
		}
	}

	.setCircleactive {
		color: #00eba9;
		font-size: 10px !important;
	}

	.iconForm {
		color: $primary-color;
	}

	.filter-top {
		color: $primary-color !important;
	}

	table.positionTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid #666666 !important;
		border-radius: 5px;
	}

	table.bulkTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid #666666 !important;
		border-radius: 5px;
	}

	.headerContents {
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		overflow: hidden;
		background: #202124;
	}
	.headerfilterContents {
		border-radius: 8px;
		border: 1px solid $header-bgcolor !important;
		background: #202124;
		// z-index: 1000;
	}
	.tableHeadBGclr {
		background: #1E1E1E !important;
	}

	.headClr {
		color: #fff !important;
	}

	.CodeMirror {
		background: lightgrey;
	}

	.amount-div {
		background: #7c7b7b;
		color: #fff;
	}

	.invoiceOverview .nav-tabs li {
		border-bottom: 1px solid $header-bgcolor !important;
	}

	.invoiceOverview .nav-tabs>li.active {

		a,
		a:hover,
		a:focus {
			cursor: default;
			background-color: transparent !important;
			border: 1px solid $header-bgcolor;
			border-bottom-color: transparent;
		}

		border-top: 1px solid $header-bgcolor !important;
	}

	.invoiceOverview .nav-tabs {
		li {
			a {
				&:hover {
					background-color: transparent !important;
					border: none;
				}
			}
		}
	}

	.headData {
		background: #1E1E1E 0% 0% no-repeat padding-box;
		color: #fff;
		border: 1px solid $header-bgcolor;
		margin-top: 10px;
	}

	.idTooltip,
	.btnTooltip,
	.errorTooltip,
	.companyTooltip {
		background: $widget-bgcolor 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}

	.advace-header-formbtn {
		background-color: unset !important;
		border: 2px solid $common_base_color !important;
		color: $primary-color !important;
		// padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $secondary-color !important;
		}
	}

	.error_text {
		color: $primary-color !important;
	}

	.tranhoverTable {
		tbody {
			tr {
				&:hover {
					// color: $primary-color !important;
					background-color: #7c7b7b !important;
					cursor: pointer;
				}
			}
		}
	}

	.bulkhoverTable {
		tbody {
			tr {
				&:hover {
					// color: $primary-color !important;
					background-color: $common_base_color!important;
					cursor: pointer;
					color: #000 !important;
				}
			}
		}
	}

	#header.colorChangeTest {
		.header-btn {
			background-color: transparent;
			box-shadow: none;

			&:hover {
				background-color: $table-bgcolor;
			}
		}

		.dropdown-icon-menu {
			&:hover {
				.header-btn {
					background-color: $table-bgcolor;
				}
			}
		}

	}

	nav ul ul {
		li {
			a {
				color: $primary-color;
				font-size: 16px !important;
				text-shadow: none !important;
				font-weight: 400;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $common-base-color !important;
				}
			}
		}
	}

	.filterSection {
		.ng-select {
			.ng-select-container {
				.ng-value-container {
					.ng-placeholder {
						color: $primary-color !important;
						font-size: 14px;
					}
				}
			}

			.ng-arrow-wrapper {
				.ng-arrow {
					border-color: $primary-color transparent transparent !important;
				}
			}
		}

		.ng-select.ng-select-opened {
			>.ng-select-container {
				.ng-arrow {
					border-color: transparent transparent $primary-color !important;
				}
			}
		}
	}

	.disableForm .panelSector {
		.ng-select {
			.ng-select-container {
				border: 1px solid $header-bgcolor;

				.ng-value-container {
					.ng-placeholder {
						color: $primary-color !important;
						font-size: 14px;
					}
				}
			}

			.ng-arrow-wrapper {
				.ng-arrow {
					border-color: $primary-color transparent transparent !important;
				}
			}
		}

		.ng-select .requiredSection {
			.ng-select-container {
				border: 1px solid #dc3545;
			}
		}

		.ng-select.ng-select-opened {
			>.ng-select-container {
				.ng-arrow {
					border-color: transparent transparent $primary-color !important;
				}
			}
		}
	}

	// *********************Ebill css********************************************

	.card-header {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.accordianClass {
		color: #FFFFFF;
	}

	#wizard {
		background-color: map.get($darktheme-colors, "commonbgcolor") !important;
		// border: 1px solid #666666;
		color: map.get($darktheme-colors, "textcolor");

		.line-1 {
			background: $header-bgcolor;
		}

		.wizard-items {
			border: 1px solid #3B424C;
			background: #202124 0% 0% no-repeat padding-box;

			&:hover {
				background: #171819 0% 0% no-repeat padding-box;
			}

			.wizardDiv {
				border: 1px solid #404040;
				background: #404040 0% 0% no-repeat padding-box;
			}
		}

		b.badges {
			border: 1px solid #202124;
			box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
		}

	}

	#wizard .wizard-items .wizardDiv {
		border: 1px solid #404040;
		background: #404040 0% 0% no-repeat padding-box;
	}

	#wizard b.badges {
		border: 1px solid #202124;
		box-shadow: inset 1px 1px 0 rgb(0 0 0 / 10%), inset 0 -1px 0 rgb(0 0 0 / 7%);
	}

	#wizard .wizard-billeritem {
		border: 1px solid #3B424C;
		background: #202124 0% 0% no-repeat padding-box;
	}

	.docLbl {
		width: 100%;
		color: #fff;
		font-size: 14px;
	}

	.form-control {
		color: #fff !important;
		background-color: #202124 !important;
		border: 1px solid #333333;
	}

	.docInput {
		width: 97%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #0C1214 !important;
		border: unset !important;
	}


	.comInput {
		width: 100%;
		// margin-right: 10px;
		border-radius: 10px !important;
		background-color: #0C1214 !important;
		border: unset !important;
	}

	.common_wizardItemContainer .wizard_format {
		width: 50% !important;
	}

	.card-header {
		background-color: rgba(0, 0, 0, 0.5);
	}

	.card {
		background-color: #202124;
		border: 1px solid rgba(0, 0, 0, 0.08);
	}

	#wizard .line-1 {
		background: $header-bgcolor !important;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		label {
			color: #fff !important;
		}

		.form-control {
			// background: transparent!important;
			border: 1px solid $header-bgcolor;
			color: #fff;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 13px;
		}
	}

	.billTabSection {
		.stepsinout-form.formly {
			.form-control {
				color: $primary-color !important;
				background-color: $secondaryFocus !important;
				border-color: $secondaryFocus;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
				border-top-right-radius: 10px !important;
				// border-radius: 10px !important;
				-webkit-transition: border 0s ease-in-out 0s;
				-moz-transition: border 0s ease-in-out 0s;
				-o-transition: border 0s ease-in-out 0s;
				-ms-transition: border 0s ease-in-out 0s;
				transition: border 0s ease-in-out 0s;
			}
		}
	}

	.billTabSection {
		border: 1px solid $header-bgcolor;
	}

	.biller_tabSection {
		.nav-item {

			// border-right: unset !important;
			a {
				margin-right: unset !important;
				background-color: unset !important;
				color: #fff !important;
				border-bottom: unset !important;
				border-left-color: $header-bgcolor !important;
				border-right-color: $header-bgcolor !important;
				border-top-color: $header-bgcolor !important;
			}
		}


	}

	.btn-primary {
		background: transparent !important;
		border: 2px solid $common_base_color;
		color: $primary-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color;
		}
	}

	table.positionTable {
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
	}

	table.bulkTable {
		margin-top: 1px !important;
		margin-bottom: 0px !important;
		border: 1px solid $header-bgcolor !important;
		border-radius: 5px;
	}

	#wizard .wizard-companyitem {
		border: 1px solid #3B424C;
		background: #202124 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}

	.ebillForm .panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #111 !important;
		border: 1px solid $header-bgcolor;

		.ng-input {
			color: #fff !important;
		}
	}

	.commision-agent .panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #fff !important;
		border: 1px solid $header-bgcolor !important;
	}

	.emailMessage {
		border: 1px solid #3b424c !important;
	}

	.errorOccured {
		border-color: #dc3545 !important;
	}

	// *********************end Ebill css********************************************
	.ng-dropdown-panel.ng-select-bottom {
		border: 1px solid $header-bgcolor !important;
	}

	.nav_btn {
		color: #666 !important;
	}

	.navFooter {
		.nav_btn {
			color: #fff !important;

			&:hover {
				color: #000 !important;
			}
		}
	}

	.stepsinout-form,
	.businessconnect-form {
		.input-group-append {
			.input-group-text {
				background-color: $secondaryFocus;
				color: $primary-color;
				border: 1px solid $secondaryFocus;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;

				&::before {
					border-left: 1px solid $primary-color;
				}
			}
		}
	}

	.stepsinout-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-bottom-right-radius: 10px !important;
			// border-top-right-radius: 10px !important;
			// border-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.billTabSection {
		.stepsinout-form.formly {
			.form-control {
				color: $primary-color !important;
				background-color: $secondaryFocus !important;
				border-color: $secondaryFocus;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
				border-top-right-radius: 10px !important;
				// border-radius: 10px !important;
				-webkit-transition: border 0s ease-in-out 0s;
				-moz-transition: border 0s ease-in-out 0s;
				-o-transition: border 0s ease-in-out 0s;
				-ms-transition: border 0s ease-in-out 0s;
				transition: border 0s ease-in-out 0s;
			}
		}
	}

	.fieldForm {
		.form-control {
			color: $primary-color !important;
			background-color: $content-bg-color !important;
			border-color: map.get($darktheme-colors, 'bordercolor');
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-bottom-right-radius: 10px !important;
			// border-top-right-radius: 10px !important;
			// border-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.defaultDot {
		background-color: white;
	}

	.businessconnect-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			// border-radius: 10px !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.statusSection tr td:not(:last-child) {
		border-right: 2px solid #fff;
	}

	.company-select .ng-select-container {
		color: #fff;
		height: 42px !important;
		border: none;
	}

	.invoice-tab-header {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
		background-color: $content-bg-color !important;
		color: #fff !important;
	}

	.nav-tabs.invoice-tab {
		>li {
			border: 1px solid map.get($darktheme-colors, "bordercolor");
			border-top: none !important;

			&:hover {
				border: 1px solid #7c7b7b !important;
				border-top: none !important;
				background-color: #7c7b7b !important;
				box-shadow: 0 -2px 0 #7c7b7b !important;
			}

			>a.invoice-tabhead {
				color: $primary-color !important;
			}

			>a {
				&:hover {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}

				&:focus {
					color: $primary-color !important;
					background-color: #7c7b7b !important;
				}
			}
		}

		>li.active {
			// border: none !important;
			border: 1px solid $common_base_color !important;
			box-shadow: 0 -2px 0 $common_base_color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: $content-bg-color !important;
				color: $primary-color !important;

				&:focus {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}

				&:hover {
					background-color: $content-bg-color !important;
					color: $primary-color !important;
				}
			}
		}
	}

	.commonBorderColor {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
	}

	.formBorderColor {
		border: 1px solid $header-bgcolor !important;
	}

	.borderChangeColor {
		border-color: $header-bgcolor !important;
	}

	.setwhiteFont {
		color: $primary-color;
	}

	.dataTable tbody .shown+tr>td>table>tbody>tr:last-child {
		border-bottom: 1px solid $header-bgcolor;
	}

	.additional-details {
		border: 1px solid $header-bgcolor;
	}

	.devOptions {
		background-color: #171819;
		color: #fff;

		textarea {
			background-color: #171819;
		}
	}

	.itemEditButton {

		background-color: $content-bg-color;
		color: $primary-color !important;

		&:hover {
			background: $common_base_color !important;
			color: $secondaryFocus !important;
		}
	}

	.graphDataLabel {
		color: #fff;
	}

	.tooltipA .tooltiptext {
		background: $widget-bgcolor 0% 0% no-repeat padding-box;
		color: $base-color;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.notPaidHead,
	.notPaidHead span {
		color: $base-color !important;
	}

	.successDiv-status {
		background-color: map.get($darktheme-colors, "bgcolor");
	}

	.bs-datepicker {
		display: flex;
		align-items: stretch;
		flex-flow: row wrap;
		background-color: #202124 !important;
		// box-shadow: 0 0 10px 0 #202124;
		box-shadow: none;
		position: relative;
		z-index: 1;
		color: #fff;
	}

	.bs-datepicker-body {
		padding: 10px;
		border-radius: 0 0 3px 3px;
		// min-height: 232px;
		min-width: 293px;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
	}

	.bs-datepicker-body table td {
		color: #fff;
		text-align: center;
		position: relative;
		padding: 0;
	}

	.tranheader {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
		background: transparent !important;
		border-radius: 8px;
	}

	select:not([multiple]) option {
		background: #202124;
	}

	.ebillForm header {
		color: #fff;
	}

	.boxDiv {
		border: 1px solid map.get($darktheme-colors, "bordercolor") !important;

		.boxClass {
			.box {
				border: 1px solid map.get($darktheme-colors, "bordercolor") !important;
				color: #fff;
			}
		}
	}

	.filePattern {
		color: #E2E2E2 !important;
	}

	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		cursor: default;
		background: #7c7b7b;
	}

	.fuelux .wizard ul li.active {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		cursor: default;
		background: #171717;
	}

	.fuelux .wizard .actions {
		position: absolute;
		right: 0;
		z-index: 2;
		float: right;
		padding-right: 15px;
		padding-left: 15px;
		line-height: 46px;
		vertical-align: middle;
		background-color: #171717;
		border-left: 1px solid #3B424C;
	}

	.fuelux .wizard ul li .chevron:before {
		position: absolute;
		top: -24px;
		right: 1px;
		display: block;
		border: 24px solid transparent;
		border-right: 0;
		border-left: 14px solid #171717;
		content: "";

	}

	//           .fuelux .wizard ul li .chevron:hover {

	// 	border-left: 14px solid #171717;
	// 	content: "";
	//           }
	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #fff;
		;
		cursor: default;
		background: #171717;
	}

	.fuelux .wizard ul li.active {
		color: #000;
		background: $common_base_color;
	}

	.fuelux .wizard ul li.active .chevron:before {
		border-left: 14px solid $common_base_color;
	}

	.fuelux .wizard {
		background-color: transparent !important;
		border-color: #333333;
	}

	.btnAction,
	.btnFile,
	.btnSendinvit,
	.iom_btn {
		font-size: 13px;
		// width: 178px;
		background: transparent 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $primary-color !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}
	}

	.dropzonbox {
		background-color: #171717 !important;
		border: 1px solid #3B424C !important;

	}

	.company-select {
		border: none;
		border-radius: 10px;
		background-color: #0C1214 !important;
	}

	.businessFields {
		label {
			color: #fff !important;
			font-size: 14px;
		}
	}

	.company-select {
		background: #0C1214 !important;
		color: #fff;
	}

	.xslt-path {
		border: 1px solid #333;
		background: transparent;
		color: #fff;
		;
	}

	.dopzoneTxt,
	.sendEmail {
		color: #fff;
	}

	.archiveImport {
		background: #202124;
		color: #fff;
	}

	.nav-tabs>li.active>a.display-border,
	.nav-tabs>li.active>a.display-border:focus,
	.nav-tabs>li.active>a.display-border:hover {
		// background-color: #171717 !important;
		border-top: 2px solid $common_base_color !important;
		border-left: 2px solid $common_base_color !important;
		border-right: 2px solid $common_base_color !important;
		color: #fff;
		box-shadow: none;
	}

	.transaction.nav-tabs {
		>li.active {
			a {
				cursor: default;
				background-color: transparent !important;
				border: 1px solid #111;
				border-bottom-color: transparent;

				&:hover {
					cursor: default;
					background-color: transparent !important;
					border: 1px solid #111;
					border-bottom-color: transparent;
				}

				&:focus {
					cursor: default;
					background-color: transparent !important;
					border: 1px solid #111;
					border-bottom-color: transparent;
				}
			}
		}
	}

	// .inviteLoader{
	// 	color: #fff !important;
	// }
	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #fff !important;
	}

	.fileuploadform-form {
		.input-group-append {
			.input-group-text {
				background-color: #202124;
				color: $primary-color;
				border: 1px solid #333333;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;


			}
		}
	}

	.fileuploadform-form.formly {
		.form-control {
			color: $primary-color !important;
			background-color: $secondaryFocus !important;
			// border-radius: 10px !important;
			border-color: $secondaryFocus;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}
	.iom-button {
		color: $primary-color;
	}
	.tempCompanyselect{
		background-color: #202124 !important;
         order: 1px solid #333333;
	}
	.interConnPopupForm .form-control {
		border: 1px solid #666666 !important;
		background-color: #202124 !important;
		border-radius: 5px !important;
	}
	.dataTables_processing.panel.panel-default {
		background: url(../img/spin-loader_small_white.svg) no-repeat !important;
	}

	.detail_save_btn,
	.detail_save_Btn,
	.approveBtn,
	.gotoEdi,
	.iom_btn,
	.detailInfo_save_btn {
		&:hover {
			color: $common-color !important;
		}
	}
	.itemEditButton {
		&:hover {
			color: $secondaryFocus !important;
		}
	}
	table.dataTable tbody tr.selected,
	table.dataTable tbody tr.selected:hover {
		color: #000 !important;
	}
	
	.badge-iom {
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		font-size: 13px !important;
		padding: 4px 13px !important;
		background-color: $common_base_color !important;
		color:$common-color;
		border-radius: 10rem;
	}
	.theme-list-div{
		.half-div{
			color: $primary-color !important;
		}
	}

	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #fff !important;
	}
	#transaction-header .form-control::placeholder{
		color: #fff !important;
	}
	.companyTooltip {
		background: $widget-bgcolor 0% 0% no-repeat padding-box;
		border: 1px solid map.get($darktheme-colors, "bordercolor");
		color: $base-color;
	}
	
}

// /*
// ---------------------------------------------------------------------------------------
//     End   Dark theme style
// ---------------------------------------------------------------------------------------
// */

// /*
// ---------------------------------------------------------------------------------------
//     White theme style
// ---------------------------------------------------------------------------------------
// */


.smart-style-2 {
	table.dataTable tbody tr.selected,
	table.dataTable tbody tr.selected:hover {
		color: $primary-color !important;
	}
	
	.itemEditButton {
		&:hover {
			color: $primary-color !important;
		}
	}
	#content {
		background: $primary-color !important;
	}
	a.iom_btn.nav_btn {
		color: $light-nav-font-color !important;
	}
	.logoutSection{
		a.iom_btn.nav_btn{
			&:hover {
				border-color: $common_base_color !important;
				background: $common_base_color !important;
				color: $primary-color !important;
			}
		}
	}
	

	.successchangepassDiv {
		background-color: #F7F7F7 !important;
	}

	.btn-primary {
		background: $primary-color !important;
		border: 2px solid $common_base_color;
		color: $content-bg-color !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;

		&:hover {
			background: $common_base_color !important;
			color: $primary-color !important;
			border: 2px solid $common_base_color;
		}

		&:focus {
			border-color: $common_base_color !important;
		}
	}

	.addonItem {
		color: #000 !important;
	}

	.connector-form {

		.inputSection,
		.selectSection {
			.form-control {
				background-color: #EEEEEE !important;
				border: 1px solid #EEEEEE !important;
			}
		}
	}

	.connectorContainer {
		.serviceLbl {
			color: #000 !important;
		}
	}

	.serviceBody {
		label {
			color: #000;
		}

		.inputSection,
		.selectSection {
			label {
				color: #000 !important;
			}
		}
	}

	.serviceForm {

		formly-field,
		formly-wrapper-form-field {
			label {
				color: #000 !important;
			}

			input {
				border-top-right-radius: 0px !important;
				border-bottom-right-radius: 0px !important;
			}
		}
	}

	.connectorlblleft,
	.connectorlbl {
		color: #000 !important;
	}

	.setCircleactive {
		color: #00eba9;
		font-size: 10px !important;
	}

	#main {
		background-color: $primary-color !important;
		height: 100% !important;
	}

	// <!---------subscripition scss------------------->

	.theme-header-text-color {
		color: #212529 !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		font-size: 13px;
		border-radius: 15px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $common_base_color !important;
		letter-spacing: 0px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 15px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $secondary-color;
		opacity: 1;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		background-color: $primary-color !important;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border-radius: 10px;
	}

	// .table-bordered>tbody>tr>td {
	// 	border: 1px solid map.get($whitetheme-colors, "bordercolor");
	// }

	.currentButton {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		color: $common-color !important;
		border-color: map.get($whitetheme-colors, "bordercolor") !important;
		cursor: not-allowed;
	}

	.currentButtonent {
		border-color: map.get($whitetheme-colors, "bordercolor") !important;
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.currentPlan {
		background-color: map.get($whitetheme-colors, "bordercolor") !important;
		color: $secondary-color !important;

		&:hover {
			background: map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.emptyCol {
		border-top-right-radius: 0px !important;

		border : {
			top: 1px solid transparent !important;
			left: 1px solid transparent !important;
			right: 1px solid transparent !important;
			bottom: 1px solid transparent !important;
		}

	}

	.selectButton {
		border-radius: 0%;
		padding: 3px 15px;
		background-color: $primary-color;
		border: 2px solid $common_base_color;
		border-radius: 20px;
		color: $secondary-color;
		font-size: 14px;
		outline: none;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border-color: $common_base_color;
		}
	}

	.nextBtn {
		font-size: 13px;
		// width: 178px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}
	}

	.cancelBtn {
		// top: 728px;
		// left: 560px;
		// width: 100px;
		// height: 38px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		background: #F7F7F7 0% 0% no-repeat padding-box !important;
		color: $common-color !important;
		font-size: 13px;
		padding: 3px 15px !important;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $primary-color !important;
		}

	}

	.jarviswidget>div {
		border: none
	}

	.pricingTable table {
		color: $common-color !important;
	}


	.bootstrapWizard li .step {
		background-color: $primary-color;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		color: $secondary-color;
	}

	.lightBackgroundColor {
		background-color: $primary-color !important;
		// background-color: none !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;
		margin-left: 13px;
	}

	.left-div {
		background-color: $primary-color !important;
		// border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;
	}

	.card-div {
		border-radius: 5px;
		background-color: $primary-color !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		padding-bottom: 0px;

		&:hover {
			background-color: #EEEEEE !important;
		}
	}

	.textColor {
		color: $secondary-color !important;
		// background: #202124  !important;

		&:hover {
			background: transparent !important;

		}
	}

	// .NextconfirmLabel {
	// 	margin-left: 100px;
	// 	font-size: 13px;
	// 	width: 193px;
	// 	letter-spacing: 0px;
	// 	color: $widget-bgcolor;
	// 	margin-top: 5px;
	// }

	.headData {
		background-color: $primary-color;
		color: $common-color;
	}

	.jarviswidget-color-darken header {
		background: none !important;
		// background: map.get($whitetheme-colors, "popup-bg-color") !important;
		color: #4C4F53 !important;
		// border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.jarviswidget>header {
		color: #333;
		// border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border: none;
	}

	.back-btn {
		background: $subscribe-bgcolor 0% 0% no-repeat padding-box;
		border-color: $subscribe-bgcolor;
		font-size: 13px;
	}

	// <!---------END subscripition scss------------------->
	.applicationHead {
		background: $primary-color 0% 0% no-repeat padding-box;
		color: black !important;
	}

	#left-panel {
		padding-right: 0px !important;
		padding-left: 0px !important;
		padding-bottom: 0px !important;
		padding-top: 0px !important;

		nav {
			ul {
				li {
					border-bottom: 1px solid $common_base_color;

					&:hover {
						background: $common_base_color !important;
						color: $primary-color !important;
						border: 1px solid #3B3B3B33;
					}
				}

				li.active {
					background-color: $common_base_color !important;

					a {
						color: #fff !important;
					}

					ul {
						li {
							color: #202427 !important;

							&:hover {
								color: $primary-color !important;
								a {
									color: $primary-color !important;
									background: $common_base_color !important;
								}
							}

							a {
								color: $light-nav-font-color !important;
							}

						}

						li.active {
							a {
								color: $primary-color !important;
							}
						}
					}
				}

				li.open {
					li:hover {
						a {
							color: $primary-color !important;
						}
					}
				}
			}
		}
	}

	nav {
		ul {
			li.active {
				a {
					font-weight: 400;

					&:before {
						color: #202427;
					}
				}
			}
		}

		ul {
			li {
				a {
					color: $light-nav-font-color !important;
					padding: 12px 17px 12px 16px !important;
					font-size: 16px !important;
					&:focus,
					&:hover {
						color: $primary-color !important;
						background: $common_base_color !important;
					}
					i {
						font-size: 17px !important;
						vertical-align: 0 !important;
					}
				}
			}

			li.open {
				a {
					color: $light-nav-font-color !important;
					font-weight: 400;
				}

				&:focus,
				&:hover {
					color: $primary-color !important;
					background: $common_base_color !important;
				}
			}

			li.open a b {
				color: $light-nav-font-color !important;
			}

			li.open.active a b {
				color: $light-nav-font-color !important;
			}

			li.open:hover>a {
				color: $primary-color !important;
			}

			li.open:hover>a b {
				color: $primary-color !important;
			}
		}
	}

	nav ul ul {
		li {
			background-color: #dbdbdb;

			a {
				padding-left: 43px !important;
				padding-top: 6px !important;
				padding-bottom: 6px !important;
				color: $primary-color;
				font-size: 14px !important;

				&:focus,
				&:hover {
					color: #202427 !important;
					background: $common_base_color !important;
				}
			}
		}
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	// nav ul li a {
	// 	color: $primary-color !important;
	// 	font-size: 14px !important;

	// 	&:focus,
	// 	&:hover {
	// 		background: $common_base_color !important;
	// 	}
	// }
	.notification-body {
		// .unread {
		// 	background-color: map.get($whitetheme-colors, "bgcolor") !important;
		// }

		.from {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.msg-body {
			color: $subtext-color !important;
		}

		.activityTime {
			color: $subtext-color !important;
		}

		li {
			span {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			}
		}
	}

	.ajax-dropdown {
		border: 1px solid #d9d9d9 !important;
	}

	#fullscreen {
		.svg-icon {
			background-image: url("../img/iconSvg/light-theme/Path222.svg");
		}
	}

	#header {
		.notification-svg {
			background-image: url("../img/iconSvg/light-theme/39-Notification.svg");
		}

		#activity.activity-svg {
			background-image: url("../img/iconSvg/light-theme/Page-1.svg");
		}

		.collapseMenu {
			.collaspe-menu-svg {
				background-image: url("../img/iconSvg/light-theme/Path 224.svg");
			}

			.collaspe-menu-sub-svg {
				background-image: url("../img/iconSvg/Group224.svg");
				cursor: pointer;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}
		}

		.header-btn {
			box-shadow: none;
		}

		.header-btn-list {
			a {
				box-shadow: none;
			}
		}

		.menu-item {
			&:not(:last-child) {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: unset !important;
			}

			.webComponetsBorder {
				// border-right: 1px solid map.get($whitetheme-colors, "bordercolor");
				border: none;
			}

			&:hover {
				background-color: map.get($whitetheme-colors, "bordercolor");
			}

			.webComponetsHoverColor {
				&:hover {
					background-color: map.get($whitetheme-colors, "bordercolor");
				}
			}
		}

		.menu-item.no-hover {
			&:hover {
				background-color: map.get($whitetheme-colors, "bgcolor");
			}
		}
	}

	.header-dropdown-list {
		a.dropdown-toggle {
			color: map.get($whitetheme-colors, "color") !important;
		}

		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}
			}

			.active {
				a {
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
				}
			}
		}
	}

	.theme-reverse-text-color {
		color: $secondaryFocus !important;
		color: $primary-color !important;
	}

	.theme-icon {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-header-text-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.theme-subtext-color {
		color: $subtext-color !important;
	}

	.dashboard {
		.jarviswidget {
			div {
				background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
				border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}

	.profileBackground {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	#ribbon {
		.breadcrumb {
			li {
				color: map.get($whitetheme-colors, "panel-color");

				&:last-child {
					color: map.get($whitetheme-colors, "color") !important;
				}
			}
		}
	}

	.userProfile {
		.applicationBackGround {
			background-color: $primary-color !important;
			// margin-left: 5px;
			// margin-right: -5px;
			padding: 0;
		}

		.theme-text-color {
			color: $secondary-color !important;
		}
	}

	.border {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 10px;
	}

	.subList {
		&:not(:last-child) {
			// border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			border-bottom: 1px solid $primary-color !important;
		}
	}

	.theme-fade-color {
		color: $subtext-color !important;
	}

	.applicationHeadModal {
		background: map.get($whitetheme-colors, "background-color") !important;
	}

	.modalBodyBgColor {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.spanBoderBottom {
		border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	// .jarviswidget-color-darken {
	// 	header {
	// 		background: map.get($whitetheme-colors, "background-color") !important;
	// 	}
	// }

	.theme-bg-color {
		background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.theme-header-color {
		color: $common-color !important;
	}

	// .footerTextColor {
	// 	color: $primary-color !important;
	// }

	.login-info {
		color: #212529 !important;
	}

	.auth-text-color {
		color: map.get($whitetheme-colors, "bordercolor") !important;
	}

	.menuIconsOption {
		img {
			filter: brightness(100) invert(1);
		}
	}

	.menuSelected {
		background-color: map.get($whitetheme-colors, "bordercolor");
	}

	.account-table-div {
		.dt-toolbar-footer {
			border: 1px solid map.get($whitetheme-colors, "bgcolor") !important;
			margin-top: -6px;
			box-shadow: none !important;
			background-color: map.get($whitetheme-colors, "bgcolor") !important;
			width: 100% !important;

			.pagination {
				.paginate_button.active {
					a {
						background-color: map.get($whitetheme-colors, "color") !important;
						border-color: map.get($whitetheme-colors, "color") !important;
						color: map.get($whitetheme-colors, "commonbgcolor") !important;
					}
				}
			}
		}
	}

	.account-table {
		.history_action {
			text-decoration: underline !important;
		}

		td {
			color: map.get($whitetheme-colors, "color") !important;
			padding-left: 24px !important;
		}

		th {
			color: map.get($whitetheme-colors, "color") !important;
			padding-left: 24px !important;
		}
	}

	nav ul li.active>a:before {
		content: unset !important;
	}

	.dropdown-icon-menu {
		>ul {
			>li {
				.btn {
					background: none;
				}
			}
		}
	}

	.header-btn {
		background: none;
	}

	.body-image {
		border-radius: 100%;
	}

	.profileDeatils {
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		margin-top: -42px;
		background-color: map.get($whitetheme-colors, "textcolor");
		border-radius: 10px;
		height: 601px;
	}

	.theme-text-color {
		color: $secondary-color;
	}

	.jarviswidget>div {

		// border-right-color: $primary-color !important;
		// border-bottom-color: $primary-color !important;
		// border-left-color: $primary-color !important;

	}

	.lstTrncnTbl {
		.dt-toolbar {
			background: none !important;
			border-bottom: none;
		}

		td {
			a {
				color: $common_base_color;
			}
		}
	}

	.company_details_section {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.contact_infrmtn_sctn {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;

		.detailLbl {
			color: $common-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: map.get($whitetheme-colors, "base_bgcolor");
					border: 1px solid map.get($whitetheme-colors, "base_bgcolor");
					font-size: 14px;
					color: $common-color;
				}
			}
		}
	}

	.comapny_details_form {
		.detailLbl {
			color: $common-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: map.get($whitetheme-colors, "base_bgcolor");
					border: 1px solid map.get($whitetheme-colors, "base_bgcolor");
					font-size: 14px;
					color: $common-color;
				}
			}
		}
	}



	.company_detail_head {
		// font-size: 16px;
		color: $common-color !important;
		// padding-left: 15px;
	}


	.editEnabled {
		.detailLbl {
			color: $common-color !important;
			font-size: 14px !important;

			.commoninputStyle {
				.form-control {
					background-color: $primary-color !important;
					// border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
				}
			}
		}
	}

	.detail_save_btn,
	.detail_save_Btn,
	.approveBtn,
	.detailInfo_save_btn,
	.approveBtn,
	.gotoEdi,
	.iom_btn {
		color: $common-color;
		&:hover {
			color: $primary-color;
		}
	}

	// .detailInfo_save_btn {
	// 	border-radius: 20px;
	// 	background: $primary-color;
	// 	border: 2px solid $common_base_color;
	// 	color: $common-color;
	// 	font-size: 12px;
	// }


	.editDisabled {
		background: none;
	}

	.editEnabled {
		background-color: #F7F7F7 !important;
	}

	.profile_details {
		.prf_name {
			color: $common-color;
		}
	}

	#left-panel b.collapse-sign {
		// display: none;
	}

	.theme-content-color {
		color: map.get($whitetheme-colors, "textcolor");
	}

	.addressdiv {
		background: map.get($whitetheme-colors, "base_bgcolor") 0% 0% no-repeat padding-box !important;
	}

	.theme-button-color {
		background-color: $primary-color;
		color: $common-color;
	}

	.tranhoverTable select {
		background-color: inherit;
		color: inherit;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		border-radius: 5px;

		option {
			background: map.get($whitetheme-colors, "base_bgcolor") 0% 0% no-repeat padding-box;
			color: $secondary-color;
		}
	}

	.tranhoverTable input[type=radio]:checked:after {
		border: 2px solid map.get($whitetheme-colors, "bordercolor");
	}


	.butttonBluecolor {
		background-color: $primary-color !important;
		border: 2px solid $common_base_color !important;
		color: $widget-bgcolor !important;
		// top: 861px;
		// left: 1139px;
		// width: 161px;
		// height: 38px;
		border-radius: 20px;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}
	}

	.gridSectionFirst {
		.payMethodSection {
			border: 1px solid map.get($whitetheme-colors, "bordercolor");

			.payHead {
				span {
					color: $common-color;
					font-weight: 600;
				}
			}


		}

		.techContactSection {
			border: 1px solid map.get($whitetheme-colors, "bordercolor");

			.techHead {
				color: $common-color;
				font-weight: 600;
			}
		}
	}

	.technical_details_form {
		.detailLbl {
			color: $common-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: map.get($whitetheme-colors, "base_bgcolor");
					border: 1px solid map.get($whitetheme-colors, "base_bgcolor");
					font-size: 14px;
					color: $common-color;
				}
			}
		}
	}

	.gridSectionTwo {
		.billingContactSection {
			border: 1px solid map.get($whitetheme-colors, "bordercolor");


			.billHead {
				font-size: 16px;
				color: $common-color;
				font-weight: 600;
			}
		}
	}

	.billing_details_form {
		.detailLbl {
			color: $common-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: map.get($whitetheme-colors, "base_bgcolor");
					border: 1px solid map.get($whitetheme-colors, "base_bgcolor");
					font-size: 14px;
					color: $common-color;
				}
			}
		}
	}

	.gridSectionThree {
		.estimatedBillSection {
			padding: 10px 30px;
			border: 1px solid map.get($whitetheme-colors, "bordercolor");
			margin: 20px 0px 20px 20px;
			width: 96%;
			border-radius: 10px;
			height: 584px;

			hr {
				border-top: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}

			.estBillHead {
				font-size: 16px;
				color: $common-color;
				font-weight: 600;
			}

			.billItemDesc {
				font-size: 14px;
				color: $common-color;
			}

			.billItemPrice {
				font-size: 14px;
				color: $common-color;
			}
		}
	}

	.cardNum,
	.cardOwnerName,
	.cardExp_Date {
		color: $common-color !important;
	}

	.editPaymentHeader {
		color: $common-color !important;
		font-size: 16px !important;
	}

	.payContent {
		background: map.get($whitetheme-colors, "commonbgcolor") !important;
	}

	.edit_details_form {
		.detailLbl {
			color: $common-color;
			font-size: 14px;

			.commoninputStyle {
				.form-control {
					height: 40px !important;
					border-radius: 6px !important;
					background-color: $primary-color;
					border: 1px solid map.get($whitetheme-colors, "bordercolor");
					font-size: 14px;
					color: $common-color;
				}
			}
		}
	}

	// .PaySctn{
	// 	background-color: $primary-color !important;
	// 	border:1px solid map.get($whitetheme-colors, "bordercolor") !important;
	// }
	.modal-content {
		// background: #F7F7F7 !important;
		// border-radius: 10px;
		// background-color: $widget-bgcolor !important;
		// border: 1px solid $activetab-color;
		// color:$primary-color;
		// width: 900px;
		background-color: $primary-color !important;
		border: 1px solid #666666;
		color: $widget-bgcolor;
		border-radius: 10px;
	}

	.underline {
		border-color: map.get($whitetheme-colors, "bordercolor");
	}

	.subscription-sum {
		border-top: 2px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.card-fields {
		.form-fields-wrapper {
			border-bottom: 1px solid #d5e1ec;

			.icon {
				width: 25px;
				height: auto;
			}

			.form-fields {
				width: calc(100% - 35px);
				margin-left: auto;

				input {
					outline: none;
					border: none;
					width: 100%;
					line-height: 50px;
					padding: 0;

					font: {
						weight: 500;
						size: 20px;
					}

					background-color: map.get($whitetheme-colors, "commonbgcolor");

					&::-webkit-input-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					&::-moz-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					&:-ms-input-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}

					& :-moz-placeholder {
						color: $header-bgcolor;
						font-size: 16px;
					}
				}
			}
		}

		.checkbox {
			color: $payment-color;
		}

		.card-errors {
			color: $invalid-color;
			font-size: 13px;
			margin: 10px 0 0 0;
		}
	}

	.payment-detail {
		width: 100%;
		max-width: 1010px;
		background-color: map.get($whitetheme-colors, "commonbgcolor");
		margin: auto;
		padding: 10px 10px 6px 10px;
	}

	.nav ul .active>a {
		color: #202427 !important;
		font-weight: 700;
	}

	.ElementsApp,
	.ElementsApp .InputElement {
		color: $common-color !important;
		font-weight: 300;
		font-family: "Helvetica Neue", Helvetica, sans-serif;
		font-size: 18px;
	}

	.prf_name,
	.prf_url {
		color: $common-color !important;
	}

	.sub-btn {
		border: 2px solid $common_base_color !important;
		color: #171717 !important;

		&:hover {
			background-color: $common_base_color !important;
			color: $primary-color !important;
		}
	}

	.manage-btn {
		font-size: 13px;
		border: 2px solid map.get($whitetheme-colors, "bordercolor") !important;
		border-radius: 20px;
		background-color: $primary-color !important;
		color: #171717 !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
			border: 2px solid $common_base_color !important;
		}
	}

	.ganerateButton {
		background: $primary-color;
		color: $common-color;
	}

	.stepsChecked {
		font-weight: 900;
		background-color: $header-bgcolor;
	}

	.next_payment {
		background-color: #CCCCCC;
		color: $common-color;
	}

	.selectExpiry {
		.form-control {
			height: 40px !important;
			border-radius: 6px !important;
			background-color: $primary-color;
			border: 1px solid map.get($whitetheme-colors, "bordercolor");
			font-size: 14px;
			color: $common-color;
			margin-top: 5px;
		}
	}

	.form-control.is-invalid {
		background-image: none !important;
		border-color: #dc3545 !important;
	}

	label {
		font-weight: 400;
	}

	.profileEditTextBox {
		background: $primary-color 0% 0% no-repeat padding-box !important;
	}

	.profileModalBtn {
		padding: 10px;
		// margin-left: -15px;
		background-color: $primary-color !important;
		border: 2px solid $common_base_color !important;
		color: $common-color !important;
		border-radius: 19px;

		&:hover {
			background: $common_base_color !important;
			color: $common-color !important;
		}
	}

	.sub-img {
		background: #666666 0% 0% no-repeat padding-box;
	}

	.row-divider {
		border-color: map.get($whitetheme-colors, "bordercolor") !important;
	}

	.dot-div {
		color: map.get($whitetheme-colors, "textcolor") !important;
	}

	// BUY-ADDON start

	.theme-header-text-color {
		color: #212529 !important;
	}

	.theme-header-color {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.cancel {
		text-align: left;
		width: auto;
		// min-width: 50px !important;
		font-size: 13px;
		border-radius: 15px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border: 2px solid $common_base_color !important;
		letter-spacing: 0px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;
	}

	.TotalLabel {
		top: 275px;
		left: 560px;
		height: 15px;
		text-align: left;
		font: normal normal bold 31px/23px Open Sans;
		letter-spacing: 0px;
		color: $secondary-color;
		opacity: 1;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		// background-color: $primary-color !important;
	}

	.paymentSectionDetails {
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border-radius: 10px;
		margin-left: -1px;
	}

	.paymentOptions {
		display: none;
		// background-color: map.get($whitetheme-colors, "commonbgcolor") !important;
		border-radius: 10px;
	}

	.table-bordered>tbody>tr>td {
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody>tr>td:first-child {
		// border-left: 2px solid map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody>tr>td:last-child {
		// border-right: 2px solid map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody>tr:last-child>td {
		// border-bottom: 2px solid map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody.headData>tr:first-child>td,
	.table-bordered>tbody.table-headData>tr:first-child>td {
		// border-top: 2px solid map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody.headData>tr>td.switchCol,
	.table-bordered>thead.headData>tr>th.switchCol,
	.table-bordered>tbody.table-headData>tr>td.switchCol {
		box-shadow: -1px 0 0 0 map.get($whitetheme-colors, "bordercolor");
	}

	.table-bordered>tbody.headData>tr>td:first-child.switchCol,
	.table-bordered>tbody.table-headData>tr>td:first-child.switchCol {
		box-shadow: 0 -1px 0 0 map.get($whitetheme-colors, "bordercolor");
	}

	.currentPlan {
		background-color: map.get($whitetheme-colors, "bordercolor") !important;
		color: $secondary-color !important;

		&:hover {
			background: map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.emptyCol {
		background-color: $primary-color !important;

		border : {
			top: 1px solid transparent !important;
			left: 1px solid transparent !important;
			right: 1px solid transparent !important;
			bottom: 1px solid transparent !important;
		}

	}



	.nextBtn {
		font-size: 13px;
		// width: 178px;
		background: $primary-color 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}
	}

	.cancelBtn {
		// top: 728px;
		// left: 560px;
		// width: 100px;
		// height: 38px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		background: #F7F7F7 !important;
		color: $common-color !important;
		font-size: 13px;
		padding: 3px 15px !important;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}

	}

	.jarviswidget>div {
		border: none
	}

	.pricingTable table {
		color: $common-color !important;
	}

	.nextstep {
		font-size: 13px;
		// width: 178px;
		background: #F7F7F7 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $common-color !important;
		}
	}

	.bootstrapWizard li .step {
		background-color: $primary-color;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		color: $secondary-color;
	}

	.lightBackgroundColor {
		background-color: $primary-color !important;
		border: 1px solid map.get($whitetheme-colors, "bordercolor");
		border-radius: 10px;
		margin-left: 13px;
	}

	.textColor {
		color: #4C4F53 !important;
		// background: #FFFFFF !important;

		&:hover {
			// background: #FFFFFF !important;
			// background: transparent !important;

		}
	}

	.NextconfirmLabel {
		color: $widget-bgcolor !important;
		border: 1px solid $whiteborder-color;
	}

	.cardconfirmLabel {
		color: $widget-bgcolor !important;
		border: 1px solid $whiteborder-color;
	}

	// .headData {
	// 	background-color: inherit !important;
	// 	color: $common-color;
	// 	margin-top: 10px;
	// }



	.back-btn {
		background: $subscribe-bgcolor 0% 0% no-repeat padding-box;
		border-color: $subscribe-bgcolor;
		font-size: 13px;
	}

	.buy-addon {
		.navigationList {
			.nav.nav-tabs {
				li {
					a {
						span {
							color: $common-color !important;
						}

						&:hover {
							background-color: $common_base_color !important;
							border: 1px solid $common_base_color !important;
						}
					}
				}
			}
		}
	}

	.package-name {
		.packageHeader {
			color: $secondary-color !important;
		}
	}

	.yearlyData {
		span.price {
			color: $secondary-color !important;
		}
	}

	.buy-addon {
		.checkout {
			background-color: $primary-color;
		}
	}

	.table-headData {
		background-color: $primary-color;
		color: $common-color;
		margin-top: 10px;
	}

	.cart-btn {
		background-color: $secondary-color;
	}

	// BUY-ADDON end

	.blacktooltip {
		background: $primary-color 0% 0% no-repeat padding-box;
		border: 1px solid $whiteborder-color;
		color: map.get($whitetheme-colors, "color");

		.insideDivUl {
			li {
				border-bottom: 1px solid map.get($whitetheme-colors, "bgcolor");
			}

			li:last-child {
				border-bottom: unset;
			}
		}
	}

	.error-blacktooltip{
		background: $primary-color 0% 0% no-repeat padding-box;
		border: 1px solid $whiteborder-color;
		color: map.get($whitetheme-colors, "color");

		.insideDivUl {
			li {
				border-bottom: 1px solid map.get($whitetheme-colors, "bgcolor");
			}

			li:last-child {
				border-bottom: unset;
			}
		}
	}

	.optionSelect {
		background-color: map.get($darktheme-colors, "textcolor") !important;
		color: #202124 !important;
		border-color: $whiteborder-color !important;
	}




	.table {
		width: 100%;
		color: $common-color;
		border-collapse: collapse;
		// background-color: $primary-color !important;
		border-color: $whiteborder-color;
		border-radius: 5px;
	}

	.history-table th {
		border-color: $whiteborder-color !important;
		background: #EEEEEE 0% 0% no-repeat padding-box;
		color: $common-color;
	}

	.commonHead {
		background-color: #EEEEEE !important;
		color: $base-color;
		font-size: 14px;
		border: unset !important;
	}

	.algntblTranscation {
		background: $primary-color !important;
	}

	.historyLabel {
		color: $common-color;
	}

	table.history-table {
		margin-top: 0 !important;
		// border: 1px solid #CCCCCC !important;
		// border-radius: 5px 5px 0px 0px;
		border-radius: 5px !important;
		background-color: $primary-color;

		tr {
			&:hover {
				background-color: #EEEEEE !important;
				color: $common-color;
			}
		}

	}

	.history-table .history_action {
		text-decoration: underline !important;
	}

	.table-bordereds>tbody>tr>td {
		border: 1px solid $whiteborder-color;
		// z-index: 10px;
		// background: #FFFFFF !important;
	}

	#userChoiceSelect {

		.container,
		.durationDetailFields,
		.successNote {
			background-color: $fontbackground-color !important;
		}

		.commonTimeData {
			background-color: $primary-color !important;
			border: 1px solid #EEEEEE !important;
		}

		.fieldHeader {
			color: $common-color !important;
		}

		// .durationrow,.durationEndRow{
		// 	background-color: $primary-color !important;
		// }
		.commonFormField {

			input,
			textarea {
				background-color: $primary-color !important;
				border: 1px solid #EEEEEE;
				color: $common-color !important;
			}
		}

		textarea {
			background-color: $primary-color !important;
			border: 1px solid #EEEEEE;
			color: $common-color !important;
		}
	}

	.yearlabel {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		background-color: $primary-color;
	}

	.cancellabel {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		background-color: $primary-color;
	}

	.table-striped tbody tr:nth-of-type(odd) {
		background-color: $primary-color;
	}

	.gradientDiv {
		background: #EEEEEE 0% 0% no-repeat padding-box !important;
		border: 1px solid $whiteborder-color;

	}

	.hoverBtn {
		background: $primary-color !important;
	}

	.spnFilePath {
		b {
			color: $primary-color !important;
		}
	}

	.dataTables_processing {
		background: url(../img/spin-loader.svg) no-repeat !important;
	}

	.pagination>.active>a {
		background-color: $common_base_color !important;
		border-color: $common_base_color !important;
		color: $primary-color
	}

	.pagination>li>a {
		background-color: $primary-color !important;
		color: $common-color;
	}

	.pagination>li:first-child>a,
	.pagination>li:first-child>span {
		margin-left: 0;
		border-bottom-left-radius: 2px;
		border-top-left-radius: 2px;
		background-color: $primary-color !important;
		// color: #BEEB20;
	}

	.pagination>li:last-child>a,
	.pagination>li:last-child>span {
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		background-color: $primary-color !important;
		// color: #BEEB20;

	}

	table.pending-list-table {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		margin-top: 0px !important;
		border-radius: 5px;
	}

	.table {
		thead {
			tr {
				th {
					border-color: $whiteborder-color;
				}
			}
		}

		tbody {
			tr {
				td {
					border-color: $whiteborder-color;
				}
			}
		}
	}

	.history-btn {
		color: $common-color;

		&:hover {
			background-color: $common_base_color !important;
			color: $common-color;
		}
	}

	.darkHeaderIcon,
	.lightHeaderIcon {
		color: $common-color !important;
	}

	.list-area {
		background-color: map.get($darktheme-colors, "bgcolor");
		border-radius: 0px;
	}

	.user_roles {
		.c-btn {
			color: $primary-color;
			border-color: #424242;
			background: #616161;
		}
	}

	.serviceTheme {
		background-color: map.get($darktheme-colors, "textcolor") !important;
		// width: 1200px !important;
		border-radius: 0px;

		.ui-dialog-titlebar {
			background-color: map.get($darktheme-colors, "textcolor") !important;
			border-bottom: none !important;
			width: 100% !important;

			.ui-dialog-title {
				width: 100% !important;

				.widget-header {
					display: flex;
					justify-content: normal;
				}

				.shortDescription {
					text-align: center;
				}
			}

			.ui-dialog-titlebar-close {
				position: absolute !important;
				top: 25px;
				right: 5px;
			}

			h5 {
				color: $common-color !important;
			}

			h1 {
				color: $common-color !important;
			}

			.ui-button {
				color: $common_base_color;
				opacity: 1;
			}
		}

		.ui-dialog-content {
			background-color: map.get($darktheme-colors, "textcolor") !important;

			.service_modal {
				background-color: map.get($darktheme-colors, "textcolor") !important;

				.modal-content {
					box-shadow: unset !important;
					-webkit-box-shadow: unset !important;
					border: unset !important;
					background-color: map.get($darktheme-colors, "textcolor") !important;
				}
			}
		}

	}

	.user-role-form {
		.multiselect-dropdown {
			.filter-textbox {
				input {
					background: $primary-color !important;
				}
			}

			.multiselect-item-checkbox {
				border-bottom: none !important;

				input {
					+ {
						div {
							&:before {
								color: $common-color !important;
								border: 2px solid $common-color !important;
							}

							&:focus {
								color: $common-color !important;
								border: 2px solid $common-color !important;
							}

							&:after {
								border-color: $common-color !important;
							}
						}
					}
				}
			}
		}
	}

	.price_calcultation_form {
		.multiselect-dropdown {
			.filter-textbox {
				input {
					background: $primary-color !important;
				}
			}

			.multiselect-item-checkbox {
				border-bottom: none !important;

				input {
					+ {
						div {
							&:before {
								color: $common-color !important;
								border: 2px solid #645b5b !important;
							}

							&:focus {
								color: $common-color !important;
								border: 2px solid #645b5b !important;
							}

							&:after {
								border-color: #645b5b !important;
							}
						}
					}
				}
			}
		}
	}

	.reMark {
		color: $common-color;
		border: 1px solid $whiteborder-color;
	}

	.darkHeaderIcon,
	.lightHeaderIcon,
	.editButtonsLabel {
		color: $common-color !important;
	}

	.spin-loader {
		background: url(../img/spin-loader_small.svg) no-repeat;
	}

	.big-spin-loader {
		background: url(../img/spin-loader.svg) no-repeat !important;

	}

	.errorOccured {
		border-color: #dc3545 !important;
	}

	.NextconfirmLabels {
		color: $common-color;
		border: 1px solid $whiteborder-color;
	}

	.btnModalSave {
		float: right;
		background-color: $primary-color;
		border: none;
		color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color;

		&:hover {
			color: $common-color !important;
		}
	}

	.btnModalClose {
		float: left;
		background-color: $primary-color;
		border: none;
		color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;
	}

	.successDiv {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.successinviteDiv {
		// background-color: #171819;
		background-color: #F7F7F7 !important;
	}

	.successappDiv {
		background-color: #F7F7F7 !important;
	}

	.successcmpimgDiv {
		background-color: #F7F7F7 !important;
	}

	.successcmpprofileimgDiv {
		background-color: #F7F7F7 !important;
	}

	.successuserdeleteDiv {
		background-color: #F7F7F7 !important;
	}

	.disabledColor {
		background-color: #F7F7F7 !important;
		color: $common-color !important;
		border-color: $whiteborder-color !important;
	}

	.cardimg {
		filter: brightness(100) invert(1);
	}

	.userInfromation,
	.seperator,
	.modal_header {
		color: $common-color !important;
	}

	.inv-img {
		content: url('../img/invoice.svg') !important;
	}

	.badge-disable-iom {
		color: $darkTextColor !important;
	}

	.checkmark {
		border: 2px solid #666666
	}

	// .checkbox-container .checkmark:after {
	// 	border: solid #212529 !important;
	// }
	.checkbox-container .checkmark:after {
		left: 7px;
		top: 1px;
		width: 8px;
		height: 16px;
		border: solid #959595;
		border-width: 0 3px 3px 0;
		transform: rotate(45deg);
	}

	.vertical-line {
		background-color: #CCCCCC;
	}

	.card_details_nocard {
		p {
			color: #111;
			font-size: 14px;
		}
	}

	.subPlanData {
		color: #111;
	}

	.table-wrapper {
		background-color: #EEEEEE 0% 0% no-repeat padding-box !important;

		table {
			tbody {
				tr {
					td {
						background-color: $primary-color !important;
					}
				}
			}
		}
	}

	.tooltipRole {
		&:hover {
			.tooltiptextRole {
				visibility: visible;
				border: 1px solid $whiteborder-color;
				color: $common-color;

				&:hover {
					background: $primary-color 0% 0% no-repeat padding-box !important;
				}

				.insideDivUl {
					li {
						border-bottom: 1px solid $whiteborder-color;
					}

					li:last-child {
						border-bottom: unset;
					}
				}
			}
		}

		.tooltiptextRole {

			background-color: $primary-color;
			color: $common-color;



		}

	}

	.header-formbtn {
		background-color: $primary-color !important;
		border: 2px solid $common_base_color !important;
		color: $common-color !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $secondary-color !important;
		}
	}

	.companyField,
	.comapnyLbl,
	.manageHeader,
	.comapnyHeaderLbl {
		color: #0c1214 !important;
	}

	.MessageBoxButtonSection button {
		color: #0c1214 !important;
	}

	.subscriptions-page {
		.table-bordered {

			// border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			>thead {
				>tr {
					background: $primary-color !important;

					>th {
						&:not(:first-child) {
							border: 1px solid map.get($whitetheme-colors, "bordercolor");
						}
					}
				}
			}
		}

		table.noFeature.table-bordered {
			>thead {
				>tr {
					background: $primary-color !important;

					>th {
						border: 1px solid map.get($whitetheme-colors, "bordercolor");
					}
				}
			}
		}

		td {
			background-color: $primary-color !important;
		}
	}

	.top-div {
		border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;

		.description-div {
			.description-content {
				border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			}
		}
	}

	.step-div {
		background: $primary-color;
	}

	.footer-div {
		background: $primary-color;
	}

	.algntblTranscation {
		border-radius: 8px;
		border: 1px solid $whiteborder-color !important;
		overflow: hidden;
		background: $primary-color !important;
	}

	.setselectHeight {
		border: 1px solid #BDBDBD;
		background-color: $primary-color;
		color: $secondary-color !important;
	}

	.successdeleteDiv {
		background-color: #F7F7F7 !important;
	}

	.btnModalClose {
		float: left;
		background-color: $primary-color;
		border: none;
		// color: $content-bg-color !important;
		border-radius: 15px;
		border: 2px solid $common_base_color !important;
	}

	.ui-datepicker {
		td {
			.ui-state-default {
				color: $tabsborder-color;
			}
		}

		.ui-datepicker-header {
			background-color: $primary-color;
		}
	}

	.ui-datepicker {
		td {
			.ui-state-highlight {
				color: $primary-color;
			}

			.ui-state-hover {
				color: $primary-color;
			}
		}
	}

	.iconForm {
		color: #000 !important;
	}

	.filter-top {
		color: #0C1214 !important;
	}

	.dashbaordlabel {
		background-color: unset !important;
		border: 1px solid $whiteborder-color !important;
		border-radius: 5px !important;
	}
              .companyslabel {
		background-color: unset !important;
		border-color: 1px solid #EEEEEE  !important;
		border-radius: 5px !important;
	}
          
	.ediLbl,
	.editoLbl {
		color: #000 !important;
	}

	.dashbaordlabel {
		color: #0C1214 !important;
	}

	table.positionTable {
		border: 1px solid $whiteborder-color !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border-radius: 5px;
	}

	table.bulkTable {
		border: 1px solid $whiteborder-color !important;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border-radius: 5px;
	}

	.headerContents {
		// padding: 0 0 0 7px;
		border-radius: 8px;
		border: 1px solid #dadce0 !important;
		// margin-left: 0 3px !important;
		overflow: hidden;
	}
	.headerfilterContents{
		border-radius: 8px;
		border: 1px solid #dadce0 !important;
		// z-index: 1000;
	}

	.headClr {
		color: #0C1214 !important;
	}

	.transactionHeads {
		color: #4C4F53 !important;
	}

	.detailLbl,
	.lblWhite {
		color: #0C1214 !important;
	}

	.amount-div {
		color: #4C4F53 !important;
		background-color: #EDEDED !important;
	}

	.nav-tabs {
		li {
			float: left;
			margin-bottom: -1px;
			width: 34%;
			text-align: center;
			border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
		}
	}

	.invoiceOverview .nav-tabs li {
		border-bottom: 1px solid $whiteborder-color !important;
	}

	.invoiceOverview .nav-tabs>li.active {

		a,
		a:hover,
		a:focus {
			cursor: default;
			background-color: transparent !important;
			border: 1px solid $whiteborder-color;
			border-bottom-color: transparent;
		}

		border-top: 1px solid $whiteborder-color !important;
	}

	.invoiceOverview .nav-tabs {
		li {
			a {
				&:hover {
					background-color: transparent !important;
					border: none;
				}
			}
		}
	}

	.headData {
		background-color: #EDEDED !important;
		color: #4C4F53 !important;
		border-color: $whiteborder-color !important;
	}

	.idTooltip,
	.btnTooltip,
	.errorTooltip,
	.companyTooltip {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");

	}

	.advace-header-formbtn {
		background-color: $primary-color !important;
		border: 2px solid $common_base_color !important;
		color: $common-color !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			color: $secondary-color !important;
		}
	}

	.headtabData {
		color: map.get($whitetheme-colors, "color") !important;
		border: none;
	}

	.topHead {
		background-color: $primary-color !important;
	}

	.error_text {
		color: $darkTextColor !important;
	}

	.tranhoverTable {
		tbody {
			tr {
				&:hover {
					color: map.get($whitetheme-colors, "color") !important;
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
					cursor: pointer;
				}
			}
		}
	}

	#header.colorChangeTest .maintenance {
		color: #666666;
	}

	.filterSection {
		.ng-select {
			.ng-select-container {
				.ng-value-container {
					.ng-placeholder {
						color: $common-color !important;
						font-size: 14px;
					}
				}
			}
		}

		.ng-select.ng-select-opened {
			>.ng-select-container {
				.ng-arrow {
					border-color: $common-color transparent transparent !important;
					border-color: transparent transparent $common-color !important;
				}
			}
		}

		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				.ng-option {
					color: $common-color !important;
					font-size: 12px !important;
				}
			}
		}
	}

	.disableForm .panelSector {
		.ng-select {
			.ng-select-container {
				border: 1px solid #6666 !important;

				.ng-value-container {
					.ng-placeholder {
						color: $common-color !important;
						font-size: 14px;
					}
				}
			}
		}

		.ng-select .requiredSection {
			.ng-select-container {
				border: 1px solid #dc3545;
			}
		}

		.ng-select.ng-select-opened {
			>.ng-select-container {
				.ng-arrow {
					border-color: $common-color transparent transparent !important;
					border-color: transparent transparent $common-color !important;
				}
			}
		}

		.ng-dropdown-panel {
			.ng-dropdown-panel-items {
				.ng-option {
					color: $common-color !important;
					font-size: 12px !important;
				}
			}
		}

		.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
			color: $common-color !important;
		}
	}

	// *********************Ebill css********************************************
	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		formly-field-select select option {
			background-color: unset !important;
		}

		.form-control {
			background: #fff !important;
			border: 1px solid #6666 !important;
			color: #000;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 14px;
		}

	}

	#wizard .wizard-items {
		border: 1px solid $whiteborder-color;
		background: #fff 0% 0% no-repeat padding-box;
	}

	#wizard .wizard-billeritem {
		border: 1px solid $whiteborder-color;
		background: #fff 0% 0% no-repeat padding-box;
	}

	#wizard .wizard-items .wizardDiv {
		border: 1px solid transparent;
		background: #cccccc 0% 0% no-repeat padding-box;
	}

	.form-control:disabled {
		background-color: #EEEEEE !important;
	}

	.docInput {
		width: 97%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important;
	}

	#wizard .wizard-companyitem {
		border: 1px solid $whiteborder-color;
		background: #fff 0% 0% no-repeat padding-box;
		border-radius: 10px;
	}

	//           .wizardcmp_format {
	// 	width: 20% !important;
	// 	border-radius: 10px !important;
	//           }
	.docLbl {
		width: 100%;
		color: #000;
		font-size: 14px;
	}

	#wizard .line-1 {
		background: $whiteborder-color;
	}

	.billTabSection {
		border: 1px solid $whiteborder-color;
	}

	.ebillFormFormly,
	.disableForm,
	.ebillForm {
		formly-field-select select option {
			background-color: unset !important;
		}

		.form-control {
			background: #fff !important;
			border: 1px solid #6666 !important;
			color: #000;
			height: 40px !important;
			border-radius: 6px !important;
			font-size: 14px;
		}

	}

	#wizard {
		color: #666666;
	}

	.docInput {
		width: 97%;
		margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important;
	}

	.btn-primary {
		background: #fff !important;
		border: 2px solid $common_base_color;
		color: #202124 !important;
		padding: 3px 15px;
		border-radius: 15px;
		font-size: 13px;
	}

	.common_wizardItemContainer .wizard_format {
		width: 50% !important;
	}

	.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		font-size: 13px !important;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid #6666 !important;
	}

	.companySelect .ng-select-container {
		border-radius: 10px !important;
		color: #000 !important;
		height: 42px !important;
		border: none;
	}

	.nav-tabs li {
		float: left;
		margin-bottom: -1px;
		width: 34%;
		text-align: center;
		border-bottom: 1px solid $whiteborder-color !important;
	}
	.nav-tabs li.active{
		border-bottom-color: transparent !important;
	}

	.biller_tabSection .nav-item {
		width: auto !important;
	}

	.biller_tabSection .nav-item a {
		margin-right: unset !important;
		background-color: unset !important;
		color: #000 !important;
		border-bottom: unset !important;
		border-left-color: #6666 !important;
		border-right-color: #6666 !important;
		border-top-color: #6666 !important;
	}

	.panelSector .ng-select .ng-select-container {
		background-color: transparent !important;
		color: #000 !important;
		border: 1px solid #6666 !important;

	}

	.emailMessage {
		border: 1px solid $whiteborder-color !important;
	}


	.fieldForm {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			border-top-right-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
			// border-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}
	}

	.comInput {
		width: 100%;
		// margin-right: 10px;
		border-radius: 10px !important;
		background-color: #EEEEEE !important;
		border-color: #EEEEEE;
		border: unset !important;
	}

	// *********************end Ebill css********************************************

	.ng-dropdown-panel.ng-select-bottom {
		border: 1px solid #6666 !important;
	}

	.nav_btn {
		color: #fff !important;

		&:hover {
			color: #202427 !important;
		}
	}

	.stepsinout-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}

		.connectorBorder select {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}
	.templatest-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border: 1px solid #ccc !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			border-bottom-right-radius: 10px !important;
			border-top-right-radius: 10px !important;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;
		}

		.connectorBorder select {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.connectorBorder select {
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}

	.businessconnect-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE !important;
			// border-radius: 10px !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			// border-color:none;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;

		}
	}

	.stepsinout-form {
		.input-group-append {
			.input-group-text {
				background-color: #EEEEEE;
				// border: 1px solid $header-bgcolor !important;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;

				&::before {
					border-left: 1px solid #A2A2A2;
				}
			}
		}
	}

	.businessconnect-form {
		.input-group-append {
			.input-group-text {
				background-color: #EEEEEE;
				// border: 1px solid $primary-color;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;

				&::before {
					border-left: 1px solid #A2A2A2;
				}
			}
		}
	}

	.defaultDot {
		background-color: #666;
	}

	.statusSection tr td:not(:last-child) {
		border-right: 2px solid $header-bgcolor;
	}

	.greyDot {
		background-color: black !important;
		opacity: 0.5;
	}

	.bp_connectorStatus,
	.square {
		background-color: hsla(0, 0%, 93.3%, .9333333333333333) !important;
	}

	.statusSection tr td {
		color: #000 !important;
	}

	.bpHeader,
	.bc_sectionHead,
	.invitationLbl,
	.searchLbl,
	.netComID,
	.businessLbl,
	.comapnyLbl {
		color: $common-color !important;
	}

	.company-select .ng-select-container,
	.companySelect .ng-select-container {
		border-radius: 10px !important;
		color: #000 !important;
		height: 42px !important;
		border: none;
	}

	.invoice-tab-header {
		border: 1px solid map.get($whitetheme-colors, "bgcolor") !important;
		border-bottom: none !important;
		border-left: none !important;
		border-right: none !important;
	}

	.nav-tabs.invoice-tab {
		>li.active {
			border-bottom: none !important;
			box-shadow: 0 -2px 0 $common_base_color !important;

			&:hover {
				background-color: transparent !important;
			}

			>a.invoice-tabhead {
				background-color: transparent !important;
				color: $common-color !important;

				&:focus {
					background-color: $primary-color !important;
					color: $common-color !important;
				}

				&:hover {
					background-color: $primary-color !important;
					color: $common-color !important;
				}
			}
		}

		>li {
			border-bottom: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
			border-right: 1px solid map.get($whitetheme-colors, "bordercolor") !important;

			&:last-child {
				border-right: none !important;
			}

			&:hover {
				border-bottom: 1px solid $whiteborder-color !important;
				border-top: none !important;
				background-color: #CCCCCC !important;
			}
		}

		>li {
			>a {

				// box-shadow: 0 -2px 0 map.get($whitetheme-colors, "bordercolor") !important;
				&:hover {
					color: $common-color !important;
				}
			}
		}
	}

	.commonBorderColor {
		border: 1px solid map.get($whitetheme-colors, "bordercolor") !important;
	}

	.formBorderColor {
		border: 1px solid $whiteborder-color !important;
	}

	.borderChangeColor {
		border-color: $whiteborder-color !important;
	}

	.dataTable tbody .shown+tr>td>table>tbody>tr:last-child {
		border-bottom: 1px solid $whiteborder-color;
	}

	.additional-details {
		border: 1px solid $whiteborder-color;
	}

	.devOptions {
		background-color: #f5f5f5;
		color: #171819;

		textarea {
			background-color: #f5f5f5;
		}
	}

	.itemEditButton {
		background-color: $primary-color;
		color: #202528;
		border-color: $common_base_color !important;

		&:active {
			background: $common_base_color !important;
			border-color: $common_base_color !important;
		}
	}

	.graphDataLabel {
		color: #202528;
	}

	.tooltipA .tooltiptext {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		color: map.get($whitetheme-colors, "color");
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
	}

	.notPaidHead,
	.notPaidHead span {
		color: map.get($whitetheme-colors, "color") !important;
	}

	.successDiv-status {
		background-color: #F7F7F7 !important;
	}

	.bs-datepicker-body table td {
		color: #000;
		text-align: center;
		position: relative;
		padding: 0;
	}

	.owl-dt-calendar-control {
		background-color: #fff;
		border: 1px solid #ccc !important;
		margin-top: 11px;
	}

	.tranheader {
		border: 1px solid $whiteborder-color !important;
		background: $primary-color !important;
		border-radius: 8px;

	}

	.owl-dt-calendar-control {
		background-color: #fff;
		border: 1px solid #ccc !important;
		margin-top: 11px;
	}

	.boxDiv {
		border: 1px solid $whiteborder-color !important;

		.boxClass {
			.box {
				border: 1px solid $whiteborder-color !important;
				color: #000 !important;
			}
		}
	}

	.filePattern {
		color: #000 !important;
	}

	.fuelux .wizard ul li .chevron:before {
		position: absolute;
		right: 1px;
		display: block;
		border: 24px solid transparent;
		border-right: 0;
		border-left: 14px solid #ededed;
		content: "";
		/* background: #fff; */

	}

	.fuelux .wizard ul li {
		position: relative;
		float: left;
		height: 46px;
		padding: 0 20px 0 30px;
		margin: 0;
		font-size: 16px;
		line-height: 46px;
		color: #000;
		;
		cursor: default;
		background: #ededed;
	}

	.fuelux .wizard ul li.active {
		color: #fff;
		background: $common_base_color;
	}

	.fuelux .wizard ul li.active .chevron:before {
		border-left: 14px solid $common_base_color;
	}

	.btnAction,
	.btnFile,	
	.iom_btn {
		font-size: 13px;
		// width: 178px;
		
		background: transparent 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $primary-color !important;
		}
	}
	.btnSendinvit,.btn-next{
		font: normal normal normal 12px/19.5px Open Sans;
		// width: 178px;
		background: #fff 0% 0% no-repeat padding-box !important;
		border-color: $common_base_color !important;
		letter-spacing: 0px;
		border: 2px solid $common_base_color !important;
		border-radius: 15px;
		color: $widget-bgcolor !important;
		padding: 3px 15px;

		&:hover {
			background: $common_base_color !important;
			border: 2px solid $common_base_color;
			color: $widget-bgcolor !important;
		}
	}
	.dropzonbox {
		background-color: #F7F7F7 !important;
		border: 1px solid #dadce0;
	}

	.company-select {
		border: none;
		border-radius: 10px;
		background-color: #EEEEEE !important;
	}

	.businessFields {
		label {
			color: #000 !important;
			font-size: 14px;
		}
	}

	.company-select {
		color: #0C1214 !important;
	}

	.xslt-path {
		border: 1px solid #666;
		background: transparent;
		color: #0C1214;
	}

	.dopzoneTxt {
		color: #000;
	}

	.detailLbls {
		color: #000 !important;
	}

	.archiveImport {
		background: #ffffff;
	}

	.transaction.nav-tabs {
		>li.active {
			a {
				cursor: default;
				background-color: transparent !important;
				border-top: 2px solid $common_base_color !important;
				border-left: 2px solid $common_base_color !important;
				border-right: 2px solid $common_base_color !important;
				border-bottom-color: transparent;

				&:hover {
					cursor: default;
					background-color: transparent !important;
					border: 1px solid #111;
					border-bottom-color: transparent;
				}

				&:focus {
					cursor: default;
					background-color: transparent !important;
					border: 1px solid #111;
					border-bottom-color: transparent;
				}
			}
		}
	}

	// .inviteLoader{
	// 	color: #000 !important;
	// }
	.bulkhoverTable {
		tbody {
			tr {
				&:hover {
					// color: $primary-color !important;
					background-color: $common_base_color!important;
					cursor: pointer;
					color: #000 !important;
				}
			}
		}
	}

	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #202124 !important;
	}

	.fileuploadform-form.formly {
		.form-control {
			color: $secondaryFocus !important;
			background-color: #EEEEEE !important;
			border-color: #EEEEEE !important;
			// border-radius: 10px !important;
			border-top-left-radius: 10px !important;
			border-bottom-left-radius: 10px !important;
			// border-color: map.get($whitetheme-colors, "commonbgcolor") !important;
			// border-color:none;
			-webkit-transition: border 0s ease-in-out 0s;
			-moz-transition: border 0s ease-in-out 0s;
			-o-transition: border 0s ease-in-out 0s;
			-ms-transition: border 0s ease-in-out 0s;
			transition: border 0s ease-in-out 0s;

		}
	}

	.fileuploadform-form {
		.input-group-append {
			.input-group-text {
				background-color: #fff;
				color: #495057;
				border: 1px solid #ced4da;
				border-top-right-radius: 10px;
				border-bottom-right-radius: 10px;


			}
		}
	}
	.iom-button {
		color: #000;
	}
	.tempCompanyselect{
		color: #0C1214 !important;
		background-color: #EEEEEE !important;
	}
	.interConnPopupForm .form-control {
		background-color: #fff !important;
		border-radius: 5px !important;
	}
	.intertabData {
		color: #0C1214 !important;
		background-color: none;
	}
	.interConnPopupForm .form-control.is-invalid {
		border: 1px solid #ced4da !important;
	}
	
	.badge-iom {
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		vertical-align: baseline;
		font-size: 13px !important;
		padding: 4px 13px !important;
		background-color: $common_base_color !important;
		color:$primary-color;
		border-radius: 10rem;
	}
}
.smart-style-2.minified{
	#left-panel{
		nav{
			ul.navList {
				li{
					&:hover{
						a{
							color: #202427 !important;
						}
						
					}
				}
			}
		}
	}

	.detail_save_btn,
	.detail_save_Btn,
	.approveBtn,
	.gotoEdi,
	.iom_btn,
	.detailInfo_save_btn {
		&:hover {
			color: $primary-color !important;
		}
	}

	.ng-select .ng-select-container .ng-value-container .ng-placeholder {
		color: #202124 !important;
	}
	#transaction-header .form-control::placeholder{
		color: #202124 !important;
	}
	.companyTooltip {
		background: map.get($whitetheme-colors, "commonbgcolor") 0% 0% no-repeat padding-box;
		border: 1px solid map.get($whitetheme-colors, "bgcolor");
		color: map.get($whitetheme-colors, "color");

	}
}

/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/
.positionTable .bulkTable {
	thead th {
		position: sticky;
		top: -5px;
	}

	::-webkit-scrollbar {
		width: 12px;
		background: red;
	}

	::-webkit-scrollbar-thumb {
		background: red;
		-webkit-border-radius: 1ex;
		-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
	}

	::-webkit-scrollbar-corner {
		background: #000;
	}
}

.smallLang {
	display: block !important;
	position: absolute;
	top: 25px;

	.dropdown-toggle::after {
		color: $fontcommon-color !important;
	}

	.header-dropdown-list {
		.dropdown-toggle {
			.language-text {
				color: #212529 !important;
			}
		}

		.dropdown-menu {
			li {
				a {
					&:hover {
						background-color: map.get($whitetheme-colors, "bgcolor") !important;
					}
				}
			}

			.active {
				a {
					background-color: map.get($whitetheme-colors, "bgcolor") !important;
				}
			}

			background-color: $primary-color !important;
			border: 1px solid rgba(0, 0, 0, .15) !important;

			.dropdown-item {
				color: #212529 !important;
			}
		}
	}

}

.commisionButton {
	margin-top: 23px !important;
}

.cancelButton {
	margin-left: 10px;
}

.copyIcon {
	padding-left: 10px;
}

.select-info {
	padding-left: 5px;
}

#header.colorChangeTest {
	background-color: #474d226e !important;
}

.company_nameField {
	.ng-value-container {
		input {
			margin-top: 5px;
		}
	}

	.ng-select-container {
		min-height: 40px !important;
	}
}

.cookie-text {
	font-size: 12px;

}

.show-consent {
	display: flex;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
}

.cookie-consent {
	width: 100%;
	flex-wrap: wrap;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	color: #d6d6d6;
	background-color: #3c404d;
	padding: 0.6em 1.8em;
	z-index: 9999;
	// padding: 10px 0 0 10px ;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.consent-btn {
	color: $fontcommon-color;
	flex: 1 0 130px;
	background-color: $common_base_color;
	border-color: transparent;
	padding: 0.2em 0.5em;
	font-size: 1.2em;
	font-family: Helvetica, Calibri, Arial, sans-serif;
	font-weight: bolder;
	border-width: 2px;
	border-style: solid;
	text-align: center;
	white-space: nowrap;
	border-radius: 5px;
	min-height: 20px;
	margin: 0 30px 10px 10px;
	max-width: 100px;
}

.priUrl {
	color: $common_base_color !important;
	text-decoration: none;
	font-weight: 500;
}

.notAllowed {
	cursor: not-allowed;

	.ng-select-container {
		pointer-events: none;
	}
}

.userProfile {
	.applications .jarviswidget {
		margin: 0;
	}
}

@media (max-width: 1200px) {
	.getClass {
		max-width: 33.33% !important;
	}
}

@media (max-width: 576px) {
	.getClass {
		max-width: 100% !important;
		margin-bottom: 5px;
	}
}

table thead tr th {
	font-size: 14px;
	font-weight: 400;
}

.dataTables_filter{
	display: none !important;
}
.dataTables_length {
		display: none;
	}

// .lstTrncnTbls.scroll-dashboard{
// 	.dataTables_length {
// 		display: block  !important;
// 	}
// }
.scroll-bulkdashboard{
	.dataTables_length  {
		display: block !important;
	}
	select.form-control.input-md {
		font-size: 14px;
		border-radius: 7px !important;
		margin-bottom: 15% !important;
	}
}

.notPaidHead,
.notPaidList {
	.idTooltip {
		height: auto;
		width: auto;
	}
}

.notPaidPart {
	.algntblTranscation {
		overflow: visible;
	}
}

.notPaidHead,
.notPaidHead span {
	font-size: 12px;
}

.lstTrncnTbl tr.selected td a {
	color: #fff !important;
}

.deleteBtnDiv {
	position: relative;

	.btnTooltip {
		position: absolute;
		left: 90px;
	}
}

.disabledClass {
	pointer-events: none !important;
}

.disabledCursor {
	cursor: not-allowed !important;
}

.theme-green .bs-datepicker-head {
	background-color: $common_base_color !important;
}

.bs-datepicker-head button {
	display: inline-block;
	vertical-align: top;
	padding: 0;
	height: 30px;
	line-height: 30px;
	border: 0;
	background: transparent;
	text-align: center;
	cursor: pointer;
	color: #000;
	transition: 0.3s;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
	color: #000;
}

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
	background-color: $common_base_color;
	color: #000;
	transition: 0s;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
	background-color: $common_base_color!important;
	color: #000 !important;
	border: #666666 !important;
}

.owl-dt-inline-container,
.owl-dt-popup-container {
	position: relative;
	width: 0.5em;
	height: 0px;
	/* display: none; */
	/* box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12); */
}

.infoFieldIcon {
	height: 14px;
	width: 14px;
	cursor: pointer;
}

.infoServiceFieldIcon {
	height: 14px;
	width: 14px;
	cursor: pointer;
}

.serviceForm {
	formly-field {
		.invalid-feedback {
			formly-validation-message {
				font-size: 13px !important;
			}
		}
	}
}

.form-group.has-error {
	formly-wrapper-addons {
		.input-group {
			.input-group-append {
				border: 1px solid $invalid-bordercolor !important;
				border-left: none !important;
				border-top-right-radius: 10px !important;
				border-bottom-right-radius: 10px !important;
			}

			.input-addons {
				border: 1px solid $invalid-bordercolor !important;
				border-right: none !important;
				border-top-left-radius: 10px !important;
				border-bottom-left-radius: 10px !important;

			}

			.form-control.is-invalid {
				border: none !important;
			}
		}
	}
}

.inputSection,
.selectSection {
	small {
		font-size: 13px !important;
	}
}

.boxDiv {
	// margin: -10px -10px 10px !important;
	border-radius: 10px;

	.boxClass {
		flex: 1 0 20%;

		.box {
			border-radius: 10px;
			margin: 10px;
		}
	}
}

.font-size-20 {
	font-size: 20px;
}

.font-size-14 {
	font-size: 14px;
}

//-------------------responsive view start --------------------------------
@media (max-height: 770px) {
	ul.navList {
		// overflow: auto;
		height: 450px;
		-ms-overflow-style: none;
		/* IE and Edge */
		scrollbar-width: none;
		/* Firefox */
		&::-webkit-scrollbar-track {
			background-color: $common-color;
		}
		&::-webkit-scrollbar {
			width: 0px;
		}
	}
}

@media (max-width: 657px) {
	.actions {
		display: contents !important;
	}

	.steps-container {
		display: flex !important;
	}
}

//-------------------responsive view End-----------------------------------
.dashboardTableList {
	thead tr th {
		vertical-align: top !important;
	}
}
.bulkinviteTableList{
	thead tr th {
		vertical-align: middle !important;
	}
}
// .importButton{
// 	color:#202124 !important;
// 	background-color:$common_base_color!important;
//   }
.archiveButton {
	color: #fff !important;
	background-color: #202124 !important;
}

.templateBtn {
	border-radius: 15px !important;
	background: transparent !important;
	border: 2px solid $common_base_color!important;
	font-size: 13px !important;
	padding: 3px 15px !important;
	line-height: 19.5px !important;
	font-weight: 400 !important;
}

.templateBtn:hover {
	border-color: $common_base_color!important;
	background: $common_base_color!important;
	color: #000 !important;
}

.smart-style-1 .templateBtn {
	color: #ffffff;
}

.smart-style-2 .templateBtn {
	color: #000;
}

//--------------------------------------mtc responsive view -----------------
@media (max-width: 750px) {
	.menu-item.language-align {
		width: 50px !important;
		padding-left: 60px !important;
	}
}

.navFooter {
	// bottom: 80px !important;
	position: absolute;
}

@media (max-width: 372px) {
	.navFooter {
		bottom: 170px !important;
		position: absolute;
	}
}

@media (min-width: 373px) and (max-width: 690px) {
	.navFooter {
		bottom: 120px !important;
		position: absolute;
	}
}

@media (max-width: 850px) {
	td.actionAlign {
		display: flex;
		flex-direction: column;
	}

	button.btn.gotoEdi.mb-1.ml-3 {
		margin-left: 0rem !important;
		margin-top: 5px !important;
	}
}

span.company_test-align {
	margin-top: 10px !important;
}

.smart-style-2 .lstTrncnTbl td a {
	color: #474D22;
}

@media(max-width:478px) {
	button.btn.detail_save_Btn {
		margin-top: 10px !important;
	}
}

ng-dropdown-panel {
	::-webkit-scrollbar {
		width: 1px !important;
	}

	::-webkit-scrollbar-track {
		background: #7e7e7e;
		/* color of the tracking area */
	}

	::-webkit-scrollbar-thumb {
		background: #212529;
		/* color of the scroll thumb */
	}
}

.status-align {
	text-align: center !important;
	width: 80px;
}

.subscription-style {
	border: 1px solid #333 !important;
	padding-bottom: 20px !important;
	border-radius: 8px !important;
	/* height: 200px; */
}

.smart-style-2 .subscription-style {
	border: 1px solid #c5c5c5 !important;
	padding-bottom: 20px !important;
	border-radius: 8px !important;
	/* height: 200px; */
}

.starter-align {
	margin-top: 20px !important;
}

.userDash_tileCount {
	font-size: 25px;
	font-weight: 500;
	margin-top: 12px !important;
}

.btn-align {
	margin-top: 0%;
	margin-bottom: 0%;
}

@media(max-width:750px) {
	.commision-agent-align {
		flex: 100% !important;
	}
}

@media(max-width:380px) {
	.commonFieldDiv.company_mode-align {
		display: block !important;
	}
}

label.ediLbl.commonLbl.companyModeStyle {
	margin-left: 0px !important;
}

@media(max-width:1505px) {
	.ebilloverview-filterBtn {
		margin-bottom: 1rem !important;
	}
}

@media(max-width:600px) {
	.divCl-align {
		width: 100% !important;
	}
}

// @media (max-width: 576px){
// #content {
//     max-height: calc(100vh - 220px) !important;
// }
// }
@media (max-width: 597px) {
	.sectionDiv-align {
		display: block !important;
		width: 100% !important;
	}

	.Section-align {
		width: 100% !important;
	}

	.divCl-text-align {
		width: 100% !important;
	}
}

@media (max-width: 1898px)and (min-width: 598px) {
	.divCl-text-align {
		margin-top: -16px;
	}
}

@media (max-width: 597px) {
	.divCl-text-align {
		margin-top: -8px;
	}
}

@media (min-width: 1200px) {
	.transTable-align {
		height: 370px;
	}
}

@media (max-width: 1200px) {
	.tableDatas-align {
		padding-right: 0px !important;
	}

	.headerContent-align {
		margin-top: 20px !important;
	}
}

.smart-style-1 .card-header-align {
	background-color: #0C1214 !important;
}

@media (max-width:1400px) {
	.wizardCircleWidth-align {
		flex: 1 0 50% !important;
	}
}

@media (max-width:576px) {
	.communication_btn {
		margin-left: 15%;
		width: auto;
	}
}

.smart-style-1 .search_status-bg-clr {
	background-color: #171717 !important;
}

.algntblTranscation-style {
	margin-top: 1.5rem !important;
	//   margin-left: 1.5rem!important;
	//   margin-right: 1.5rem!important;
}

.tranheader-style {
	padding: 7px !important;
}

@media (max-width:700px) {
	.btnSection-align {
		width: 100% !important;
	}
}

// span.ng-clear {
// 	display: none !important;
// }

.item1 {
	.multiselect-item-checkbox {
		border-bottom: 1px solid #333 !important;
		padding: 10px !important;
	}
}

.tranheader-align {
	padding-bottom: 10px !important;
}

.page-title-align {
	margin-bottom: 7px !important;
}

.row-table-align {
	overflow-y: auto !important;
}

.invoice_status-align {
	padding-left: 5px !important;
}

// -------------------------------------mtc responsive view end--------------  

.history-table-div{
	overflow-x: auto;
	&::-webkit-scrollbar {
		width: 0px !important;
		height: 0px !important;
	}

	&::-webkit-scrollbar-track {
		// border: 1px solid #202124;
		border-radius: 5px;
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #CCC;
		// border: 1px solid #202124;
		border-radius: 5px;
	}
}
// span.ng-clear-wrapper.ng-star-inserted {
//     display: none;
// }
// .ng-select .ng-clear-wrapper {
//     display: none !important;
// }
.setCirclehold {
    color: orange;
    font-size: 10px !important;
}
.smart-style-1 a.user_mngmnt_resnd_pswd {
    color: $common_base_color;
}
.user_mngmnt_pswrd_rsnd {
    display: flex;
    justify-content: end;
}
.user_toggle_style_active{
    color: $common_base_color;
}
.user_toggle_style_inactive{
    color:#CCC;
}
.archive-data-table tbody tr.selected {
	.user_toggle_style_active {
		color: #000000;
	}
	.user_toggle_style_inactive {
		color: #000;
	}
	.editRole {
        border: 2px solid #000000 !important;
		color:#000 !important;
	}
//  .smart-style-1 .iom_btn.selected {
// 		background: $common_base_color!important;
// 		color: #000 !important;
// 	}
}

.interConnPopupForm {
	small {
		font-size: 12px !important;
		color: red !important;
	}
}
.ellipsis_col {
    white-space: nowrap; 
	width: calc(100% - 15px); 
	overflow: hidden;
	text-overflow: ellipsis; 
	display: block;
}
.transactionsTable tbody tr td {
    padding: 8px 6px !important;
}
.select-info {
	display: none;
}
.g_id_signin {
	display: none !important;
}

select:disabled{
	opacity: 0.5;
}
td.company_adress {
    width: 15% !important;
}
.disabled-row {
	pointer-events: none !important; 
	// opacity: 0.5 !important; 
	color: rgb(72 67 67) !important;
}
// table.packageDeatilsTable {
//     height: 328px !important;
// }

.main-title {
	font-size: 16px !important;
	font-family: Open Sans, Arial, Helvetica, sans-serif;
}
.needInput {
	.custom-radio.custom-control {
		display: inline-block;
		margin-right: 10px;
	}
}
nav ul li a {
    padding: 12px 0px 12px 12px !important;
}
nav ul ul li>a {
	padding-left: 40px !important;
}
.transactions-data-table th, .transactions-data-table td {
    white-space: nowrap;
}
.transactions-data-table th {
    display: table-cell !important;
}

/* Ensure all table data (td) cells are visible */
.transactions-data-table td {
    display: table-cell !important;
}
.sender_ellipsis_col {
    white-space: nowrap;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
/* Sender column */
.transactions-data-table th:nth-child(4), 
.transactions-data-table td:nth-child(4) {
    width: 25% !important;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Receiver column */
.transactions-data-table th:nth-child(5), 
.transactions-data-table td:nth-child(5) {
    width: 25% !important;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.transactions-data-table {
    table-layout: fixed;
    width: 100%;
}
.document_ellipsis_col {
    white-space: nowrap;
    width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.smart-style-2
{
.emailLbl {
    color: #212529 !important;
}
}
.smart-style-1
{
.emailLbl {
    color:  #fff !important;
}
}

.matchingCompany {
    word-break: break-all;
    // width: 400px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.create-time{
    word-wrap: break-word;
    width: 100%;
    white-space: normal;
}