/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
@import "commonstyle";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700");
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
body:not(.menu-on-top).desktop-detected {
    min-height: auto !important;
	overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
    min-height: auto !important;
	overflow: hidden;
}
body {
    background: unset;
    background-color: #202124;
}
#content{
	padding: 20px !important;
	max-height: calc(100vh - 100px);
    overflow-y: auto;
	&::-webkit-scrollbar {
		display: none;
	}
}
.page-footer {
    /* padding-left: 0px; */
    z-index: 0;
	border-top: none;
}
#logo {    
    background-repeat: no-repeat;
    background-size: 100%;
    height: 49px !important;
    width: 135px;
    display: block;
    margin-top: 10px;
    margin-left: 36px;
}
.setnavBackgroundImg{	
    position: relative;
    z-index: 2;
    max-width: none;
    width: 90px;
    height: 90px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
	width: 110px;
	height: 110px;
}
.setBackgroundCompanyImg{
	position: relative;
    z-index: 2;
    max-width: none;
    width: 200px;
    height: 200px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;
	margin: 0 auto;
}
.setBackgroundCompanylogoImg{
	position: relative;
    z-index: 2;
    max-width: none;
    width: 200px;
    height: 200px;
    cursor: pointer;
	background-repeat: no-repeat;
	display: flex;	
}
.col-xs-10 {
    width: 83.33333333% !important;
}

.col-xs-2 {
    width: 16.66666667% !important;
}

.col-xs-3 {
    width: 25% !important;
}

.col-xs-7 {
    width: 58.33333333% !important;
}

.col-xs-8 {
    width: 66.66666667% !important;
}

.col-xs-6 {
    width: 50% !important;
}

.col-xs-1 {
    width: 8.33333333% !important;
}

.col-xs-4 {
    width: 33.33333333% !important;
}

.col-xs-5 {
    width: 41.66666667% !important;
}

#extr-page {
	overflow-x: hidden;
	#header {
		#logo {
			img {
				width: 200px;
			}
			margin-left: 75px !important;
			margin-top: 10px !important;
		}
	}
}

.notification-body {
	// .unread {
	// 	background: $table-bgcolor !important;
	// }
	li {
		span {
			background: $secondaryFocus !important;
		}
	}
}
.li-footer__copy-text {
	color: #8B91A0;
}
.loader-outer{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
  }
  .loader>span {
    width: 15px;
    height: 15px;
    display: block;
    background: $common_base_color;
    border-radius: 50%;
    position: relative;
    margin: 0 5px;
  }
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dot-1 {
    animation: anim 1s linear 0s infinite;
  }
  
  .dot-2 {
    animation: anim 1s linear 0.25s infinite;
  }
  
  .dot-3 {
    animation: anim 1s linear 0.50s infinite;
  }
  
  .dot-4 {
    animation: anim 1s linear 0.75s infinite;
  }
  
  @keyframes anim {
    0% {
      top: 0;
    }
  
    50% {
      top: 15px;
    }
  
    100% {
      top: 0;
    }
  }
@media (max-width: 1161px) and (min-width: 300px) {
    #extr-page #header #logo img {
        width: 150px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}

@media only screen and (max-width: 280px) {
    #header>:first-child,
    aside {
        width: 150px;
    }
    #extr-page #header #logo img {
        width: 125px;
    }
    #extr-page #header #logo {
        margin-left: 10px !important;
        margin-top: 20px !important;
    }
}
.includePdf label {
margin-bottom: 5px !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
background-image: none !important;
}
// .user-filter-form{
	.ng-dropdown-panel.ng-select-bottom {
        .ng-dropdown-panel-items {
            .ng-option {
                &:last-child {
                    border-bottom-right-radius: 0px !important;
                    border-bottom-left-radius: 0px !important;
                }
            }
        }
    }
// }
.ng-dropdown-panel{
	border: 1px solid #666 !important;
}
.checkbox i{
	position: absolute;
	top: 3px;
	left: 0;
	display: block;
	width: 17px;
	height: 17px;
	outline: 0;
	border-width: 1px;
	border-style: solid;
	background: transparent !important;
	border: 2px solid #666666;
	border-radius: 5px;
}


.smart-form .checkbox input+i:after {
	color: $common_base_color;
}
.smart-form .checkbox i {
	border-color: #BDBDBD;
	border: 2px solid #666666;
	border-radius: 5px;
}
.smart-form .checkbox:hover i{
	border-color: #666666;
}
.fuelux .wizard ul li.complete:hover {
	cursor: pointer;
	background: none;
}
.custom-control-input:checked~.custom-control-label::before {
	color: #fff;
	border-color: $common_base_color;
	background-color: $common_base_color;
}
.fuelux .wizard ul li .chevron {
	position: absolute;
	top: 0;
	right: -14px;
	z-index: 1;
	display: block;
	border: 24px solid transparent;
	border-right: 0;
}
.ng-select .ng-select-container {
	background: transparent;
	font-size: 12px;
	border-radius: 10px !important;
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
	border-color:none;
	box-shadow:none;
            }
            
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 {
	#logo-group {
		background: unset !important;
	}
	#logo {
		background-image: url(/assets/img/svgImages/logo.png);
	}
	.btn-header {

			&:first-child {
				a {
					background: unset;
					border: 1px solid $border-color;
				}
		}
	}
	#header {
		background-color: #202124;
	}
	aside {
		background-color: #171819;
		border-right: 1px solid #666666 !important;
	}
	.page-footer {
		background-color: $common-color;
	}
	#ribbon {
		background: $tabsborder-color;
	}
	.ajax-dropdown {
		background-color: $secondaryFocus !important;
	}
	.ajax-notifications {
		background-color: $secondaryFocus !important;
	}
	.header-dropdown-list {
		a {
			&:hover {
				color: $primary-color !important;
			}
		}
	}
	.setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group348.svg);	
	}
	.setcompanyImg{
		background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg)
	}
	.setBackgroundCompanyImg,
	.setBackgroundCompanylogoImg{
		background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg)
	}
	.owl-dt-control-button-arrow{
		fill: $primary-color !important;
	}
	
              .ng-select .ng-select-container {
		color: #fff;
	}

	.ng-select.ng-select-opened>.ng-select-container {
		background: transparent !important;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		background-color: #202124 !important;
		color: #ffff;
		font-size: 12px;
	}

	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
		color: #ffff !important;
	}

	.ng-dropdown-panel.ng-select-bottom {
		border: unset !important;
	}
	.owl-dt-calendar-main {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding: 0 0.5em 0.5em;
		outline: 0;
		background: #202124;
		border: 1px solid #333333 !important;
	          }
	.owl-dt-calendar-control .owl-dt-calendar-control-content {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #202124;
	// border: 1px solid #333333 !important;
	
	}
	.owl-dt-control-arrow-button svg {
		width: 50%;
		height: 50%;
		// fill: currentColor;
		color: #fff !important;
	          }
	        
	          .smart-form .checkbox input:checked+i {
		border: 2px solid #666666;
		border-radius: 5px;
	}
	.fuelux .wizard ul li .chevron {
		border-left: 14px solid #3B424C;
	}
	.fuelux .wizard ul li.complete:hover .chevron:before {
		border-left: 14px solid #171717; 
	}
}

/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/

/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 {
	#logo {
		background-image: url(/assets/img/svgImages/logoWhite.png);
		//margin-top: 10px;
	}
	.login-info {
		color: #212529;
	}
	aside{
		color: #fff !important;
		// background: #171819 !important;
		background: #ededed !important;
	 }
	 #header {
		background-color: $primary-color !important;
	}
	nav ul .active>a{
		color: #202427 !important;
                            font-weight: 400 !important;
		font-size: 16px !important;
	}
	.setnavBackgroundImg{
		background-image: url(/assets/img/svgImages/Group349.svg);	
	}
	.setBackgroundCompanyImg,
	.setBackgroundCompanylogoImg{
		background-image: url(/assets/img/svgImages/Group350.svg);	
	}
	.ng-select .ng-select-container {
		color: #000;
	}
	.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
		font-size: 12px;
	}

	.owl-dt-calendar-main {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		padding: 0 0.5em 0.5em;
		outline: 0;
		background: #fff;
		border: 1px solid #ccc  !important;
		
	          }
	.owl-dt-calendar-control .owl-dt-calendar-control-content {
	flex: 1 1 auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	// border: 1px solid #333333 !important;
	
	}
	.owl-dt-control-arrow-button svg {
		width: 50%;
		height: 50%;
		// fill: currentColor;
		color: #000 !important;
	          }
	          .smart-form .checkbox input:checked+i {
		border-color: #666666 !important;
	          }
	.fuelux .wizard ul li .chevron {
                   border-left: 14px solid #d4d4d4;
	}
	.ng-select.ng-select-opened>.ng-select-container {
		background: none;
		// border-color: #b3b3b3 #ccc #d9d9d9;
	          }
}

/*
---------------------------------------------------------------------------------------
    White  theme style
---------------------------------------------------------------------------------------
*/
.ng-select .ng-select-container {
	color: #333;
	background-color: transparent;
	border-radius: 4px;
              border: none; 
	min-height: 36px;
	align-items: center;
          }
// @media (min-width: 576px){
// 	#content{
// 		max-height: calc(90vh - 100px) !important;
// 	}	
// }

// @media (min-width: 768px){
// 	#content{
// 		max-height: calc(120vh - 100px) !important;
// 	}
// }

// @media (max-width: 992px){}

// @media (max-width: 1250px){}

// @media (min-width: 1250px) and (max-width: 1500px){

// }
.smart-style-2.minified #left-panel {
	padding: 0!important;
	padding-top: 25px !important;
          }
@media (max-width: 992px) {
	#content{
		max-height: calc(100vh - 130px) !important;
	}
}
@media (max-width: 576px){
	#content{
		max-height: calc(100vh - 180px) !important;
	}
}

// owl-datetime picker

.cdk-overlay-container {
	z-index: 9999 !important;
}

.owl-dt-inline {
	width: 100% !important;
}
.owl-dt-popup-container .owl-dt-calendar {
	height: 20.25em;
	width: 29.35em;
	margin-left: -8px;
          }
// .owl-dt-inline-container,
// .owl-dt-popup-container {
// 	width: 100% !important;
// }
// .owl-dt-container{
// 	font-size: 18px !important;
// }
// .owl-dt-inline-container .owl-dt-calendar,
// .owl-dt-popup-container .owl-dt-calendar{
// 	height: 500px !important;
// }

// .cdk-overlay-container {
// 	z-index: 9999 !important;
// }

// .owl-dt-inline {
// 	width: 100% !important;
// }

// .owl-dt-inline-container,
// .owl-dt-popup-container {
// 	width: 100% !important;
// }

// .owl-dt-container {
// 	font-size: 18px !important;
// }

// .owl-dt-inline-container .owl-dt-calendar,
// .owl-dt-popup-container .owl-dt-calendar {
// 	height: 500px !important;
// }

// .owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
// .owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
// 	background-color: #666666 !important;
// 	color: #fff !important;
// }

// .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){
// 	background-color: #202124 !important;
// 	border: 1px solid #666666 !important;
// 	color: #fff !important;
// }
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
	position: absolute;
	top: 5%;
	left: 5%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 90%;
	height: 90%;
	/* font-size: 0.8em; */
	line-height: 1;
	border: 1px solid transparent;
	border-radius: 999px;
	color: inherit;
	cursor: pointer;
	font-size: 13px;
          }
.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: none !important;
}
.custom-radio .custom-control-label::before {
    border-radius: 50%;
    margin-top: 3px;
}
.minified nav ul ul li>a:hover {
    background: $common_base_color!important;
}

// @media (max-width: 992px) {
// 	#content{
// 		max-height: calc(100vh - 130px) !important;
// 	}
// }
// @media (max-width: 576px){
// 	#content{
// 		max-height: calc(100vh - 180px) !important;
// 	}	
// }

.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
	background-color: $common_base_color!important;
	color: #000;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
	border-color: $common_base_color!important;
}
.owl-dt-control-period-button .owl-dt-control-button-content {
	height: 1.5em;
	padding: 0 0.5em;
	border-radius: 3px;
	transition: background-color 100ms linear;
	font-size: 13px;
}
.actionAlign{
    display: flex !important;
    text-align: center !important;
}
.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
	 background-color: $common_base_color!important;
}

#extr-page {
    background-color: #fcfceb !important;	
}
span#logo {
    margin-top: 15px;
}
.ellipse {
	background-image: url('/assets/img/ellipse.png'); 
    position: absolute;
    left: 0px;
	top: -1px;
    width: 330px;
    height: 270px;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
}
@media (max-width: 992px) {
.ellipse {
    height: 171px;
}
}
@media (max-width: 500px) {
	.ellipse {
		display:none !important; 
	}
	}