@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic,700italic,300,400,700);

/* You can add global styles to this file, and also import other style files */
/* #GOOGLE FONT */
.cdk-overlay-container,.cdk-global-overlay-wrapper{pointer-events:none;top:0;left:0;height:100%;width:100%}
.cdk-overlay-container{position:fixed;z-index:1000}
.cdk-overlay-container:empty{display:none}
.cdk-global-overlay-wrapper{display:flex;position:absolute;z-index:1000}
.cdk-overlay-pane{position:absolute;pointer-events:auto;box-sizing:border-box;z-index:1000;display:flex;max-width:100%;max-height:100%}
.cdk-overlay-backdrop{position:absolute;top:0;bottom:0;left:0;right:0;z-index:1000;pointer-events:auto;-webkit-tap-highlight-color:transparent;transition:opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);opacity:0}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:1}
@media screen and (-ms-high-contrast: active){.cdk-overlay-backdrop.cdk-overlay-backdrop-showing{opacity:0.6}}
.cdk-overlay-dark-backdrop{background:rgba(0,0,0,0.288)}
.cdk-overlay-transparent-backdrop,.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing{opacity:0}
.cdk-overlay-connected-position-bounding-box{position:absolute;z-index:1000;display:flex;flex-direction:column;min-width:1px;min-height:1px}
.cdk-global-scrollblock{position:fixed;width:100%;overflow-y:scroll}
.owl-dialog-container{position:relative;pointer-events:auto;box-sizing:border-box;display:block;padding:1.5em;box-shadow:0 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12);border-radius:2px;overflow:auto;background:#fff;color:rgba(0,0,0,0.87);width:100%;height:100%;outline:none}
.owl-dt-container,.owl-dt-container *{box-sizing:border-box}
.owl-dt-container{display:block;font-size:1rem;background:#ffffff;pointer-events:auto;z-index:1000}
.owl-dt-container-row{border-bottom:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-row:last-child{border-bottom:none}
.owl-dt-calendar{display:flex;flex-direction:column;width:100%}
.owl-dt-calendar-control{display:flex;align-items:center;font-size:1em;width:100%;padding:.5em;color:#000000}
.owl-dt-calendar-control .owl-dt-calendar-control-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button{padding:0 .8em}
.owl-dt-calendar-control .owl-dt-calendar-control-content .owl-dt-calendar-control-button:hover{background-color:rgba(0,0,0,0.12)}
.owl-dt-calendar-main{display:flex;flex-direction:column;flex:1 1 auto;padding:0 .5em .5em;outline:0}
.owl-dt-calendar-view{display:block;flex:1 1 auto}
.owl-dt-calendar-multi-year-view{display:flex;align-items:center}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table{width:calc(100% - 3em)}
.owl-dt-calendar-multi-year-view .owl-dt-calendar-table .owl-dt-calendar-header th{padding-bottom:.25em}
.owl-dt-calendar-table{width:100%;border-collapse:collapse;border-spacing:0}
.owl-dt-calendar-table .owl-dt-calendar-header{color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-weekdays th{font-size:.7em;font-weight:400;text-align:center;padding-bottom:1em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider{position:relative;height:1px;padding-bottom:.5em}
.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after{content:'';position:absolute;top:0;left:-.5em;right:-.5em;height:1px;background:rgba(0,0,0,0.12)}
.owl-dt-calendar-table .owl-dt-calendar-cell{position:relative;height:0;line-height:0;text-align:center;outline:0;color:rgba(0,0,0,0.85);-webkit-appearance:none;-webkit-tap-highlight-color:rgba(0,0,0,0);-webkit-tap-highlight-color:transparent}
.owl-dt-calendar-table .owl-dt-calendar-cell-content{position:absolute;top:5%;left:5%;display:flex;align-items:center;justify-content:center;box-sizing:border-box;width:90%;height:90%;font-size:.8em;line-height:1;border:1px solid transparent;border-radius:999px;color:inherit;cursor:pointer}
.owl-dt-calendar-table .owl-dt-calendar-cell-out{opacity:.2}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected{color:rgba(255,255,255,0.85);background-color:#3f51b5}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected.owl-dt-calendar-cell-today{box-shadow:inset 0 0 0 1px rgba(255,255,255,0.85)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled{cursor:default}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){color:rgba(0,0,0,0.4)}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected{opacity:.4}
.owl-dt-calendar-table .owl-dt-calendar-cell-disabled>.owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected){border-color:rgba(0,0,0,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected){background-color:rgba(0,0,0,0.04)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range{background:rgba(63,81,181,0.2)}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from{border-top-left-radius:999px;border-bottom-left-radius:999px}
.owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to{border-top-right-radius:999px;border-bottom-right-radius:999px}
.owl-dt-timer{display:flex;justify-content:center;width:100%;height:7em;padding:.5em;outline:none}
.owl-dt-timer-box{position:relative;display:inline-flex;flex-direction:column;align-items:center;width:25%;height:100%}
.owl-dt-timer-content{flex:1 1 auto;display:flex;justify-content:center;align-items:center;width:100%;margin:.2em 0}
.owl-dt-timer-content .owl-dt-timer-input{display:block;width:2em;text-align:center;border:1px solid rgba(0,0,0,0.5);border-radius:3px;outline:medium none;font-size:1.2em;padding:.2em}
.owl-dt-timer-divider{display:inline-block;align-self:flex-end;position:absolute;width:.6em;height:100%;left:-.3em}
.owl-dt-timer-divider:before,.owl-dt-timer-divider:after{content:'';display:inline-block;width:.35em;height:.35em;position:absolute;left:50%;border-radius:50%;transform:translateX(-50%);background-color:currentColor}
.owl-dt-timer-divider:before{top:35%}
.owl-dt-timer-divider:after{bottom:35%}
.owl-dt-control-button{-webkit-user-select:none;-moz-user-select:none;user-select:none;cursor:pointer;outline:none;border:none;-webkit-tap-highlight-color:transparent;display:inline-block;white-space:nowrap;text-decoration:none;vertical-align:baseline;margin:0;padding:0;background-color:transparent;font-size:1em;color:inherit}
.owl-dt-control-button .owl-dt-control-button-content{position:relative;display:inline-flex;justify-content:center;align-items:center;outline:none}
.owl-dt-control-period-button .owl-dt-control-button-content{height:1.5em;padding:0 .5em;border-radius:3px;transition:background-color 100ms linear}
.owl-dt-control-period-button:hover>.owl-dt-control-button-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control-period-button .owl-dt-control-button-arrow{display:flex;justify-content:center;align-items:center;width:1em;height:1em;margin:.1em;transition:transform 200ms ease}
.owl-dt-control-arrow-button .owl-dt-control-button-content{padding:0;border-radius:50%;width:1.5em;height:1.5em}
.owl-dt-control-arrow-button[disabled]{color:rgba(0,0,0,0.4);cursor:default}
.owl-dt-control-arrow-button svg{width:50%;height:50%;fill:currentColor}
.owl-dt-inline-container,.owl-dt-popup-container{position:relative;width:18.5em;box-shadow:0 5px 5px -3px rgba(0,0,0,0.2),0 8px 10px 1px rgba(0,0,0,0.14),0 3px 14px 2px rgba(0,0,0,0.12)}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-inline-container .owl-dt-timer,.owl-dt-popup-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-timer{width:100%}
.owl-dt-inline-container .owl-dt-calendar,.owl-dt-popup-container .owl-dt-calendar{height:20.25em}
.owl-dt-dialog-container{max-height:95vh;margin:-1.5em}
.owl-dt-dialog-container .owl-dt-calendar{min-width:250px;min-height:330px;max-width:750px;max-height:750px}
.owl-dt-dialog-container .owl-dt-timer{min-width:250px;max-width:750px}
@media all and (orientation: landscape){.owl-dt-dialog-container .owl-dt-calendar{width:58vh;height:62vh}.owl-dt-dialog-container .owl-dt-timer{width:58vh}}
@media all and (orientation: portrait){.owl-dt-dialog-container .owl-dt-calendar{width:80vw;height:80vw}.owl-dt-dialog-container .owl-dt-timer{width:80vw}}
.owl-dt-container-buttons{display:flex;width:100%;height:2em;color:#3f51b5}
.owl-dt-container-control-button{font-size:1em;width:50%;height:100%;border-radius:0}
.owl-dt-container-control-button .owl-dt-control-button-content{height:100%;width:100%;transition:background-color 100ms linear}
.owl-dt-container-control-button:hover .owl-dt-control-button-content{background-color:rgba(0,0,0,0.1)}
.owl-dt-container-info{padding:0 .5em;cursor:pointer;-webkit-tap-highlight-color:transparent}
.owl-dt-container-info .owl-dt-container-range{outline:none}
.owl-dt-container-info .owl-dt-container-range .owl-dt-container-range-content{display:flex;justify-content:space-between;padding:.5em 0;font-size:.8em}
.owl-dt-container-info .owl-dt-container-range:last-child{border-top:1px solid rgba(0,0,0,0.12)}
.owl-dt-container-info .owl-dt-container-info-active{color:#3f51b5}
.owl-dt-container-disabled,.owl-dt-trigger-disabled{opacity:0.35;filter:Alpha(Opacity=35);background-image:none;cursor:default !important}
.owl-dt-timer-hour12{display:flex;justify-content:center;align-items:center;color:#3f51b5}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box{border:1px solid currentColor;border-radius:2px;transition:background 200ms ease}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box .owl-dt-control-button-content{width:100%;height:100%;padding:.5em}
.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:focus .owl-dt-control-button-content,.owl-dt-timer-hour12 .owl-dt-timer-hour12-box:hover .owl-dt-control-button-content{background:#3f51b5;color:#ffffff}
.owl-dt-calendar-only-current-month .owl-dt-calendar-cell-out{visibility:hidden;cursor:default}
.owl-dt-inline{display:inline-block}
.owl-dt-control{outline:none;cursor:pointer}
.owl-dt-control .owl-dt-control-content{outline:none}
.owl-dt-control:focus>.owl-dt-control-content{background-color:rgba(0,0,0,0.12)}
.owl-dt-control:not(:-moz-focusring):focus>.owl-dt-control-content{box-shadow:none}
.owl-hidden-accessible{border:0;clip:rect(0 0 0 0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px}
.ng-select.ng-select-opened>.ng-select-container{background:#fff;border-color:#b3b3b3 #ccc #d9d9d9}
.ng-select.ng-select-opened>.ng-select-container:hover{box-shadow:none}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{top:-2px;border-color:transparent transparent #999;border-width:0 5px 5px}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{border-color:transparent transparent #333}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{border-bottom-right-radius:0;border-bottom-left-radius:0}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{border-top-right-radius:0;border-top-left-radius:0}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{border-color:#007eff;box-shadow:inset 0 1px 1px rgba(0,0,0,0.075),0 0 0 3px rgba(0,126,255,0.1)}
.ng-select.ng-select-disabled>.ng-select-container{background-color:#f9f9f9}
.ng-select .ng-has-value .ng-placeholder{display:none}
.ng-select .ng-select-container{color:#333;background-color:#fff;border-radius:4px;border:1px solid #ccc;min-height:36px;align-items:center}
.ng-select .ng-select-container:hover{box-shadow:0 1px 0 rgba(0,0,0,0.06)}
.ng-select .ng-select-container .ng-value-container{align-items:center;padding-left:10px}
[dir="rtl"] .ng-select .ng-select-container .ng-value-container{padding-right:10px;padding-left:0}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{color:#999}
.ng-select.ng-select-single .ng-select-container{height:36px}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{top:5px;left:0;padding-left:10px;padding-right:50px}
[dir="rtl"] .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{padding-right:10px;padding-left:50px}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{background-color:#f9f9f9;border:1px solid #e6e6e6}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{padding:0 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-top:5px;padding-left:7px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container{padding-right:7px;padding-left:0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{font-size:.9em;margin-bottom:5px;background-color:#ebf5ff;border-radius:2px;margin-right:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{margin-right:0;margin-left:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{background-color:#f9f9f9}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:5px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{padding-left:0;padding-right:5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{display:inline-block;padding:1px 5px}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{background-color:#d1e8ff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-right:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{border-left:1px solid #b8dbff;border-right:none}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:1px solid #b8dbff}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{border-left:0;border-right:1px solid #b8dbff}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 0 3px 3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{padding:0 3px 3px 0}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{top:5px;padding-bottom:5px;padding-left:3px}
[dir="rtl"] .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{padding-right:3px;padding-left:0}
.ng-select .ng-clear-wrapper{color:#999}
.ng-select .ng-clear-wrapper:hover .ng-clear{color:#D0021B}
.ng-select .ng-spinner-zone{padding:5px 5px 0 0}
[dir="rtl"] .ng-select .ng-spinner-zone{padding:5px 0 0 5px}
.ng-select .ng-arrow-wrapper{width:25px;padding-right:5px}
[dir="rtl"] .ng-select .ng-arrow-wrapper{padding-left:5px;padding-right:0}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{border-top-color:#666}
.ng-select .ng-arrow-wrapper .ng-arrow{border-color:#999 transparent transparent;border-style:solid;border-width:5px 5px 2.5px}
.ng-dropdown-panel{background-color:#fff;border:1px solid #ccc;box-shadow:0 1px 0 rgba(0,0,0,0.06);left:0}
.ng-dropdown-panel.ng-select-bottom{top:100%;border-bottom-right-radius:4px;border-bottom-left-radius:4px;border-top-color:#e6e6e6;margin-top:-1px}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{border-bottom-right-radius:4px;border-bottom-left-radius:4px}
.ng-dropdown-panel.ng-select-top{bottom:100%;border-top-right-radius:4px;border-top-left-radius:4px;border-bottom-color:#e6e6e6;margin-bottom:-1px}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{border-top-right-radius:4px;border-top-left-radius:4px}
.ng-dropdown-panel .ng-dropdown-header{border-bottom:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-footer{border-top:1px solid #ccc;padding:5px 7px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{-webkit-user-select:none;-moz-user-select:none;user-select:none;padding:8px 10px;font-weight:500;color:rgba(0,0,0,0.54);cursor:pointer}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{cursor:default}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{background-color:#f5faff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{background-color:#ebf5ff;font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{background-color:#fff;color:rgba(0,0,0,0.87);padding:8px 10px}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{color:#333;background-color:#ebf5ff}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{font-weight:600}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color:#f5faff;color:#333}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{color:#ccc}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-left:22px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{padding-right:22px;padding-left:0}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{font-size:80%;font-weight:400;padding-right:5px}
[dir="rtl"] .ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{padding-left:5px;padding-right:0}
[dir="rtl"] .ng-dropdown-panel{direction:rtl;text-align:right}
body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
  overflow: hidden;
}
body:not(.menu-on-top).mobile-detected {
  min-height: auto !important;
  overflow: hidden;
}
body {
  background: unset;
  background-color: #202124;
}
#content {
  padding: 20px !important;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}
#content::-webkit-scrollbar {
  display: none;
}
.page-footer {
  /* padding-left: 0px; */
  z-index: 0;
  border-top: none;
}
#logo {
  background-repeat: no-repeat;
  background-size: 100%;
  height: 49px !important;
  width: 135px;
  display: block;
  margin-top: 10px;
  margin-left: 36px;
}
.setnavBackgroundImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 90px;
  height: 90px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
  width: 110px;
  height: 110px;
}
.setBackgroundCompanyImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
  margin: 0 auto;
}
.setBackgroundCompanylogoImg {
  position: relative;
  z-index: 2;
  max-width: none;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-repeat: no-repeat;
  display: flex;
}
.col-xs-10 {
  width: 83.33333333% !important;
}
.col-xs-2 {
  width: 16.66666667% !important;
}
.col-xs-3 {
  width: 25% !important;
}
.col-xs-7 {
  width: 58.33333333% !important;
}
.col-xs-8 {
  width: 66.66666667% !important;
}
.col-xs-6 {
  width: 50% !important;
}
.col-xs-1 {
  width: 8.33333333% !important;
}
.col-xs-4 {
  width: 33.33333333% !important;
}
.col-xs-5 {
  width: 41.66666667% !important;
}
#extr-page {
  overflow-x: hidden;
}
#extr-page #header #logo {
  margin-left: 75px !important;
  margin-top: 10px !important;
}
#extr-page #header #logo img {
  width: 200px;
}
.notification-body li span {
  background: #0C1214 !important;
}
.li-footer__copy-text {
  color: #8B91A0;
}
.loader-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}
.loader > span {
  width: 15px;
  height: 15px;
  display: block;
  background: #8B9635;
  border-radius: 50%;
  position: relative;
  margin: 0 5px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dot-1 {
  -webkit-animation: anim 1s linear 0s infinite;
          animation: anim 1s linear 0s infinite;
}
.dot-2 {
  -webkit-animation: anim 1s linear 0.25s infinite;
          animation: anim 1s linear 0.25s infinite;
}
.dot-3 {
  -webkit-animation: anim 1s linear 0.5s infinite;
          animation: anim 1s linear 0.5s infinite;
}
.dot-4 {
  -webkit-animation: anim 1s linear 0.75s infinite;
          animation: anim 1s linear 0.75s infinite;
}
@-webkit-keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@keyframes anim {
  0% {
    top: 0;
  }
  50% {
    top: 15px;
  }
  100% {
    top: 0;
  }
}
@media (max-width: 1161px) and (min-width: 300px) {
  #extr-page #header #logo img {
    width: 150px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
@media only screen and (max-width: 280px) {
  #header > :first-child,
aside {
    width: 150px;
  }

  #extr-page #header #logo img {
    width: 125px;
  }

  #extr-page #header #logo {
    margin-left: 10px !important;
    margin-top: 20px !important;
  }
}
.includePdf label {
  margin-bottom: 5px !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.ng-dropdown-panel {
  border: 1px solid #666 !important;
}
.checkbox i {
  position: absolute;
  top: 3px;
  left: 0;
  display: block;
  width: 17px;
  height: 17px;
  outline: 0;
  border-width: 1px;
  border-style: solid;
  background: transparent !important;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox input + i:after {
  color: #8B9635;
}
.smart-form .checkbox i {
  border-color: #BDBDBD;
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-form .checkbox:hover i {
  border-color: #666666;
}
.fuelux .wizard ul li.complete:hover {
  cursor: pointer;
  background: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #8B9635;
  background-color: #8B9635;
}
.fuelux .wizard ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
}
.ng-select .ng-select-container {
  background: transparent;
  font-size: 12px;
  border-radius: 10px !important;
}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: none;
  box-shadow: none;
}
/*
---------------------------------------------------------------------------------------
    Default Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 #logo-group {
  background: unset !important;
}
.smart-style-1 #logo {
  background-image: url(/assets/img/svgImages/logo.png);
}
.smart-style-1 .btn-header:first-child a {
  background: unset;
  border: 1px solid #e4e4e4;
}
.smart-style-1 #header {
  background-color: #202124;
}
.smart-style-1 aside {
  background-color: #171819;
  border-right: 1px solid #666666 !important;
}
.smart-style-1 .page-footer {
  background-color: #000;
}
.smart-style-1 #ribbon {
  background: #707070;
}
.smart-style-1 .ajax-dropdown {
  background-color: #0C1214 !important;
}
.smart-style-1 .ajax-notifications {
  background-color: #0C1214 !important;
}
.smart-style-1 .header-dropdown-list a:hover {
  color: #ffffff !important;
}
.smart-style-1 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group348.svg);
}
.smart-style-1 .setcompanyImg {
  background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg);
}
.smart-style-1 .setBackgroundCompanyImg,
.smart-style-1 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/svgImages/comapnyDefaultDark.svg);
}
.smart-style-1 .owl-dt-control-button-arrow {
  fill: #ffffff !important;
}
.smart-style-1 .ng-select .ng-select-container {
  color: #fff;
}
.smart-style-1 .ng-select.ng-select-opened > .ng-select-container {
  background: transparent !important;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #202124 !important;
  color: #ffff;
  font-size: 12px;
}
.smart-style-1 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: #ffff !important;
}
.smart-style-1 .ng-dropdown-panel.ng-select-bottom {
  border: unset !important;
}
.smart-style-1 .owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
  background: #202124;
  border: 1px solid #333333 !important;
}
.smart-style-1 .owl-dt-calendar-control .owl-dt-calendar-control-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #202124;
}
.smart-style-1 .owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  color: #fff !important;
}
.smart-style-1 .smart-form .checkbox input:checked + i {
  border: 2px solid #666666;
  border-radius: 5px;
}
.smart-style-1 .fuelux .wizard ul li .chevron {
  border-left: 14px solid #3B424C;
}
.smart-style-1 .fuelux .wizard ul li.complete:hover .chevron:before {
  border-left: 14px solid #171717;
}
/*
---------------------------------------------------------------------------------------
    End Dark theme style
---------------------------------------------------------------------------------------
*/
/*
---------------------------------------------------------------------------------------
    Default White theme style
---------------------------------------------------------------------------------------
*/
.smart-style-2 #logo {
  background-image: url(/assets/img/svgImages/logoWhite.png);
}
.smart-style-2 .login-info {
  color: #212529;
}
.smart-style-2 aside {
  color: #fff !important;
  background: #ededed !important;
}
.smart-style-2 #header {
  background-color: #ffffff !important;
}
.smart-style-2 nav ul .active > a {
  color: #202427 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}
.smart-style-2 .setnavBackgroundImg {
  background-image: url(/assets/img/svgImages/Group349.svg);
}
.smart-style-2 .setBackgroundCompanyImg,
.smart-style-2 .setBackgroundCompanylogoImg {
  background-image: url(/assets/img/svgImages/Group350.svg);
}
.smart-style-2 .ng-select .ng-select-container {
  color: #000;
}
.smart-style-2 .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 12px;
}
.smart-style-2 .owl-dt-calendar-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
  background: #fff;
  border: 1px solid #ccc !important;
}
.smart-style-2 .owl-dt-calendar-control .owl-dt-calendar-control-content {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.smart-style-2 .owl-dt-control-arrow-button svg {
  width: 50%;
  height: 50%;
  color: #000 !important;
}
.smart-style-2 .smart-form .checkbox input:checked + i {
  border-color: #666666 !important;
}
.smart-style-2 .fuelux .wizard ul li .chevron {
  border-left: 14px solid #d4d4d4;
}
.smart-style-2 .ng-select.ng-select-opened > .ng-select-container {
  background: none;
}
/*
---------------------------------------------------------------------------------------
    White  theme style
---------------------------------------------------------------------------------------
*/
.ng-select .ng-select-container {
  color: #333;
  background-color: transparent;
  border-radius: 4px;
  border: none;
  min-height: 36px;
  align-items: center;
}
.smart-style-2.minified #left-panel {
  padding: 0 !important;
  padding-top: 25px !important;
}
@media (max-width: 992px) {
  #content {
    max-height: calc(100vh - 130px) !important;
  }
}
@media (max-width: 576px) {
  #content {
    max-height: calc(100vh - 180px) !important;
  }
}
.cdk-overlay-container {
  z-index: 9999 !important;
}
.owl-dt-inline {
  width: 100% !important;
}
.owl-dt-popup-container .owl-dt-calendar {
  height: 20.25em;
  width: 29.35em;
  margin-left: -8px;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  position: absolute;
  top: 5%;
  left: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 90%;
  height: 90%;
  /* font-size: 0.8em; */
  line-height: 1;
  border: 1px solid transparent;
  border-radius: 999px;
  color: inherit;
  cursor: pointer;
  font-size: 13px;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
  margin-top: 3px;
}
.minified nav ul ul li > a:hover {
  background: #8B9635 !important;
}
.owl-dt-calendar-table :not(.owl-dt-calendar-cell-disabled):hover > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
  background-color: #8B9635 !important;
  color: #000;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: #8B9635 !important;
}
.owl-dt-control-period-button .owl-dt-control-button-content {
  height: 1.5em;
  padding: 0 0.5em;
  border-radius: 3px;
  transition: background-color 100ms linear;
  font-size: 13px;
}
.actionAlign {
  display: flex !important;
  text-align: center !important;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #8B9635 !important;
}
#extr-page {
  background-color: #fcfceb !important;
}
span#logo {
  margin-top: 15px;
}
.ellipse {
  background-image: url("/assets/img/ellipse.png");
  position: absolute;
  left: 0px;
  top: -1px;
  width: 330px;
  height: 270px;
  background-position: left;
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 992px) {
  .ellipse {
    height: 171px;
  }
}
@media (max-width: 500px) {
  .ellipse {
    display: none !important;
  }
}
@charset "UTF-8";
#ribbon ol.breadcrumb {
  font-size: 13px;
  font-weight: 600;
}
.ng-select.ng-select-disabled > .ng-select-container {
  background: none !important;
}
table {
  width: 100%;
  background-color: inherit !important;
  color: #ffffff;
  border-collapse: collapse;
}
.sub-btn {
  font-size: 13px;
  border-radius: 15px;
  background-color: transparent !important;
  padding: 3px 15px;
}
.scroll-dashboard {
  overflow-y: auto !important;
  overflow-x: hidden;
  z-index: 1;
  margin-top: 52px;
}
.scroll-dashboard::-webkit-scrollbar-track {
  background-color: #000;
}
.scroll-dashboard::-webkit-scrollbar {
  width: 0px;
}
.scroll-dashboard::-webkit-scrollbar-thumb {
  background-color: #000;
}
.demo {
  position: absolute;
  top: 5px;
  right: 0;
  width: 160px;
  z-index: 902;
  background: #F1DA91;
}
.demo label {
  margin-bottom: 4 !important;
}
.demo label input[type=checkbox].checkbox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label input[type=radio].radiobox + span:before {
  border: 1px solid #5E5E5E;
}
.demo label:hover input[type=checkbox].checkbox + span:before {
  border-color: #E77317;
}
.demo .checkbox {
  padding-left: 22px;
}
.demo .checkbox i {
  border-color: #7A7A7A;
}
.demo .radio {
  padding-left: 22px;
}
.demo #demo-setting + form {
  display: none;
}
.demo span {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  height: 30px;
  width: 30px;
  border-radius: 5px 0 0 5px;
  background: #F1DA91;
  padding: 2px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.demo span i {
  font-size: 17px;
}
.demo span:hover i {
  color: #333 !important;
}
.demo form section {
  margin: 0 !important;
}
.demo .btn {
  font-size: 13px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
  padding: 3px 7px !important;
  height: auto !important;
}
.demo.activate {
  right: 0 !important;
  box-shadow: -11px 12px 23px rgba(0, 0, 0, 0.2);
  padding: 5px 10px 10px;
}
.demo.activate #demo-setting + form {
  display: block;
}
.demo.activate span {
  left: -30px;
}
.demo.activate span i:before {
  content: "";
}
.demo-liveupdate-1 {
  font-size: 12px !important;
  position: absolute !important;
  left: 33px;
  top: 15px;
  display: block;
  z-index: 2;
  font-weight: 700;
  padding: 1px 61px 3px 7px;
}
.demo-btns {
  margin: 0;
  padding: 0;
  list-style: none;
}
.demo-btns li {
  display: inline-block;
  margin-bottom: 7px;
}
.demo-icon-font {
  font-size: 14px;
  margin-bottom: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
}
.demo-icon-font:hover {
  background-color: rgba(86, 61, 124, 0.1);
  text-decoration: none;
}
.demo-modal {
  width: auto;
  max-width: 600px;
  z-index: 1;
}
.demo-border {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.demo-vertical-uislider {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.demo-vertical-uislider div {
  height: 200px;
  margin: 0 0 0 10px;
  display: inline-block;
}
.demo-vertical-uislider:first-child {
  margin-left: 15px;
}
.toggle-demo {
  position: absolute;
  top: 10px;
  right: -8px;
  display: block;
  width: 154px;
  height: auto;
  z-index: 99;
  opacity: 0.9;
}
.toggle-demo:hover {
  opacity: 1;
}
.toggle-demo .btn-group {
  width: 100px;
  float: left;
}
.toggle-demo span {
  display: inline-block;
  float: left;
  margin-right: 5px;
  line-height: 21px;
  color: #757575;
}
#header .menu-item {
  margin: 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
  cursor: pointer;
}
#header .menu-item_pading {
  padding: 0 10px;
}
#header .menu-left-item {
  padding: 0 10px;
}
#header .company-title {
  font: normal normal bold 12px/19px Open Sans;
}
#header .company-name {
  font: normal normal normal 14px/19px Open Sans;
}
#header #fullscreen .svg-icon {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 45px !important;
  margin-top: -3px;
}
#header .notification-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header #activity.activity-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 60px !important;
  height: 45px !important;
}
#header .collapseMenu .collaspe-menu-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 40px !important;
  height: 30px !important;
}
#header .collapseMenu .collaspe-menu-sub-svg {
  background-repeat: no-repeat;
  width: 45px !important;
  height: 45px !important;
}
#header .header-btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
}
#header .dropdown-icon-menu ul li .btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
}
.knobs-demo div {
  display: inline-block;
  margin-left: 30px;
}
.knobs-demo:first-child {
  margin-left: 0;
}
.widget-box {
  background-color: #ffffff;
  box-shadow: 10px 10px 45px 0 rgba(19, 46, 72, 0.2);
  border-radius: 5px;
  overflow: hidden;
  padding: 20px;
}
.widget-box .title-box .title .ed-icon {
  transform: translateY(-50%) scale(0.8);
  transform-origin: left center;
}
.widget-box .title-box .title .title-lbl {
  font-size: 18px;
  line-height: 18px;
  padding-left: 35px;
}
.widget-box .title-box .title .title-lbl:before {
  left: 25px;
}
.widget-box .title-box .title .title-lbl.no-icon {
  padding-left: 0;
}
.widget-box .title-box .title .title-lbl.no-icon:before {
  display: none;
}
.widget-box .icon-box {
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget-box .icon-box .number {
  font-size: 30px;
}
.widget-box .icon-box .ed-icon {
  width: 30px;
  height: 30px;
}
.widget-box h3 {
  font-size: 24px;
  line-height: 24px;
}
.widget-box p {
  font-size: 18px;
  line-height: 20px;
}
.activity.ajax-dropdown:first-child {
  margin: 0;
  padding: 0;
}
.activity .ajax-notifications {
  border-bottom: none;
  border-top: none;
}
.applist-box1 .applist-row {
  padding: 0 15px;
  flex-wrap: wrap;
}
.applist-box1 .applist-row .app-box {
  text-align: center;
  padding: 20px 0px;
  font-weight: 500;
}
.applist-box1 .applist-row .app-box .module-icon {
  width: 45px;
  height: 45px;
  background-repeat: no-repeat;
  display: inline-block;
  background-size: 100%;
}
.fixed-navigation .nav-demo-btn {
  display: none !important;
}
.minified .nav-demo-btn {
  display: none !important;
}
.minified #left-panel {
  z-index: 904;
  padding-top: 23px !important;
}
.smart-rtl .demo .margin-right-5 {
  margin-right: 0 !important;
}
.listFinal {
  font-size: 16px;
  padding: 5px;
}
.listFinal::before {
  content: ">";
  font-size: 20px;
  padding: 5px;
}
.minified #clearCache_text {
  display: none;
}
.minified #help_text {
  display: none;
}
.minified .userlabel {
  display: none !important;
}
.minified .body-image {
  width: 40px !important;
  height: 40px !important;
  margin: auto;
}
.minified .colDisplay {
  padding: 12px 0px !important;
}
.minified #clearCache .iom_btn {
  padding: 3px 13px !important;
}
.header-dropdown-list li .dropdown-toggle {
  margin-top: 6px;
}
.dash-icon {
  background-image: url(/assets/img/application/dashboard.svg);
}
.purchase-icon {
  background-image: url(/assets/img/application/purchase.svg);
}
.inv-icon {
  background-image: url(/assets/img/application/invoice.svg);
}
.file-icon {
  background-image: url(/assets/img/application/filemanager.svg);
}
.vmi-icon {
  background-image: url(/assets/img/application/vmi.svg);
}
.contract-icon {
  background-image: url(/assets/img/application/contract.svg);
}
.edi-icon {
  background-image: url(/assets/img/application/edi.svg);
}
.header-dropdown-list .dropdown-menu {
  padding: 4px;
  overflow: auto;
  max-height: 200px;
  border-radius: 10px;
}
.header-dropdown-list .dropdown-menu li a {
  border-radius: 0 !important;
  font-size: 14px;
}
.header-dropdown-list .dropdown-menu::-webkit-scrollbar {
  width: 0px;
}
.nav-demo-btn {
  display: none;
}
body:not(.menu-on-top) .nav-demo-btn {
  display: block;
  padding: 6px 5px;
  margin: 5px 10px;
  width: auto;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  font-size: 12px;
  white-space: normal;
}
.ajax-dropdown-app > :first-child {
  margin: 0 0 3px;
  padding: 0 0 9px;
}
.page-footer {
  position: fixed;
  border-top: transparent;
}
.hidden-menu .page-footer {
  padding-left: 20px;
}
.dashHeadLocation {
  color: #ffffff;
  display: flex;
  list-style: none;
  padding: 0;
}
.listitems {
  font-size: 24px;
}
.main-nav {
  text-align: right;
  font: normal normal normal 16px/22px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  opacity: 1;
  padding-top: 11px;
}
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-left: 0;
  padding-right: 10px;
}
#content {
  background: #1E1E1E !important;
}
.theme-header-color {
  color: #E2E2E2 !important;
}
.theme-text-color {
  color: #E2E2E2 !important;
}
.header-menu {
  font: normal normal normal 14px/19px Open Sans;
}
.notification-body > li {
  border: 1px solid #e3e3e3 !important;
}
.flag.flag-en {
  background-position: -144px -154px;
}
#left-panel {
  position: absolute;
  top: 52px;
  z-index: 904;
  padding-top: 2px;
}
#left-panel nav ul li.active > a:before {
  right: -19px;
  content: none !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.notification-body .subject {
  color: #7A7A7A !important;
}
.iombackground {
  background-repeat: no-repeat;
  background-position: center;
  max-height: 100vh;
  height: 100vh;
  background: #C04848;
  /* fallback for old browsers */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(rgba(102, 102, 102, 0.9), rgba(102, 102, 102, 0.9)), url(/assets/img/register.png);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background-size: cover;
  background-repeat: no-repeat;
}
.userAccountSvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('user.23211b4b22ee8a248707.png');
}
.toolsvg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('tools.9b60c16d7739a9ac429d.png');
}
.support-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('support.f7bfa814958611603cb8.png');
}
.conection-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('conections.7e1bc3b862fe00171b61.png');
}
.data-svg {
  background-repeat: no-repeat;
  background-position: center;
  width: 45px !important;
  height: 45px !important;
  background-image: url('data2x.530f077fc89937c875d0.png');
}
.NextconfirmLabel {
  font-size: 13px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-right: 15px;
  padding: 0px 15px;
  border-radius: 20px;
}
.fa-exclamation {
  color: red;
  font-size: x-large;
  padding: 0px 0px;
}
.ui-dialog-titlebar-close:before {
  content: "";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
}
.ui-dialog .ui-dialog-titlebar-close {
  position: absolute;
  right: 0.3em;
  top: 50%;
  width: 19px;
  margin: -20px 0 0 0;
  padding: 1px;
  height: 18px;
  font-size: 20px;
  font-weight: 400;
  line-height: 19.5px;
  text-shadow: none;
}
.blacktooltip {
  text-align: left;
  z-index: 1000;
  position: fixed;
  width: 150px;
  height: auto;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
}
.blacktooltip .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.blacktooltip .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.error-blacktooltip {
  text-align: left;
  z-index: 1000;
  position: absolute !important;
  width: 150px;
  height: auto;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
}
.error-blacktooltip .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
@media (max-width: 979px) {
  .page-footer {
    padding: 5px 14px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  #left-panel {
    top: 162px !important;
  }
}
@media (min-width: 480px) and (max-width: 576px) {
  #left-panel {
    top: 162px !important;
  }
}
@media (min-width: 577px) and (max-width: 803px) {
  #left-panel {
    top: 155px !important;
  }

  .comapnyLbl {
    justify-content: flex-start !important;
  }
}
@media (max-width: 320px) {
  #left-panel {
    top: 255px !important;
  }
}
@media (min-width: 577px) and (max-width: 803px) {
  #left-panel {
    top: 155px !important;
  }
}
@media (max-width: 992px) {
  #fullscreen .svg-icon {
    width: 40px !important;
  }

  #header #activity.activity-svg {
    width: 40px !important;
  }

  #header .notification-svg {
    width: 40px !important;
  }

  #header .menu-item_pading {
    padding: 0 10px 0 0 !important;
  }

  .comapnyLbl {
    justify-content: flex-start !important;
  }
}
@media (max-width: 500px) {
  .header-formbtn,
.advace-header-formbtn {
    width: 100% !important;
  }
}
@media (min-width: 1200px) and (max-width: 1375px) {
  .header-formbtn {
    width: 60px !important;
  }
}
@media (max-width: 1300px) {
  .comapnyLbl {
    justify-content: flex-start !important;
  }
}
@media screen and (max-width: 1545px) {
  .scroll-tbs {
    height: 173px !important;
    overflow-y: auto !important;
  }
}
@media (min-width: 1440px) and (max-width: 1800px) {
  .englishText {
    font-size: 8px !important;
  }

  .germanText {
    font-size: 6px !important;
  }

  .englishTextFuture {
    font-size: 10px !important;
  }

  .germanTextFuture {
    font-size: 10px !important;
  }
}
@media (max-width: 1440px) {
  .englishText,
.englishTextFuture,
.germanTextFuture,
.germanText {
    width: 15% !important;
  }
}
.scroll-content::-webkit-scrollbar {
  width: 1px;
}
.scroll-content {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-x: none;
}
.MessageBoxButtonSection button {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.MessageBoxButtonSection button:hover {
  border-radius: 15px;
  background: #8B9635 !important;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
  color: #000 !important;
}
.contact_infrmtn_sctn .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.comapny_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.detail_save_btn,
.detail_save_Btn,
.approveBtn,
.gotoEdi,
.iom_btn,
.detailInfo_save_btn {
  border-radius: 15px;
  background: transparent;
  border: 2px solid #8B9635;
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
.detail_save_btn:hover,
.detail_save_Btn:hover,
.approveBtn:hover,
.gotoEdi:hover,
.iom_btn:hover,
.detailInfo_save_btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
}
.iom_btn[disabled] {
  box-shadow: none;
  cursor: not-allowed;
  opacity: 0.65;
}
.editDisabled {
  background: none;
}
.editEnabled {
  background-color: #171819 !important;
}
.border-line {
  background: transparent !important;
  border: none;
}
.nextBtn {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #ffffff !important;
  padding: 3px 15px;
}
.nextBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.nextstep {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #ffffff !important;
  padding: 3px 15px;
}
.nextstep:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.cancelBtn {
  border: 2px solid #8B9635;
  border-radius: 15px;
  color: #ffffff !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.cancelBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.butttonBluecolor {
  background-color: #202528 !important;
  border: 2px solid #8B9635 !important;
  color: #ffffff !important;
  border-radius: 15px;
  padding: 3px 15px;
}
.butttonBluecolor:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.lightBackgroundColor {
  background-color: #0C1214 !important;
}
.noscroll {
  overflow-y: hidden !important;
}
.outerMainDiv {
  padding: 20px !important;
  max-height: calc(100vh - 100px) !important;
  overflow-y: auto !important;
}
.PaySctn input[type=radio]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #8B9635;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #666666;
  cursor: pointer;
}
.gridSectionFirst .payMethodSection {
  padding: 11px 10px 10px 15px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  border-radius: 10px;
  min-height: 123px;
}
.gridSectionFirst .payMethodSection .payHead span {
  font-size: 16px;
  font-weight: 600;
}
.gridSectionFirst .payMethodSection .payLink {
  padding: 3px 5px 0px 15px;
}
.gridSectionFirst .payMethodSection .payLink span {
  font-size: 14px;
  color: #8B9635;
  cursor: pointer;
}
.gridSectionFirst .techContactSection {
  padding: 11px 30px 10px 30px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  border-radius: 10px;
  height: auto;
}
.gridSectionFirst .techContactSection .techHead {
  font-size: 16px;
  font-weight: 600;
}
.gridSectionFirst .techContactSection > .row {
  display: block;
}
.next_payment {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  min-width: 60%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.applicationHead {
  margin-top: 15px;
  height: 34px;
  opacity: 1;
  border: unset;
}
.tranhoverTable input[type=radio]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -1px;
  left: -1px;
  position: relative;
  background-color: #8B9635;
  content: "";
  display: inline-block;
  visibility: visible;
}
.tranhoverTable input[type=radio] {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid darkgray;
  border-radius: 50%;
  outline: none;
}
.noBorder {
  border: none !important;
}
.activeUser .dt-toolbar-footer {
  border: none;
  background: none;
}
.userEditTextBox {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.nameDiv,
.headDiv {
  color: #202528;
}
.user-table .table tbody tr:first-child td {
  border-top: none !important;
}
.rightBorder {
  border-right: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
}
.topBorder {
  border-top: 2px solid #8B9635 !important;
}
.bottomBorder {
  border-bottom: 2px solid #8B9635 !important;
}
.selected {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
}
.gridSectionTwo .billingContactSection {
  height: auto;
  padding: 10px 30px;
  margin: 20px 0px 0px 20px;
  width: 96%;
  border-radius: 10px;
}
.gridSectionTwo .billingContactSection > .row {
  display: block;
}
.fixed-head {
  position: sticky;
  top: -20px;
}
.detail-div .fixed-head {
  position: sticky;
  top: 0px;
  z-index: 100;
}
table.table-bordered.dataTable {
  border-collapse: none !important;
}
.user-table .dt-toolbar-footer {
  display: none !important;
}
.dt-toolbar-footer {
  background: none !important;
  width: 100% !important;
  font-size: none !important;
  overflow: hidden;
  padding: none !important;
  border-top: none !important;
  -ms-box-shadow: none !important;
  box-shadow: none !important;
}
.dataTables_processing {
  display: block;
  text-indent: -99999px;
  border: 0px !important;
  box-shadow: none !important;
  height: 55px;
  width: 55px;
  z-index: 999;
  margin-top: 100px !important;
  background-position: center !important;
  margin-left: 35px;
}
.lstposTbale .dataTables_processing {
  display: block;
  text-indent: -99999px;
  border: 0px !important;
  box-shadow: none !important;
  z-index: 999;
  margin-top: 15px !important;
  background-position: center !important;
}
.lstposTbale .dt-toolbar {
  background: none !important;
  border: none !important;
  display: none;
}
.dt-toolbar {
  background: none !important;
  border: none !important;
}
table.pending-list-table {
  border-collapse: separate !important;
}
table.pending-list-table thead tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
table.pending-list-table thead tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}
table.pending-list-table tbody tr:last-child td:first-child {
  border-radius: 0px 0px 0px 5px !important;
}
table.pending-list-table tbody tr:last-child td:last-child {
  border-radius: 0px 0px 5px 0px !important;
}
.table thead:first-child tr:first-child th {
  border-bottom: none !important;
}
button.disabled {
  cursor: no-drop !important;
  pointer-events: none !important;
  opacity: 0.3;
}
.badge-disable-iom {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 13px !important;
  padding: 4px 13px !important;
  border-radius: 10rem;
  cursor: no-drop;
  background: transparent;
  border: 2px solid #8B9635;
}
.MessageBoxMiddle .MsgTitle {
  letter-spacing: -1px;
  font-size: 24px;
  font-weight: 300;
  margin-left: -28px;
}
.pure-checkbox input[type=checkbox] + label:after {
  display: none !important;
}
.pure-checkbox input[type=checkbox] {
  position: relative !important;
  height: 15px !important;
  width: 15px !important;
}
.dropdown-list.fixMultiSelect {
  display: block !important;
  position: static !important;
}
.custom-scroll::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
#userRolePopup {
  max-height: 445px !important;
}
#userRolePopup footer {
  padding: 1rem 2rem;
  margin-top: 2rem;
}
#userRolePopup .dropdown-multiselect__caret {
  display: none !important;
}
.serviceTheme {
  border-radius: 10px !important;
}
.rolePopUp {
  max-height: calc(100% - 100px);
  overflow-y: auto;
  border-radius: 10px;
}
.user-role-form .multiselect-dropdown .dropdown-btn {
  border: unset !important;
}
.user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
  max-width: unset !important;
  padding: 3px 10px !important;
  border-radius: 20px !important;
  margin-right: 10px !important;
}
.user-role-form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #000 !important;
}
.user-role-form .multiselect-dropdown .filter-textbox input {
  background: #171717 !important;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
  border-radius: 5px;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #ffffff !important;
  border: 2px solid #ffffff !important;
}
.user-role-form .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: transparent !important;
}
.price_calcultation_form .multiselect-dropdown {
  width: 98.5% !important;
  height: 40px !important;
  padding: 4px 0px 0px;
  font: 13px/16px "Open Sans", Helvetica, Arial, sans-serif;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn {
  border-radius: 10px !important;
  padding: 8px 12px !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn span {
  padding-left: 5px;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
  max-width: unset !important;
  padding: 0px 5px !important;
  border-radius: 20px !important;
  box-shadow: 1px 1px #8B9635 !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  color: #000 !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  display: none !important;
}
.price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #171717 !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #ffffff !important;
  border: 2px solid #a09d9d !important;
  border-radius: 5px;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #ffffff !important;
  border: 2px solid #a09d9d !important;
}
.price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input[type=checkbox]:checked + div:before {
  background: transparent !important;
}
.price_calcultation_form .multiselect-dropdown .dropdown-multiselect__caret {
  top: 5px !important;
}
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgba(182, 235, 0, 0.25) !important;
}
.btn:focus {
  box-shadow: 0 0 0 0.2rem rgba(182, 235, 0, 0.25) !important;
}
.toolTipHideUserRole {
  width: 285px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0;
}
.roleLabel {
  width: 340px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: justify;
}
.tooltipRole {
  position: relative;
  display: inline-block;
}
.tooltipRole:hover {
  cursor: pointer;
}
.tooltipRole:hover .tooltiptextRole {
  visibility: visible;
}
.tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.tooltipRole .tooltiptextRole {
  visibility: hidden;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  word-break: break-all;
  position: fixed;
  z-index: 1;
  left: 38%;
  top: 0%;
  text-align: left;
  width: auto;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  height: auto;
}
.tooltipRole .tooltiptextRole .insideDivUl {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0rem;
}
.tooltipRole .tooltiptextRole .insideDivUl li {
  height: 38px;
  line-height: 38px;
  cursor: default;
}
.tooltipRole .toolTipRoleTitle {
  padding: 5px 5px !important;
  left: inherit !important;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  z-index: 1000;
}
.minified .profile-image-box {
  display: none;
}
.minified ul.navList {
  margin-top: -25px !important;
}
.spin-loader {
  height: 25px;
  width: 25px;
  display: inline-block;
  margin: 13px 0px 0px 10px;
}
.big-spin-loader {
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 5% auto;
  transform: translate(0, -50%);
  top: 50%;
  z-index: 99;
}
.serviceTheme .modal_header {
  padding: 0 13px;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
  font-size: 24px;
}
.card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.cardNumber {
  margin-bottom: 7px;
}
.cvv {
  margin-top: 22px;
}
/* Checkbox style */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  left: 0;
  height: 25px;
  width: 25px;
  border-radius: 7px;
  background-color: transparent;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: transparent;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 5px !important;
  border-top-right-radius: 5px !important;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-bottom-left-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
button[disabled] {
  cursor: no-drop !important;
}
html input[disabled] {
  cursor: no-drop !important;
}
.modalContent {
  padding: 20px;
  height: 200px;
}
.modalContent .modalBody {
  border-radius: 10px !important;
  padding: 0px;
}
.modalContent .modalHead {
  border: unset !important;
  padding: 0px;
  margin-bottom: 10px;
  display: inline-block;
}
.modalContent .modalHead .modalTitle {
  text-align: left;
  font: normal normal bold 16px/22px Open Sans;
  letter-spacing: 0px;
}
.table-wrapper table {
  margin-bottom: 0px !important;
}
.dt-toolbar-footer {
  padding: 5px 0px;
}
.footer-padding .dt-toolbar-footer {
  padding: 5px 10px;
}
.dropdown-list {
  box-shadow: none !important;
}
.header-formbtn {
  width: auto;
  margin-top: 1px !important;
  border: none;
  background: #ffffff !important;
  text-align: center !important;
  letter-spacing: 0;
  color: #073E84 !important;
  font-weight: bold;
  font: normal normal normal 13px/20px Open Sans !important;
  border-radius: 15px;
  margin-left: 10px;
}
.subPlanData {
  padding: 20px 0 0;
}
.subPlanData .content {
  margin-top: 10px;
}
.subPlanData .content p {
  font-size: 16px !important;
}
.comingSoon {
  text-align: left;
  z-index: 1001;
  position: absolute;
  width: auto;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  height: auto;
  padding: 10px;
  font: normal normal normal 13px/18px Open Sans;
  border-radius: 10px;
  opacity: 1;
  font-weight: bold;
  background-color: #8B9635 !important;
  margin-top: -10px;
}
.crossBtn {
  text-align: right;
  letter-spacing: 0px;
  color: #8B9635;
  opacity: 1;
  font-size: 20px;
  font-weight: bold;
}
input:-internal-autofill-selected {
  background-color: red !important;
}
.borderClassForm input,
.borderClassForm select {
  border-color: #dc3545 !important;
}
.radioMargin {
  margin-top: -2px;
}
.pe-none {
  pointer-events: none;
}
.greentext {
  color: #8B9635 !important;
}
.textalignlabel {
  text-align: center;
  width: auto;
  padding: 10px 15px;
}
.user-list {
  font-size: 14px;
  min-height: 170px;
  max-height: 320px;
  overflow-y: scroll;
}
.user-list::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.user-list::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.user-list::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.ui-datepicker {
  border-radius: 5px !important;
  z-index: 2000 !important;
}
.ui-datepicker select.ui-datepicker-month {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker select.ui-datepicker-year {
  margin: 0 2px;
  width: 47%;
}
.ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.ui-datepicker .ui-datepicker-next span {
  background: none !important;
}
.ui-datepicker .ui-datepicker-prev span {
  background: none !important;
}
.ui-datepicker-prev span {
  background-image: none !important;
}
.ui-datepicker-next span {
  background-image: none !important;
}
.ui-datepicker-prev:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.ui-datepicker-next:before {
  font-family: FontAwesome;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  font-weight: normal;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  z-index: 9;
  padding-top: 4px;
  content: "";
}
.setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.filter-top {
  font-size: 16px !important;
  letter-spacing: 0px;
  font-weight: 400;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
}
.filter-top .link {
  color: #8B9635 !important;
  font-weight: 400;
  font-size: 14px;
}
.dashbaordlabel {
  background-color: unset !important;
  border: 1px solid #333333 !important;
  border-radius: 5px !important;
}
.companyslabel {
  background-color: unset !important;
  border: 1px solid #0C1214 !important;
  border-radius: 5px !important;
}
.backdrop {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: black;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 1060;
}
.search_status {
  color: #202124;
  background-color: unset !important;
}
.user-list-select {
  color: #202124;
  background-color: unset !important;
}
.select_search_status {
  color: #202124;
  background-color: unset !important;
}
.drop {
  color: #000 !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.bulkTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.dataTable tbody tr.selected,
table.dataTable tbody tr.selected:hover {
  background-color: #8B9635 !important;
  border-color: #ccc;
}
.setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.algntblTranscation {
  border-radius: 8px;
}
.transactionsHeads {
  top: 359px;
  left: 233px;
  height: 36px;
  background: #3B424C;
  border-color: #3B424C;
  opacity: 1;
  padding-left: 10px;
  border-left: transparent;
  border-right: transparent;
}
.amount-div {
  width: 30%;
  float: right;
  height: 100px;
  padding: 10px;
  position: relative;
  top: 148px;
  border-radius: 5px;
}
.amount-div .total-amount {
  font-size: 16px;
  font-weight: 600;
}
.amount-div .amountDiv {
  float: right;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}
.scroll-tbs {
  height: 170px !important;
  overflow-y: auto !important;
  margin-top: 10px;
  margin-left: -3px;
}
.scroll-tbs::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}
.scroll-tbs::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.scroll-tbs::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.idTooltip,
.btnTooltip {
  text-align: center;
  z-index: 99999;
  position: absolute;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.idTooltip .insideDivUl-nohover,
.btnTooltip .insideDivUl-nohover {
  z-index: 99999;
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: 150px;
  word-break: break-all;
}
.companyTooltip {
  text-align: center;
  z-index: 1000;
  position: fixed;
  border-radius: 5px;
  font: normal normal normal 13px/18px Open Sans;
}
.companyTooltip .insideDivUl-nohover {
  list-style: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-all;
  height: auto;
}
.advace-header-formbtn {
  margin-top: 18px !important;
  border: none;
  background: #ffffff !important;
  text-align: center !important;
  letter-spacing: 0;
  color: #073E84 !important;
  font-weight: bold;
  font: normal normal normal 13px/20px Open Sans !important;
  border-radius: 30px;
}
.error_text {
  font-size: 13px;
}
.error-desc {
  font-size: 13px !important;
}
li.active a.error {
  color: #DC4B38 !important;
}
.CodeMirror-wrap pre {
  word-break: break-word;
}
.codePopup {
  width: 1500px !important;
}
.CodeMirror {
  height: 600px !important;
  font-size: 16px;
}
.wizard .plusBtn {
  position: absolute;
  right: 20px;
  cursor: pointer;
  top: 13px;
}
.wizardCircleWidth {
  width: 20%;
  flex: 1 0 20% !important;
}
.wizardTextWidth {
  width: 75%;
  flex: 1 0 75% !important;
  display: flex;
  align-items: flex-start !important;
}
.wizardDiv {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}
b.badges {
  position: absolute;
  cursor: pointer;
  font-size: 10px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
  content: "";
  top: -1px;
  right: -1px;
  transform: translate(25%, -25%);
  width: 25px;
  height: 25px;
  color: #111;
  background-color: #8B9635;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
}
.accordianXpand {
  transition: all 0.2s ease-in-out 0s !important;
}
.wizard#accordionExpandWizard {
  border-radius: 10px;
  color: #333333;
  padding: 20px 20px 0;
}
.wizardCircleWidth {
  width: 20%;
  flex: 1 0 20% !important;
}
.line {
  width: 5%;
  z-index: 1;
  position: relative;
}
.wizard-items {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.green-border {
  border: 1px solid #8B9635 !important;
}
#wizard .wizard-container {
  display: flex;
  flex-direction: row;
}
#wizard {
  border-radius: 15px;
}
.wizardTextWidth {
  width: 99%;
  flex: 1 0 60% !important;
  display: flex;
  align-items: flex-start !important;
}
.form-group .form-control {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 40px;
}
.wizard-billeritem {
  width: 30%;
  border-radius: 10px;
  z-index: 100;
  position: relative;
  min-height: 90px;
  display: flex;
}
.line-1 {
  height: 1px;
  width: 100%;
  z-index: 200;
  position: relative;
  top: 50% !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 13px !important;
}
.biller_tabSection .nav-item {
  font-size: 14px !important;
}
.form-group .form-control {
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  height: 40px;
}
.form-group textarea.form-control {
  height: unset !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.form-group select {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.communicationPanel {
  display: block !important;
}
.billerFormSection formly-wrapper-form-field div {
  font-size: 13px !important;
}
.includePdf formly-field-radio div .custom-radio {
  padding-left: 2.5rem;
}
.includePdf formly-field-radio div {
  display: flex;
}
.ebillForm formly-field-select select option {
  background-color: #202124 !important;
}
.ebillForm .commmonWrap formly-group {
  width: 100% !important;
}
.commmonWrap formly-group {
  width: 100% !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.bulkTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px;
}
table.dataTable thead > tr > th.sorting_disabled {
  padding-right: 9px !important;
}
.searchFilterSection .commonfield .company_nameField .ng-select-container,
.searchFilters .commonfield .company_nameField .ng-select-container {
  background: unset !important;
  border: unset !important;
}
.searchFilterSection .commonfield .ng-select.ng-select-opened > .ng-select-container,
.searchFilters .commonfield .ng-select.ng-select-opened > .ng-select-container {
  background: unset !important;
}
.searchFilterSection .commonfield .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container,
.searchFilters .commonfield .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: unset !important;
}
.disableForm .commonfield .company_nameField .ng-select-container {
  background: unset !important;
}
.disableForm .panelSector .ng-select.ng-select-opened > .ng-select-container {
  background: unset !important;
}
.disableForm .panelSector .ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  box-shadow: unset !important;
}
.disableForm .panelSector .ng-select.ng-select-single .ng-select-container {
  height: 41px !important;
}
.agents .ng-select.ng-select-single .ng-select-container {
  width: 395px;
}
.descriptionagents .ng-select.ng-select-single .ng-select-container {
  width: 395px;
}
nav ul li a b {
  right: 17px !important;
  top: 12px !important;
  font-size: 14px !important;
}
nav ul ul {
  padding: 0px;
}
nav ul ul li {
  padding: 6px;
}
nav ul ul li > a:hover {
  background: #8B9635 !important;
}
nav ul ul li > a:hover {
  background: #666 !important;
}
nav ul b .fa-plus-square-o:before {
  content: "" !important;
}
nav ul b .fa-minus-square-o:before {
  content: "" !important;
}
.text-area label.col-form-label {
  margin-right: -20px;
}
.requiredSection .ng-select-container {
  border-color: #dc3545 !important;
}
.requiredSector {
  color: #dc3545 !important;
  font-size: 13px !important;
}
.infoIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  filter: brightness(0) saturate(100%) invert(72%) sepia(13%) saturate(4773%) hue-rotate(23deg) brightness(83%) contrast(58%);
}
.dataTable tbody tr td {
  font-size: 13px;
}
.emailMessage {
  border-radius: 10px !important;
  height: 100px;
  padding: 15px 7px;
  margin-top: 27px;
}
.green-label {
  color: #beeb20 !important;
}
.form-control.ng-invalid.is-invalid {
  border-color: #dc3545 !important;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  border: 1px solid #6666 !important;
  font-size: 13px !important;
}
.companySelect .requiredSector {
  color: #dc3545;
}
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  color: #beeb20 !important;
  font-size: 13px !important;
}
.select-div {
  display: flex !important;
  align-items: flex-start !important;
  height: 20px;
}
.select-div span.checkbox-text {
  display: inline-block !important;
  width: 80px !important;
  text-align: left;
}
.defaultDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.stepsinout-form select,
.businessconnect-form select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
}
.stepsinout-form .input-group-append .input-group-text:before,
.businessconnect-form .input-group-append .input-group-text:before {
  margin-left: -6px;
  padding-left: 6px;
}
.stepsinout-form .stepsDown .form-group input {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.btn-primary:focus {
  box-shadow: none;
}
.stepsinout-form.formly .input-group-text,
.businessconnect-form.formly .input-group-text {
  font-size: 13px;
  font-weight: 400;
  color: #0C1214;
  text-align: center;
  border: unset !important;
}
.stepsinout-form .custom-checkbox .custom-control-label::before {
  width: 1.2rem;
  height: 1.3rem;
  top: 0.5rem;
}
.stepsinout-form .custom-checkbox .custom-control-label::after {
  top: 0.4rem;
  left: -1.7rem;
  width: 1.5rem;
  height: 1.5rem;
}
.fileExport .form-group {
  display: flex;
}
.stepsinout-form .fileExport formly-field-input {
  margin-left: 20px;
}
.stepsinout-form .custom-radio .custom-control-label {
  margin-right: 20px;
}
.stepsinout-form .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: none;
}
.stepsinout-form .custom-radio.custom-control {
  display: inline-block;
}
.invoice-tab-header .nav-tabs > li {
  text-align: center;
}
.invoice-tab-header .nav-tabs > li > a {
  margin: auto;
}
.transactions-detail-document table {
  table-layout: fixed !important;
}
.transactions-detail-document table td {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.transactions-detail-document table td .tr-filename {
  word-break: break-all !important;
}
.error-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
}
.error-div div.error-div-div {
  width: 300px;
  width: 300px;
  height: 200px;
  border-radius: 8px;
  padding: 5px;
}
table.dataTable {
  clear: both;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
}
table.positionTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
table.positionTable thead tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}
table.bulkTable thead tr th:first-child {
  border-radius: 5px 0px 0px 0px !important;
}
table.bulkTable thead tr th:last-child {
  border-radius: 0px 5px 0px 0px !important;
}
.englishText {
  font-size: 10px !important;
  padding: 2px 5px !important;
}
.germanText {
  font-size: 8px !important;
  padding: 3px 5px !important;
}
.englishTextFuture {
  width: 15% !important;
}
.germanTextFuture {
  width: 15% !important;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list {
  position: fixed;
  width: auto;
}
.ediPriceCalc ng-multiselect-dropdown .multiselect-dropdown .dropdown-list[hidden] {
  position: absolute;
}
.includePdf formly-field-radio div {
  display: flex;
}
.includePdf formly-field-radio div .custom-radio {
  padding-left: 2.5rem;
}
.minified nav > ul > li > a > .menu-item-parent {
  top: 1px !important;
}
.minified nav > ul > li > ul {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.minified nav ul ul li > a:hover {
  background: #8B9635 !important;
}
.page-title {
  font-size: 16px !important;
  margin-left: unset !important;
  font-weight: 400;
  font-family: "Open Sans", Arial, Helvetica, Sans-Serif;
}
.itemEditButton {
  font-size: 37px;
  text-align: center;
  font: normal normal normal 30px/20px Open Sans;
  letter-spacing: -0.3px;
  border: 2px solid #8B9635;
  border-radius: 19px;
  font-size: 13px;
  padding: 3px 15px;
}
.itemEditButton:hover {
  background: #8B9635 !important;
  border-color: #8B9635 !important;
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
}
.itemEditButton:active {
  background: #8B9635 !important;
  border-color: #8B9635 !important;
}
.tooltipA {
  position: relative;
  display: inline-block;
}
/* Tooltip text */
.tooltipA .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 100;
  top: -5px;
  right: 40%;
}
/* Show the tooltip text when you mouse over the tooltip container */
.tooltipA:hover .tooltiptext {
  visibility: visible;
}
.owl-dt-calendar-table .owl-dt-calendar-header {
  display: none;
}
.NextconfirmLabels {
  font: normal normal normal 13px/24px Open Sans;
  letter-spacing: 0px;
  margin-right: 15px;
  padding: 4px 10px;
  border-radius: 20px;
  margin-top: -7px;
  margin-left: 10px;
}
.disabledProp .input-group {
  cursor: not-allowed;
}
.disabledProp select {
  cursor: not-allowed;
  pointer-events: none;
}
.disabledProp .custom-radio {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall .input-group {
  cursor: not-allowed;
}
.disableall select {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall .custom-radio {
  cursor: not-allowed;
  pointer-events: none;
}
.disableall input {
  cursor: not-allowed;
  pointer-events: none;
}
.modal-footer {
  border-top: none;
}
.ebillForm header {
  display: block;
  padding: 8px 0px;
  border-bottom: 1px dashed #333;
  background: transparent !important;
  font-size: 15px;
  font-weight: 500;
  margin: 0 0px 20px;
}
.formatIcon {
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.user-ebill .commmonWrap {
  margin-bottom: 10px !important;
}
.has-danger .form-control {
  border-color: #f86c6b !important;
}
.form-control.ng-invalid.ng-touched {
  border-color: #dc3545 !important;
}
.xslt-input {
  width: 92%;
  font-size: 12px;
}
table.bulkList {
  margin-top: 10px !important;
}
.setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.setCirclereg {
  color: #3276b1;
  font-size: 10px !important;
}
.setCircleSend {
  color: #d9720f;
  font-size: 10px !important;
}
.archiveImport {
  border-radius: 15px;
  border: 2px solid #8B9635;
  font-size: 13px;
  padding: 3px 15px;
  line-height: 19.5px;
  font-weight: 400;
}
li.active .last-li-child {
  border-top-right-radius: 8px;
}
.transaction li:first-child a {
  border-top-left-radius: 8px !important;
}
.fixed-header {
  position: sticky;
  top: 0px;
  z-index: 100;
}
.field_without_addon .form-control {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.selectedFormats {
  position: relative;
  border-radius: 10px !important;
  height: 40px;
  padding: 0 44px 0 10px;
}
.formatRow {
  margin: 0px 10px 0px 0px;
}
.copyIcon {
  position: absolute;
  font-size: 16px;
  right: 20px;
  top: 20px;
}
.fileFormat_toolTip {
  width: 100%;
  color: #fff;
  padding: 5px 35px;
  overflow-y: scroll;
}
.fileFormat_toolTip::-webkit-scrollbar {
  display: none !important;
}
.fileFormat_toolTip {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
  /* Firefox */
}
.fileFormatContent {
  border-radius: 10px !important;
  border: none !important;
}
.fileFormatContent .modal-header {
  border-bottom: unset !important;
}
.fileFormatContent .modal-footer {
  border-top: unset !important;
  padding: 10px 12px 10px 12px;
}
.fileFormatfooter {
  border-top: unset !important;
}
.commonSeperator {
  padding: 0 10px 0 10px;
}
.commonAchor {
  width: 30%;
  color: #8B9635 !important;
}
.foramatDetail {
  width: 64%;
}
.commonFileFormat {
  font-size: 14px;
}
.fileFormatContent .modal-footer {
  border-top: unset !important;
  padding: 10px 12px !important;
}
.setCirclerej {
  color: #eb0042;
  font-size: 10px !important;
}
.setCircleTest {
  color: #F1DA91;
  font-size: 10px !important;
}
.setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.setCirclereg {
  color: #3276b1;
  font-size: 10px !important;
}
.setCircleSend {
  color: #d9720f;
  font-size: 10px !important;
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  font-size: 13px;
}
.stepsinout-form.formly .input-group-text,
.fileuploadform-form .businessconnect-form.formly .input-group-text {
  font-size: 13px;
  font-weight: 400;
  color: #0C1214;
  text-align: center;
  border: unset !important;
}
.cdk-global-scrollblock {
  overflow-y: auto !important;
}
.iom-button {
  border-radius: 20px;
  background: transparent;
  border: 2px solid #8B9635;
  font-size: 13px;
  padding: 7px 28px;
}
.iom-button:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.searchRecipientForm formly-field label {
  font-size: 13px;
}
.searchRecipientForm formly-field formly-field-input input {
  border-radius: 10px !important;
}
.searchRecipientForm formly-field formly-validation-message {
  font-size: 12px;
}
.dividerOr .alignOr {
  display: flex !important;
  justify-content: center !important;
  margin: 0px !important;
  padding-top: 10px !important;
  font-size: 14px;
}
/* .companyTooltip {
	text-align: center;
	z-index: 1000;
	position: fixed;
	width: 150px;
	height: 86px;
	border-radius: 5px;
	font: normal normal normal 13px/18px Open Sans;

	.insideDivUl-nohover {
		list-style: none;
		padding: 5px;
		display: flex;
		flex-direction: column;
		width: fit-content;
		word-break: break-all;
		height: auto;
	}

} */
/*
---------------------------------------------------------------------------------------
  Dark theme style
---------------------------------------------------------------------------------------
*/
.smart-style-1 .login-page .iom_btn {
  color: #000 !important;
}
.smart-style-1 .successchangepassDiv {
  background-color: #171819;
}
.smart-style-1 .connectorBorder select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.smart-style-1 .stepsinout-form.formly .form-control {
  color: #fff !important;
  background-color: #0c1214 !important;
  border-color: #0c1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .templatest-form .form-control {
  color: #fff !important;
  background-color: #202124 !important;
  border: 1px solid #333333 !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .connector-form .inputSection .form-control,
.smart-style-1 .connector-form .selectSection .form-control {
  background-color: #0C1214 !important;
  border: 1px solid #0C1214 !important;
}
.smart-style-1 .btn-primary {
  background: transparent !important;
  border: 2px solid #8B9635;
  color: #ffffff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-1 .btn-primary:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 2px solid #8B9635;
}
.smart-style-1 .CodeMirror {
  background: lightgrey;
}
.smart-style-1 #main {
  height: 100% !important;
}
.smart-style-1 .NextconfirmLabels {
  color: #ffffff;
  border: 1px solid #333333;
}
.smart-style-1 .NextconfirmLabel {
  border: 1px solid #333333;
}
.smart-style-1 body {
  background-color: #1E1E1E !important;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #000 !important;
}
.smart-style-1 .breadcrumb > li + li:before {
  color: #000;
}
.smart-style-1 .auth-text-color {
  color: #0D0F12 !important;
}
.smart-style-1 #left-panel nav ul li {
  border-bottom: 1px solid #8B9635;
}
.smart-style-1 #left-panel nav ul li:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 1px solid #3B3B3B33;
  border-bottom: 1px solid #3B3B3B33 !important;
}
.smart-style-1 #left-panel nav ul li:hover a {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li:hover ul li a {
  color: #ffffff !important;
}
.smart-style-1 #left-panel nav ul li:hover ul li:hover {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li:hover ul li:hover a {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li.active {
  background-color: #8B9635 !important;
}
.smart-style-1 #left-panel nav ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li:hover a {
  color: #202427 !important;
  background: transparent !important;
}
.smart-style-1 #left-panel nav ul li.active ul li a {
  color: #ffffff !important;
  font-weight: 400 !important;
}
.smart-style-1 #left-panel nav ul li.active ul li.active a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open:hover a b {
  color: #000 !important;
}
.smart-style-1 #left-panel nav ul li.open li:hover a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open a b {
  color: #ffffff !important;
}
.smart-style-1 #left-panel nav ul li.open li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open li.open:hover > a {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open li.open:hover > a b {
  color: #202427 !important;
}
.smart-style-1 #left-panel nav ul li.open.active a b {
  color: #202427 !important;
}
.smart-style-1 nav ul ul li a {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  font-size: 14px !important;
}
.smart-style-1 #ribbon .breadcrumb li {
  color: #8B91A0;
}
.smart-style-1 #ribbon .breadcrumb li:last-child {
  color: #ffffff !important;
}
.smart-style-1 .theme-header-text-color {
  color: #ffffff !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .outerMainDiv {
  background: #202124 !important;
}
.smart-style-1 .dashabord .jarviswidget div {
  background-color: #0C1214 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .theme-icon {
  color: #ffffff !important;
}
.smart-style-1 .theme-text-color {
  color: #E2E2E2 !important;
}
.smart-style-1 .ajax-dropdown {
  border: 1px solid #313840 !important;
}
.smart-style-1 .notification-body li {
  border: 1px solid #252b31 !important;
}
.smart-style-1 .dropdown-menu {
  background-color: #0C1214;
  border: 1px solid #333333;
}
.smart-style-1 .dropdown-menu li a {
  color: #ffffff;
}
.smart-style-1 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #1E1E1E !important;
}
.smart-style-1 .header-dropdown-list .dropdown-menu .active a {
  background-color: #1E1E1E !important;
}
.smart-style-1 .applicationHead {
  background: #202124;
  color: #ffffff;
}
.smart-style-1 #fullscreen .svg-icon {
  background-image: url('Path 222.737b381f1c9fbc85a137.svg');
}
.smart-style-1 #header .notification-svg {
  background-image: url('39-Notification.4feddd112452285fe534.svg');
}
.smart-style-1 #header #activity.activity-svg {
  background-image: url('Page-1.9f2dc138f1ca7885f995.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.c485c714dd85f9b4f52d.svg');
}
.smart-style-1 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  cursor: pointer;
}
.smart-style-1 #header .collapseMenu:hover {
  background-color: #0D0F12;
}
.smart-style-1 #header .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 #header .header-btn-list a {
  background-color: #1E1E1E !important;
}
.smart-style-1 #header .menu-item:hover {
  background-color: #333333;
}
.smart-style-1 #header .theme-text-color {
  color: #ffffff !important;
}
.smart-style-1 #header .menu-item.no-hover:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header .menu-left-item {
  border-left: unset !important;
}
.smart-style-1 .jarviswidget-color-darken header {
  background: #171819 !important;
}
.smart-style-1 .upgradebtn {
  color: #ffffff !important;
}
.smart-style-1 .spanBoderBottom {
  border-bottom: 1px solid #333333 !important;
}
.smart-style-1 .modalBodyBgColor {
  background-color: #0C1214 !important;
}
.smart-style-1 .applicationHeadModal {
  background: #0D0F12 !important;
}
.smart-style-1 .theme-fade-color {
  color: #959595 !important;
}
.smart-style-1 .subList:not(:last-child) {
  border-bottom: 1px solid #202124 !important;
}
.smart-style-1 .border {
  border: 1px solid #333333 !important;
  border-radius: 10px;
}
.smart-style-1 .userProfile .applicationBackGround {
  background-color: #202124 !important;
  padding: 0;
}
.smart-style-1 .menuSelected {
  background-color: #333333;
}
.smart-style-1 .border-line {
  border: none;
  background-color: #202124 !important;
}
.smart-style-1 .account-table td {
  color: #ffffff !important;
}
.smart-style-1 .account-table th {
  color: #ffffff !important;
  background-color: #1E1E1E !important;
}
.smart-style-1 table.account-table {
  margin-bottom: 0px !important;
}
.smart-style-1 .account-table {
  border-color: #333333 !important;
}
.smart-style-1 .account-table th {
  background-color: #0d0f12 !important;
  border-color: #333333 !important;
  border-top: none;
  border-bottom: none;
  text-align: left;
  font: normal normal bold 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  color: #ffffff !important;
  padding-left: 24px !important;
}
.smart-style-1 .account-table tr:nth-child(odd) {
  background-color: #0d0f12 !important;
}
.smart-style-1 .account-table td {
  border-color: #333333 !important;
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #E2E2E2;
  color: #ffffff !important;
  padding-left: 24px !important;
}
.smart-style-1 .account-table .history_action {
  text-decoration: underline !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer {
  background-color: #0d0f12 !important;
  border: 1px solid #333333 !important;
  margin-top: -6px;
  box-shadow: none !important;
  width: 100% !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .dataTables_info {
  text-align: left !important;
  font: italic normal 600 13px/18px Open Sans !important;
  letter-spacing: 0px !important;
  color: #959595 !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .pagination .paginate_button a {
  text-align: left !important;
  font: normal normal normal 11px/15px Open Sans !important;
  letter-spacing: 0px !important;
  color: #000 !important;
  background: #0d0f12 !important;
  border: 1px solid #3B424C !important;
}
.smart-style-1 .account-table-div .dt-toolbar-footer .pagination .paginate_button.active a {
  background-color: #E2E2E2 !important;
  color: #171717 !important;
}
.smart-style-1 nav ul li a {
  color: #ffffff;
  font-size: 16px !important;
}
.smart-style-1 nav ul li a:focus, .smart-style-1 nav ul li a:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .body-image {
  border-radius: 100%;
  height: 200px;
}
.smart-style-1 .table tbody tr td {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #333333;
  border-color: #333333;
}
.smart-style-1 .lstTrncnTbl thead tr th {
  background-color: #1E1E1E !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .lstTrncnTbl tbody tr.odd {
  color: #ffffff;
  /*background-color: $table-backgroundcolor;*/
}
.smart-style-1 .lstTrncnTbl tbody tr.even {
  color: #ffffff;
  /*background-color: map.get($darktheme-colors, "bgcolor");*/
  border-bottom: 1px solid #707070 !important;
}
.smart-style-1 .lstTrncnTbl tbody td a {
  color: #8B9635;
}
.smart-style-1 .lstTrncnTbl .table-bordered {
  border-color: #707070 !important;
}
.smart-style-1 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-1 .emptyCol {
  border-top-right-radius: 0px !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  background-color: #202124 !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-1 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .currentButton {
  background-color: #404040 !important;
  color: #666666 !important;
  border-color: #333333 !important;
  cursor: not-allowed;
}
.smart-style-1 .currentButtonent {
  border-color: #333333 !important;
  background-color: #404040 !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .pagination > .active > a {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
  color: #000 !important;
  border-color: #333333;
}
.smart-style-1 .pagination > li > a {
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:first-child > a,
.smart-style-1 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: transparent !important;
  color: #ffffff;
  border-color: #333333;
}
.smart-style-1 .pagination > li:last-child > a,
.smart-style-1 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent !important;
  border-color: #333333;
}
.smart-style-1 .selectButton {
  border: 2px solid #8B9635;
  border-radius: 15px;
  padding: 3px 15px;
  background-color: #202124;
  border-color: #8B9635;
  font-size: 14px;
  outline: none;
}
.smart-style-1 .selectButton:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .cancel {
  text-align: left;
  width: auto;
  border-radius: 15px;
  background: #202124 0% 0% no-repeat padding-box !important;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
}
.smart-style-1 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.smart-style-1 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-1 .lightBackgroundColor {
  border-radius: 10px;
  background-color: #202124 !important;
  border: 1px solid #333333 !important;
  margin-left: 13px;
}
.smart-style-1 .left-div {
  border-radius: 10px;
  background-color: #202124 !important;
}
.smart-style-1 .card-div {
  border-radius: 5px;
  background-color: #202124 !important;
  border: 1px solid #333333 !important;
  padding-bottom: 0px;
}
.smart-style-1 .card-div:hover {
  background-color: #171819 !important;
}
.smart-style-1 .pricingTable table {
  color: #ffffff !important;
}
.smart-style-1 .theme-bg-color {
  background-color: #202124 !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .jarviswidget > div {
  background: transparent !important;
  border: none;
}
.smart-style-1 .bootstrapWizard li.active .title,
.smart-style-1 .bootstrapWizard li.complete .title {
  color: #ffffff;
}
.smart-style-1 .bootstrapWizard li .step {
  background-color: #202124;
  border: 1px solid #333333;
  color: #EEE;
}
.smart-style-1 .headData {
  background-color: #202124;
  color: #ffffff;
  margin-top: 10px;
  font-size: 14px;
}
.smart-style-1 .modal-body {
  background-color: #171717 !important;
}
.smart-style-1 .modal-content {
  background-color: #171717 !important;
  border: 1px solid #333333;
  color: #ffffff;
  border-radius: 10px;
}
.smart-style-1 .modal-footer {
  padding: 20px 0px 0px 0px;
  border-top: none !important;
}
.smart-style-1 .back-btn {
  background: #202124 0% 0% no-repeat padding-box;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  border-radius: 20px;
}
.smart-style-1 .jarviswidget-color-darken > header {
  background: none !important;
  border: none;
}
.smart-style-1 .theme-content-color {
  color: #FFFFFF;
}
.smart-style-1 .theme-button-color {
  background-color: #202124;
  color: #ffffff !important;
}
.smart-style-1 .tranhoverTable select {
  background-color: inherit;
  color: inherit;
  border: 1px solid #333333;
  border-radius: 5px;
}
.smart-style-1 .tranhoverTable select option {
  background: #202528 0% 0% no-repeat padding-box;
  color: inherit;
}
.smart-style-1 .tranhoverTable input[type=radio]:checked:after {
  border: 2px solid #666666;
}
.smart-style-1 .contact_infrmtn_sctn .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #171819 !important;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .comapny_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background: #171819 !important;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .editEnabled .detailLbl {
  color: #ffffff !important;
  font-size: 14px !important;
}
.smart-style-1 .editEnabled .detailLbl .commoninputStyle .form-control {
  background-color: #202124 !important;
}
.smart-style-1 .detail_save_btn,
.smart-style-1 .detail_save_Btn,
.smart-style-1 .detailInfo_save_btn,
.smart-style-1 .approveBtn,
.smart-style-1 .gotoEdi,
.smart-style-1 .iom_btn {
  color: #ffffff;
}
.smart-style-1 .editDisabled {
  background: none;
}
.smart-style-1 .gridSectionFirst .payMethodSection {
  border: 1px solid #666666;
}
.smart-style-1 .gridSectionFirst .payMethodSection .payHead span {
  color: #ffffff;
}
.smart-style-1 .gridSectionFirst .techContactSection {
  border: 1px solid #666666;
}
.smart-style-1 .gridSectionFirst .techContactSection .techHead {
  color: #ffffff;
}
.smart-style-1 .technical_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .technical_details_form .detailLbl .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .gridSectionTwo .billingContactSection {
  border: 1px solid #666666;
}
.smart-style-1 .gridSectionTwo .billingContactSection .billHead {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}
.smart-style-1 .billing_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .billing_details_form .detailLbl .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #171819 !important;
  border: 1px solid #171819;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .gridSectionThree .estimatedBillSection {
  padding: 10px 30px;
  border: 1px solid #666666;
  margin: 20px 0px 20px 20px;
  width: 96%;
  border-radius: 10px;
  height: 584px;
}
.smart-style-1 .gridSectionThree .estimatedBillSection hr {
  border-top: 1px solid #666666 !important;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .estBillHead {
  font-size: 16px;
  color: #ffffff;
  font-weight: 600;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .billItemDesc {
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .gridSectionThree .estimatedBillSection .billItemPrice {
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .edit_details_form .detailLbl {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .edit_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #666666;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .underline {
  border-color: #666666;
}
.smart-style-1 .subscription-sum {
  border-top: 2px solid #32383e !important;
}
.smart-style-1 .card-fields .form-fields-wrapper {
  border-bottom: 1px solid #d5e1ec;
}
.smart-style-1 .card-fields .form-fields-wrapper .icon {
  width: 25px;
  height: auto;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input {
  outline: none;
  border: none;
  width: 100%;
  line-height: 50px;
  padding: 0;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 20px;
  background: transparent;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input::-moz-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input:-ms-input-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-1 .card-fields .form-fields-wrapper .form-fields input :-moz-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-1 .card-fields .checkbox {
  color: #005397;
}
.smart-style-1 .card-fields .card-errors {
  color: #f34646;
  font-size: 10px;
  margin: 10px 0 0 0;
}
.smart-style-1 .ElementsApp,
.smart-style-1 .ElementsApp .InputElement {
  color: #ffffff !important;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-1 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #171717;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-1 .nav ul .active > a {
  color: #ffffff !important;
  font-weight: 700;
}
.smart-style-1 .ElementsApp,
.smart-style-1 .ElementsApp .InputElement {
  color: #ffffff;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-1 .sub-btn {
  border: 2px solid #8B9635 !important;
  color: #ffffff !important;
}
.smart-style-1 .sub-btn:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .manage-btn {
  font-size: 13px;
  border: 2px solid #333333 !important;
  border-radius: 15px;
  background-color: #202528 !important;
  color: #ffffff !important;
  padding: 3px 15px;
}
.smart-style-1 .manage-btn:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 2px solid #8B9635 !important;
}
.smart-style-1 .ganerateButton {
  background: #171819 !important;
  color: #ffffff;
}
.smart-style-1 .stepsChecked {
  font-weight: 900;
  background-color: #333333;
}
.smart-style-1 .next_payment {
  background-color: #171819 !important;
  color: #ffffff;
}
.smart-style-1 .userEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
  color: #ffffff;
}
.smart-style-1 .nameDiv,
.smart-style-1 .headDiv {
  color: #ffffff;
}
.smart-style-1 .selectExpiry .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #666666;
  font-size: 14px;
  color: #ffffff;
}
.smart-style-1 .selectExpiry span.ng-star-inserted {
  visibility: hidden;
}
.smart-style-1 .form-control.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.smart-style-1 .profileEditTextBox {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .profileModalBtn {
  padding: 10px;
  background-color: transparent !important;
  border: 2px solid #8B9635 !important;
  color: #ffffff !important;
  border-radius: 19px;
}
.smart-style-1 .profileModalBtn:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .sub-img {
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 .row-divider {
  border-color: #333333 !important;
}
.smart-style-1 .dot-div {
  color: #FFFFFF !important;
}
.smart-style-1 .table-bordered > tbody > tr > td {
  border: 1px solid #333333;
}
.smart-style-1 .subscriptions-page .table-bordered > thead > tr > th:not(:first-child) {
  border: 1px solid #333333;
}
.smart-style-1 .subscriptions-page table.noFeature.table-bordered > thead > tr > th {
  border: 1px solid #333333;
}
.smart-style-1 .table-bordered > tbody.headData > tr > td.switchCol,
.smart-style-1 .table-bordered > thead.headData > tr > th.switchCol,
.smart-style-1 .table-bordered > tbody.table-headData > tr > td.switchCol {
  box-shadow: -1px 0 0 0 #333333;
}
.smart-style-1 .table-bordered > tbody.headData > tr > td:first-child.switchCol,
.smart-style-1 .table-bordered > tbody.table-headData > tr > td:first-child.switchCol {
  box-shadow: 0 -1px 0 0 #333333;
}
.smart-style-1 .emptyCol {
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  background-color: #202124 !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
  background: #202124 !important;
}
.smart-style-1 .currentPlan {
  background-color: #CCCCCC !important;
  color: #202528 !important;
}
.smart-style-1 .currentPlan:hover {
  background: #CCCCCC !important;
}
.smart-style-1 .model-7 .checkbox label:after {
  background: #000 !important;
}
.smart-style-1 .cancel {
  text-align: left;
  width: auto;
  border-radius: 15px;
  background: #202124 0% 0% no-repeat padding-box !important;
  border: 2px solid #8B9635 !important;
  font-size: 13px;
  padding: 3px 15px;
}
.smart-style-1 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.smart-style-1 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-1 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-1 .lightBackgroundColor {
  border-radius: 5px;
  background-color: #202124 !important;
  border: 1px solid #333333;
  margin-left: 13px;
}
.smart-style-1 .pricingTable table {
  color: #ffffff !important;
}
.smart-style-1 .theme-bg-color {
  background-color: #202124 !important;
}
.smart-style-1 .theme-subtext-color {
  color: #959595 !important;
}
.smart-style-1 #content {
  background: #202124 !important;
}
.smart-style-1 .jarviswidget > div {
  border: none;
}
.smart-style-1 .bootstrapWizard li.active .title,
.smart-style-1 .bootstrapWizard li.complete .title {
  color: #ffffff;
}
.smart-style-1 .bootstrapWizard li .step {
  background-color: #202124;
  border: 1px solid #333333;
  color: #eee;
}
.smart-style-1 .textColor {
  color: #ffffff !important;
}
.smart-style-1 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-1 .jarviswidget-color-darken > header {
  background: none !important;
}
.smart-style-1 .yearlabel {
  border: 1px solid #666666;
  background-color: #202124;
}
.smart-style-1 .cancellabel {
  border: 1px solid #202124;
  background-color: #202124;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #ffffff !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: #8B9635 !important;
  border: 1px solid #8B9635 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li a:hover span {
  color: #000 !important;
}
.smart-style-1 .buy-addon .navigationList .nav.nav-tabs li.active a span {
  color: #000 !important;
}
.smart-style-1 .buy-addon .checkout {
  background-color: #202528;
}
.smart-style-1 .table-headData {
  background-color: #202124;
  color: #ffffff;
  margin-top: 10px;
}
.smart-style-1 .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #202124;
  border: 1px solid #666666;
  font-size: 14px;
  color: #ffffff !important;
}
.smart-style-1 .child .dtr-details .dtr-title {
  color: #E2E2E2 !important;
}
.smart-style-1 .child .dtr-details .dtr-data {
  color: #E2E2E2 !important;
}
.smart-style-1 .cart-btn {
  background-color: #ffffff;
}
.smart-style-1 .cart-btn .fas {
  color: #202528;
}
.smart-style-1 .blacktooltip {
  background: #202124 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .blacktooltip:hover {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .blacktooltip .insideDivUl li {
  border-bottom: 1px solid #333333;
}
.smart-style-1 .blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-1 .error-blacktooltip {
  background: #202124 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .error-blacktooltip:hover {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .error-blacktooltip .insideDivUl li {
  border-bottom: 1px solid #333333;
}
.smart-style-1 .error-blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-1 .optionSelect {
  background-color: #202124 !important;
  color: #FFFFFF !important;
}
.smart-style-1 .table {
  width: 100%;
  color: #ffffff;
  border-collapse: collapse;
  background-color: #666666;
  border-color: #333333;
  border-radius: 5px;
}
.smart-style-1 .subscriptions-page .table {
  border-color: #202124 !important;
}
.smart-style-1 .subscriptions-page .table thead tr {
  background: #202124 !important;
}
.smart-style-1 .subscriptions-page td {
  background-color: #202124;
}
.smart-style-1 .history-table th {
  border-color: #333333 !important;
  background-color: #404040 !important;
  color: #ffffff;
}
.smart-style-1 .commonHead {
  background-color: #202124 !important;
  color: #E2E2E2;
  font-size: 14px;
  border: unset !important;
}
.smart-style-1 .algntblTranscation {
  background: #202124 !important;
}
.smart-style-1 .historyLabel {
  color: #ffffff;
}
.smart-style-1 table.history-table {
  margin-top: 0px !important;
  border-radius: 8px !important;
}
.smart-style-1 table.history-table tr:hover {
  background-color: #404040 !important;
  color: #ffffff;
}
.smart-style-1 .history-table .history_action {
  text-decoration: underline !important;
}
.smart-style-1 .table-bordereds > tbody > tr > td {
  border: 1px solid #333333;
  z-index: 10px;
}
.smart-style-1 .owl-dt-calendar-control {
  color: #ffffff !important;
  border: 1px solid #333333 !important;
  background: #202124;
  margin-top: 11px;
}
.smart-style-1 .owl-dt-container {
  background: #202124 !important;
  border: #202124 !important;
  border-radius: 10px !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-header {
  color: #fff !important;
}
.smart-style-1 .owl-dt-calendar-table .owl-dt-calendar-cell {
  color: #fff !important;
}
.smart-style-1 .timeZoneBtn {
  color: #000 !important;
}
.smart-style-1 .gradientDiv {
  background-color: #171819 !important;
  border: 1px solid #ccc;
}
.smart-style-1 .gradientDiv:hover {
  background-color: #171819 !important;
}
.smart-style-1 .hoverBtn {
  background: #171819 !important;
}
.smart-style-1 .dataTables_processing {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 table.pending-list-table {
  margin-top: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 .table thead tr th {
  border-color: #333333;
}
.smart-style-1 .table tbody tr td {
  border-color: #333333;
}
.smart-style-1 .history-btn {
  color: #ffffff;
}
.smart-style-1 .history-btn:hover {
  background-color: #8B9635 !important;
  color: #000;
}
.smart-style-1 .list-area {
  background-color: #0D0F12;
  border-radius: 0px;
}
.smart-style-1 .serviceTheme {
  background-color: #171717 !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar {
  background-color: #171717 !important;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  display: flex;
  justify-content: normal;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute;
  top: 25px;
  right: 5px;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h5 {
  color: #ffffff !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar h1 {
  color: #ffffff !important;
}
.smart-style-1 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: #8B9635;
  opacity: 1;
}
.smart-style-1 .serviceTheme .ui-dialog-content {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #171717 !important;
}
.smart-style-1 .serviceTheme .chip_Section {
  border: 1px solid #1B1E24 !important;
  background-color: #7c7b7b !important;
  color: #ffffff !important;
  margin-left: 0px !important;
}
.smart-style-1 .serviceTheme .toggleMore {
  background-color: #707070 !important;
}
.smart-style-1 .serviceTheme .serachService {
  background-color: #dadce0 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect {
  background-color: #171717 !important;
  box-shadow: 0 1px 5px #171717 !important;
  border: 1px solid #707070 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect .filter-textbox {
  border-bottom: 1px solid #707070 !important;
}
.smart-style-1 .dropdown-list.fixMultiSelect .item1 .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-1 .user-role-form .multiselect-item-checkbox input[type=checkbox] + div {
  color: #ffffff !important;
}
.smart-style-1 .user-role-form .item1 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .user-role-form .item1 .multiselect-item-checkbox:hover input[type=checkbox] + div {
  color: #111 !important;
}
.smart-style-1 .user-role-form .item2 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .user-role-form .multiselect-dropdown .dropdown-btn {
  border: unset !important;
  padding: 6px 0px !important;
}
.smart-style-1 .price_calcultation_form .dropdown-list {
  background-color: #202124 !important;
  box-shadow: 0 1px 5px #171717 !important;
  border: 1px solid #333 !important;
}
.smart-style-1 .price_calcultation_form .multiselect-item-checkbox input[type=checkbox] + div {
  color: #ffffff !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .item1 .multiselect-item-checkbox:hover input[type=checkbox] + div {
  color: #111 !important;
}
.smart-style-1 .price_calcultation_form .item2 .multiselect-item-checkbox:hover {
  background-color: #7c7b7b !important;
}
.smart-style-1 .price_calcultation_form .multiselect-dropdown .dropdown-btn {
  border: 1px solid #333 !important;
}
.smart-style-1 .reMark {
  color: #ffffff;
  border: 1px solid #666666;
}
.smart-style-1 .spin-loader {
  background: url('spin-loader_small_white.797d455d611e7389cd3b.svg') no-repeat !important;
}
.smart-style-1 .big-spin-loader {
  background: url('spin-loader_big_white.3c7bb61bb066252fe699.svg') no-repeat !important;
}
.smart-style-1 .btnModalSave {
  float: right;
  background-color: #202124;
  border: none;
  color: #ffffff !important;
  border-radius: 15px;
  border: 2px solid #8B9635;
}
.smart-style-1 .btnModalSave:hover {
  color: #000 !important;
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  color: #ffffff !important;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-1 .btnModalClose:hover {
  color: #000 !important;
}
.smart-style-1 .successDiv {
  background-color: #171819;
}
.smart-style-1 .successinviteDiv {
  background-color: #171819;
}
.smart-style-1 .successappDiv {
  background-color: #171819;
}
.smart-style-1 .successcmpimgDiv {
  background-color: #171819;
}
.smart-style-1 .successcmpprofileimgDiv {
  background-color: #171819;
}
.smart-style-1 .successuserdeleteDiv {
  background-color: #171819;
}
.smart-style-1 .disabledColor {
  background-color: #404040 !important;
  color: #666666 !important;
  border-color: #666666 !important;
}
.smart-style-1 .inv-img {
  content: url('invoice_orange.449323bf104736ae3fe4.svg') !important;
}
.smart-style-1 .badge-disable-iom {
  color: #ffffff !important;
}
.smart-style-1 .checkmark {
  border: 2px solid #ffffff;
}
.smart-style-1 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-1 .vertical-line {
  background-color: #666666;
}
.smart-style-1 .card_details_nocard p {
  color: #ffffff;
  font-size: 14px;
}
.smart-style-1 .subPlanData {
  color: #ffffff;
}
.smart-style-1 .table-wrapper {
  background-color: #404040 !important;
}
.smart-style-1 .table-wrapper table tbody tr td {
  background-color: #202124 !important;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole {
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole:hover {
  background: #202124 0% 0% no-repeat padding-box !important;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole .insideDivUl li {
  border-bottom: 1px solid #333333;
}
.smart-style-1 .tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-1 .tooltipRole .tooltiptextRole {
  background-color: #202124;
  color: #ffffff;
}
.smart-style-1 .cardconfirmLabel {
  color: #E2E2E2 !important;
  border: 1px solid #333333;
}
.smart-style-1 .cardconfirmLabel .header-formbtn {
  background-color: unset !important;
  border: 2px solid #8B9635 !important;
  color: #ffffff !important;
  padding: 3px 15px;
}
.smart-style-1 .cardconfirmLabel .header-formbtn:hover {
  background: #8B9635 !important;
  color: #202528 !important;
}
.smart-style-1 .MessageBoxButtonSection button {
  color: #ffffff;
}
.smart-style-1 .manageHeader,
.smart-style-1 .comapnyHeaderLbl {
  color: #ffffff !important;
}
.smart-style-1 .top-div {
  border-bottom: 1px solid #333333 !important;
}
.smart-style-1 .top-div .description-div .description-content {
  border: 1px solid #333333 !important;
}
.smart-style-1 .step-div {
  background: #202124;
}
.smart-style-1 .footer-div {
  background: #202124;
}
.smart-style-1 .fieldForm .form-control {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .algntblTranscation {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124 !important;
}
.smart-style-1 .setselectHeight {
  background-color: #202124;
  color: #ffffff !important;
  border-color: #666666;
}
.smart-style-1 .backtopagewrapper {
  color: #ffffff;
}
.smart-style-1 .successdeleteDiv {
  background-color: #171819;
}
.smart-style-1 .btnModalClose {
  float: left;
  background-color: #202124;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-1 .search_status {
  background-color: #202124 !important;
  color: #ffffff;
}
.smart-style-1 .user-list-select {
  background-color: #202124 !important;
  color: #ffffff;
}
.smart-style-1 .select_search_status {
  background-color: #171717 !important;
  color: #ffffff;
}
.smart-style-1 .drop {
  color: #fff !important;
}
.smart-style-1 .ui-datepicker {
  background: #202124 !important;
  border-color: #666666 !important;
  z-index: 2000 !important;
}
.smart-style-1 .ui-datepicker .ui-datepicker-header {
  background-color: #202124;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-month {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker select.ui-datepicker-year {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker th {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker td .ui-state-active {
  background: #707070;
}
.smart-style-1 .ui-datepicker-header a {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-month {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-year {
  color: #ffffff !important;
}
.smart-style-1 .ui-datepicker-prev:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker-next:before {
  background: #202124 !important;
}
.smart-style-1 .ui-datepicker td .ui-state-default {
  color: #ffffff;
}
.smart-style-1 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-1 .iconForm {
  color: #ffffff;
}
.smart-style-1 .filter-top {
  color: #ffffff !important;
}
.smart-style-1 table.positionTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #666666 !important;
  border-radius: 5px;
}
.smart-style-1 table.bulkTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #666666 !important;
  border-radius: 5px;
}
.smart-style-1 .headerContents {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  overflow: hidden;
  background: #202124;
}
.smart-style-1 .headerfilterContents {
  border-radius: 8px;
  border: 1px solid #333333 !important;
  background: #202124;
}
.smart-style-1 .tableHeadBGclr {
  background: #1E1E1E !important;
}
.smart-style-1 .headClr {
  color: #fff !important;
}
.smart-style-1 .CodeMirror {
  background: lightgrey;
}
.smart-style-1 .amount-div {
  background: #7c7b7b;
  color: #fff;
}
.smart-style-1 .invoiceOverview .nav-tabs li {
  border-bottom: 1px solid #333333 !important;
}
.smart-style-1 .invoiceOverview .nav-tabs > li.active {
  border-top: 1px solid #333333 !important;
}
.smart-style-1 .invoiceOverview .nav-tabs > li.active a,
.smart-style-1 .invoiceOverview .nav-tabs > li.active a:hover,
.smart-style-1 .invoiceOverview .nav-tabs > li.active a:focus {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #333333;
  border-bottom-color: transparent;
}
.smart-style-1 .invoiceOverview .nav-tabs li a:hover {
  background-color: transparent !important;
  border: none;
}
.smart-style-1 .headData {
  background: #1E1E1E 0% 0% no-repeat padding-box;
  color: #fff;
  border: 1px solid #333333;
  margin-top: 10px;
}
.smart-style-1 .idTooltip,
.smart-style-1 .btnTooltip,
.smart-style-1 .errorTooltip,
.smart-style-1 .companyTooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-1 .advace-header-formbtn {
  background-color: unset !important;
  border: 2px solid #8B9635 !important;
  color: #ffffff !important;
}
.smart-style-1 .advace-header-formbtn:hover {
  background: #8B9635 !important;
  color: #202528 !important;
}
.smart-style-1 .error_text {
  color: #ffffff !important;
}
.smart-style-1 .tranhoverTable tbody tr:hover {
  background-color: #7c7b7b !important;
  cursor: pointer;
}
.smart-style-1 .bulkhoverTable tbody tr:hover {
  background-color: #8B9635 !important;
  cursor: pointer;
  color: #000 !important;
}
.smart-style-1 #header.colorChangeTest .header-btn {
  background-color: transparent;
  box-shadow: none;
}
.smart-style-1 #header.colorChangeTest .header-btn:hover {
  background-color: #1E1E1E;
}
.smart-style-1 #header.colorChangeTest .dropdown-icon-menu:hover .header-btn {
  background-color: #1E1E1E;
}
.smart-style-1 nav ul ul li a {
  color: #ffffff;
  font-size: 16px !important;
  text-shadow: none !important;
  font-weight: 400;
}
.smart-style-1 nav ul ul li a:focus, .smart-style-1 nav ul ul li a:hover {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-1 .filterSection .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #ffffff !important;
  font-size: 14px;
}
.smart-style-1 .filterSection .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #ffffff transparent transparent !important;
}
.smart-style-1 .filterSection .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #ffffff !important;
}
.smart-style-1 .disableForm .panelSector .ng-select .ng-select-container {
  border: 1px solid #333333;
}
.smart-style-1 .disableForm .panelSector .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #ffffff !important;
  font-size: 14px;
}
.smart-style-1 .disableForm .panelSector .ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: #ffffff transparent transparent !important;
}
.smart-style-1 .disableForm .panelSector .ng-select .requiredSection .ng-select-container {
  border: 1px solid #dc3545;
}
.smart-style-1 .disableForm .panelSector .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: transparent transparent #ffffff !important;
}
.smart-style-1 .card-header {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .accordianClass {
  color: #FFFFFF;
}
.smart-style-1 #wizard {
  background-color: !important;
  color: #FFFFFF;
}
.smart-style-1 #wizard .line-1 {
  background: #333333;
}
.smart-style-1 #wizard .wizard-items {
  border: 1px solid #3B424C;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items:hover {
  background: #171819 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-items .wizardDiv {
  border: 1px solid #404040;
  background: #404040 0% 0% no-repeat padding-box;
}
.smart-style-1 #wizard b.badges {
  border: 1px solid #202124;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}
.smart-style-1 #wizard .wizard-billeritem {
  border: 1px solid #3B424C;
  background: #202124 0% 0% no-repeat padding-box;
}
.smart-style-1 .docLbl {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
.smart-style-1 .form-control {
  color: #fff !important;
  background-color: #202124 !important;
  border: 1px solid #333333;
}
.smart-style-1 .docInput {
  width: 97%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #0C1214 !important;
  border: unset !important;
}
.smart-style-1 .comInput {
  width: 100%;
  border-radius: 10px !important;
  background-color: #0C1214 !important;
  border: unset !important;
}
.smart-style-1 .common_wizardItemContainer .wizard_format {
  width: 50% !important;
}
.smart-style-1 .card-header {
  background-color: rgba(0, 0, 0, 0.5);
}
.smart-style-1 .card {
  background-color: #202124;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.smart-style-1 #wizard .line-1 {
  background: #333333 !important;
}
.smart-style-1 .ebillFormFormly label,
.smart-style-1 .disableForm label,
.smart-style-1 .ebillForm label {
  color: #fff !important;
}
.smart-style-1 .ebillFormFormly .form-control,
.smart-style-1 .disableForm .form-control,
.smart-style-1 .ebillForm .form-control {
  border: 1px solid #333333;
  color: #fff;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 13px;
}
.smart-style-1 .billTabSection .stepsinout-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .billTabSection {
  border: 1px solid #333333;
}
.smart-style-1 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #fff !important;
  border-bottom: unset !important;
  border-left-color: #333333 !important;
  border-right-color: #333333 !important;
  border-top-color: #333333 !important;
}
.smart-style-1 .btn-primary {
  background: transparent !important;
  border: 2px solid #8B9635;
  color: #ffffff !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-1 .btn-primary:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 2px solid #8B9635;
}
.smart-style-1 table.positionTable {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 table.bulkTable {
  margin-top: 1px !important;
  margin-bottom: 0px !important;
  border: 1px solid #333333 !important;
  border-radius: 5px;
}
.smart-style-1 #wizard .wizard-companyitem {
  border: 1px solid #3B424C;
  background: #202124 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.smart-style-1 .ebillForm .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #111 !important;
  border: 1px solid #333333;
}
.smart-style-1 .ebillForm .panelSector .ng-select .ng-select-container .ng-input {
  color: #fff !important;
}
.smart-style-1 .commision-agent .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #fff !important;
  border: 1px solid #333333 !important;
}
.smart-style-1 .emailMessage {
  border: 1px solid #3b424c !important;
}
.smart-style-1 .errorOccured {
  border-color: #dc3545 !important;
}
.smart-style-1 .ng-dropdown-panel.ng-select-bottom {
  border: 1px solid #333333 !important;
}
.smart-style-1 .nav_btn {
  color: #666 !important;
}
.smart-style-1 .navFooter .nav_btn {
  color: #fff !important;
}
.smart-style-1 .navFooter .nav_btn:hover {
  color: #000 !important;
}
.smart-style-1 .stepsinout-form .input-group-append .input-group-text,
.smart-style-1 .businessconnect-form .input-group-append .input-group-text {
  background-color: #0C1214;
  color: #ffffff;
  border: 1px solid #0C1214;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.smart-style-1 .stepsinout-form .input-group-append .input-group-text::before,
.smart-style-1 .businessconnect-form .input-group-append .input-group-text::before {
  border-left: 1px solid #ffffff;
}
.smart-style-1 .stepsinout-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .billTabSection .stepsinout-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .fieldForm .form-control {
  color: #ffffff !important;
  background-color: #202124 !important;
  border-color: #333333;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .defaultDot {
  background-color: white;
}
.smart-style-1 .businessconnect-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .statusSection tr td:not(:last-child) {
  border-right: 2px solid #fff;
}
.smart-style-1 .company-select .ng-select-container {
  color: #fff;
  height: 42px !important;
  border: none;
}
.smart-style-1 .invoice-tab-header {
  border: 1px solid #333333 !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  background-color: #202124 !important;
  color: #fff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li {
  border: 1px solid #333333;
  border-top: none !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li:hover {
  border: 1px solid #7c7b7b !important;
  border-top: none !important;
  background-color: #7c7b7b !important;
  box-shadow: 0 -2px 0 #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a.invoice-tabhead {
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:hover {
  color: #ffffff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li > a:focus {
  color: #ffffff !important;
  background-color: #7c7b7b !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active {
  border: 1px solid #8B9635 !important;
  box-shadow: 0 -2px 0 #8B9635 !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #202124 !important;
  color: #ffffff !important;
}
.smart-style-1 .commonBorderColor {
  border: 1px solid #333333 !important;
}
.smart-style-1 .formBorderColor {
  border: 1px solid #333333 !important;
}
.smart-style-1 .borderChangeColor {
  border-color: #333333 !important;
}
.smart-style-1 .setwhiteFont {
  color: #ffffff;
}
.smart-style-1 .dataTable tbody .shown + tr > td > table > tbody > tr:last-child {
  border-bottom: 1px solid #333333;
}
.smart-style-1 .additional-details {
  border: 1px solid #333333;
}
.smart-style-1 .devOptions {
  background-color: #171819;
  color: #fff;
}
.smart-style-1 .devOptions textarea {
  background-color: #171819;
}
.smart-style-1 .itemEditButton {
  background-color: #202124;
  color: #ffffff !important;
}
.smart-style-1 .itemEditButton:hover {
  background: #8B9635 !important;
  color: #0C1214 !important;
}
.smart-style-1 .graphDataLabel {
  color: #fff;
}
.smart-style-1 .tooltipA .tooltiptext {
  background: #171717 0% 0% no-repeat padding-box;
  color: #E2E2E2;
  border: 1px solid #333333;
}
.smart-style-1 .notPaidHead,
.smart-style-1 .notPaidHead span {
  color: #E2E2E2 !important;
}
.smart-style-1 .successDiv-status {
  background-color: #0D0F12;
}
.smart-style-1 .bs-datepicker {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  background-color: #202124 !important;
  box-shadow: none;
  position: relative;
  z-index: 1;
  color: #fff;
}
.smart-style-1 .bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-width: 293px;
  border: 1px solid #333333;
}
.smart-style-1 .bs-datepicker-body table td {
  color: #fff;
  text-align: center;
  position: relative;
  padding: 0;
}
.smart-style-1 .tranheader {
  border: 1px solid #333333 !important;
  background: transparent !important;
  border-radius: 8px;
}
.smart-style-1 select:not([multiple]) option {
  background: #202124;
}
.smart-style-1 .ebillForm header {
  color: #fff;
}
.smart-style-1 .boxDiv {
  border: 1px solid #333333 !important;
}
.smart-style-1 .boxDiv .boxClass .box {
  border: 1px solid #333333 !important;
  color: #fff;
}
.smart-style-1 .filePattern {
  color: #E2E2E2 !important;
}
.smart-style-1 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #7c7b7b;
}
.smart-style-1 .fuelux .wizard ul li.active {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #171717;
}
.smart-style-1 .fuelux .wizard .actions {
  position: absolute;
  right: 0;
  z-index: 2;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #171717;
  border-left: 1px solid #3B424C;
}
.smart-style-1 .fuelux .wizard ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #171717;
  content: "";
}
.smart-style-1 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #fff;
  cursor: default;
  background: #171717;
}
.smart-style-1 .fuelux .wizard ul li.active {
  color: #000;
  background: #8B9635;
}
.smart-style-1 .fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #8B9635;
}
.smart-style-1 .fuelux .wizard {
  background-color: transparent !important;
  border-color: #333333;
}
.smart-style-1 .btnAction,
.smart-style-1 .btnFile,
.smart-style-1 .btnSendinvit,
.smart-style-1 .iom_btn {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #ffffff !important;
  padding: 3px 15px;
}
.smart-style-1 .btnAction:hover,
.smart-style-1 .btnFile:hover,
.smart-style-1 .btnSendinvit:hover,
.smart-style-1 .iom_btn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-1 .dropzonbox {
  background-color: #171717 !important;
  border: 1px solid #3B424C !important;
}
.smart-style-1 .company-select {
  border: none;
  border-radius: 10px;
  background-color: #0C1214 !important;
}
.smart-style-1 .businessFields label {
  color: #fff !important;
  font-size: 14px;
}
.smart-style-1 .company-select {
  background: #0C1214 !important;
  color: #fff;
}
.smart-style-1 .xslt-path {
  border: 1px solid #333;
  background: transparent;
  color: #fff;
}
.smart-style-1 .dopzoneTxt,
.smart-style-1 .sendEmail {
  color: #fff;
}
.smart-style-1 .archiveImport {
  background: #202124;
  color: #fff;
}
.smart-style-1 .nav-tabs > li.active > a.display-border,
.smart-style-1 .nav-tabs > li.active > a.display-border:focus,
.smart-style-1 .nav-tabs > li.active > a.display-border:hover {
  border-top: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
  border-right: 2px solid #8B9635 !important;
  color: #fff;
  box-shadow: none;
}
.smart-style-1 .transaction.nav-tabs > li.active a {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #111;
  border-bottom-color: transparent;
}
.smart-style-1 .transaction.nav-tabs > li.active a:hover {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #111;
  border-bottom-color: transparent;
}
.smart-style-1 .transaction.nav-tabs > li.active a:focus {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #111;
  border-bottom-color: transparent;
}
.smart-style-1 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #fff !important;
}
.smart-style-1 .fileuploadform-form .input-group-append .input-group-text {
  background-color: #202124;
  color: #ffffff;
  border: 1px solid #333333;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-1 .fileuploadform-form.formly .form-control {
  color: #ffffff !important;
  background-color: #0C1214 !important;
  border-color: #0C1214;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-1 .iom-button {
  color: #ffffff;
}
.smart-style-1 .tempCompanyselect {
  background-color: #202124 !important;
  order: 1px solid #333333;
}
.smart-style-1 .interConnPopupForm .form-control {
  border: 1px solid #666666 !important;
  background-color: #202124 !important;
  border-radius: 5px !important;
}
.smart-style-1 .dataTables_processing.panel.panel-default {
  background: url('spin-loader_small_white.797d455d611e7389cd3b.svg') no-repeat !important;
}
.smart-style-1 .detail_save_btn:hover,
.smart-style-1 .detail_save_Btn:hover,
.smart-style-1 .approveBtn:hover,
.smart-style-1 .gotoEdi:hover,
.smart-style-1 .iom_btn:hover,
.smart-style-1 .detailInfo_save_btn:hover {
  color: #000 !important;
}
.smart-style-1 .itemEditButton:hover {
  color: #0C1214 !important;
}
.smart-style-1 table.dataTable tbody tr.selected,
.smart-style-1 table.dataTable tbody tr.selected:hover {
  color: #000 !important;
}
.smart-style-1 .badge-iom {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 13px !important;
  padding: 4px 13px !important;
  background-color: #8B9635 !important;
  color: #000;
  border-radius: 10rem;
}
.smart-style-1 .theme-list-div .half-div {
  color: #ffffff !important;
}
.smart-style-1 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #fff !important;
}
.smart-style-1 #transaction-header .form-control::-moz-placeholder {
  color: #fff !important;
}
.smart-style-1 #transaction-header .form-control::placeholder {
  color: #fff !important;
}
.smart-style-1 .companyTooltip {
  background: #171717 0% 0% no-repeat padding-box;
  border: 1px solid #333333;
  color: #E2E2E2;
}
.smart-style-2 table.dataTable tbody tr.selected,
.smart-style-2 table.dataTable tbody tr.selected:hover {
  color: #ffffff !important;
}
.smart-style-2 .itemEditButton:hover {
  color: #ffffff !important;
}
.smart-style-2 #content {
  background: #ffffff !important;
}
.smart-style-2 a.iom_btn.nav_btn {
  color: #4c4f53 !important;
}
.smart-style-2 .logoutSection a.iom_btn.nav_btn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #ffffff !important;
}
.smart-style-2 .successchangepassDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .btn-primary {
  background: #ffffff !important;
  border: 2px solid #8B9635;
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .btn-primary:hover {
  background: #8B9635 !important;
  color: #ffffff !important;
  border: 2px solid #8B9635;
}
.smart-style-2 .btn-primary:focus {
  border-color: #8B9635 !important;
}
.smart-style-2 .addonItem {
  color: #000 !important;
}
.smart-style-2 .connector-form .inputSection .form-control,
.smart-style-2 .connector-form .selectSection .form-control {
  background-color: #EEEEEE !important;
  border: 1px solid #EEEEEE !important;
}
.smart-style-2 .connectorContainer .serviceLbl {
  color: #000 !important;
}
.smart-style-2 .serviceBody label {
  color: #000;
}
.smart-style-2 .serviceBody .inputSection label,
.smart-style-2 .serviceBody .selectSection label {
  color: #000 !important;
}
.smart-style-2 .serviceForm formly-field label,
.smart-style-2 .serviceForm formly-wrapper-form-field label {
  color: #000 !important;
}
.smart-style-2 .serviceForm formly-field input,
.smart-style-2 .serviceForm formly-wrapper-form-field input {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.smart-style-2 .connectorlblleft,
.smart-style-2 .connectorlbl {
  color: #000 !important;
}
.smart-style-2 .setCircleactive {
  color: #00eba9;
  font-size: 10px !important;
}
.smart-style-2 #main {
  background-color: #ffffff !important;
  height: 100% !important;
}
.smart-style-2 .theme-header-text-color {
  color: #212529 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .cancel {
  text-align: left;
  width: auto;
  font-size: 13px;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #8B9635 !important;
  letter-spacing: 0px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #202528;
  opacity: 1;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #ffffff !important;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  background-color: #F7F7F7 !important;
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-2 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-2 .currentButton {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #ccc !important;
  cursor: not-allowed;
}
.smart-style-2 .currentButtonent {
  border-color: #ccc !important;
  background-color: #F7F7F7 !important;
}
.smart-style-2 .currentPlan {
  background-color: #ccc !important;
  color: #202528 !important;
}
.smart-style-2 .currentPlan:hover {
  background: #ccc !important;
}
.smart-style-2 .emptyCol {
  border-top-right-radius: 0px !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-2 .selectButton {
  border-radius: 0%;
  padding: 3px 15px;
  background-color: #ffffff;
  border: 2px solid #8B9635;
  border-radius: 20px;
  color: #202528;
  font-size: 14px;
  outline: none;
}
.smart-style-2 .selectButton:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border-color: #8B9635;
}
.smart-style-2 .nextBtn {
  font-size: 13px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-2 .cancelBtn {
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  color: #000 !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.smart-style-2 .cancelBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #ffffff !important;
}
.smart-style-2 .jarviswidget > div {
  border: none;
}
.smart-style-2 .pricingTable table {
  color: #000 !important;
}
.smart-style-2 .bootstrapWizard li .step {
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #202528;
}
.smart-style-2 .lightBackgroundColor {
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  border-radius: 10px;
  margin-left: 13px;
}
.smart-style-2 .left-div {
  background-color: #ffffff !important;
  border-radius: 10px;
}
.smart-style-2 .card-div {
  border-radius: 5px;
  background-color: #ffffff !important;
  border: 1px solid #ccc !important;
  padding-bottom: 0px;
}
.smart-style-2 .card-div:hover {
  background-color: #EEEEEE !important;
}
.smart-style-2 .textColor {
  color: #202528 !important;
}
.smart-style-2 .textColor:hover {
  background: transparent !important;
}
.smart-style-2 .headData {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .jarviswidget-color-darken header {
  background: none !important;
  color: #4C4F53 !important;
}
.smart-style-2 .jarviswidget > header {
  color: #333;
  border: none;
}
.smart-style-2 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-2 .applicationHead {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: black !important;
}
.smart-style-2 #left-panel {
  padding-right: 0px !important;
  padding-left: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
.smart-style-2 #left-panel nav ul li {
  border-bottom: 1px solid #8B9635;
}
.smart-style-2 #left-panel nav ul li:hover {
  background: #8B9635 !important;
  color: #ffffff !important;
  border: 1px solid #3B3B3B33;
}
.smart-style-2 #left-panel nav ul li.active {
  background-color: #8B9635 !important;
}
.smart-style-2 #left-panel nav ul li.active a {
  color: #fff !important;
}
.smart-style-2 #left-panel nav ul li.active ul li {
  color: #202427 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li:hover {
  color: #ffffff !important;
}
.smart-style-2 #left-panel nav ul li.active ul li:hover a {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li a {
  color: #4c4f53 !important;
}
.smart-style-2 #left-panel nav ul li.active ul li.active a {
  color: #ffffff !important;
}
.smart-style-2 #left-panel nav ul li.open li:hover a {
  color: #ffffff !important;
}
.smart-style-2 nav ul li.active a {
  font-weight: 400;
}
.smart-style-2 nav ul li.active a:before {
  color: #202427;
}
.smart-style-2 nav ul li a {
  color: #4c4f53 !important;
  padding: 12px 17px 12px 16px !important;
  font-size: 16px !important;
}
.smart-style-2 nav ul li a:focus, .smart-style-2 nav ul li a:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li a i {
  font-size: 17px !important;
  vertical-align: 0 !important;
}
.smart-style-2 nav ul li.open a {
  color: #4c4f53 !important;
  font-weight: 400;
}
.smart-style-2 nav ul li.open:focus, .smart-style-2 nav ul li.open:hover {
  color: #ffffff !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li.open a b {
  color: #4c4f53 !important;
}
.smart-style-2 nav ul li.open.active a b {
  color: #4c4f53 !important;
}
.smart-style-2 nav ul li.open:hover > a {
  color: #ffffff !important;
}
.smart-style-2 nav ul li.open:hover > a b {
  color: #ffffff !important;
}
.smart-style-2 nav ul ul li {
  background-color: #dbdbdb;
}
.smart-style-2 nav ul ul li a {
  padding-left: 43px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  color: #ffffff;
  font-size: 14px !important;
}
.smart-style-2 nav ul ul li a:focus, .smart-style-2 nav ul ul li a:hover {
  color: #202427 !important;
  background: #8B9635 !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .notification-body .from {
  color: #4C4F53 !important;
}
.smart-style-2 .notification-body .msg-body {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body .activityTime {
  color: #7A7A7A !important;
}
.smart-style-2 .notification-body li span {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .ajax-dropdown {
  border: 1px solid #d9d9d9 !important;
}
.smart-style-2 #fullscreen .svg-icon {
  background-image: url('Path222.faed6ac4229dbddce176.svg');
}
.smart-style-2 #header .notification-svg {
  background-image: url('39-Notification.a91267442bae57b87b90.svg');
}
.smart-style-2 #header #activity.activity-svg {
  background-image: url('Page-1.06157606d3196914b46c.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-svg {
  background-image: url('Path 224.55a5f2da498c377ed45f.svg');
}
.smart-style-2 #header .collapseMenu .collaspe-menu-sub-svg {
  background-image: url('Group224.649ca2f656deeb39e0a7.svg');
  cursor: pointer;
}
.smart-style-2 #header .collapseMenu:hover {
  background-color: #ccc;
}
.smart-style-2 #header .header-btn {
  box-shadow: none;
}
.smart-style-2 #header .header-btn-list a {
  box-shadow: none;
}
.smart-style-2 #header .menu-item:not(:last-child) {
  border: unset !important;
}
.smart-style-2 #header .menu-item .webComponetsBorder {
  border: none;
}
.smart-style-2 #header .menu-item:hover {
  background-color: #ccc;
}
.smart-style-2 #header .menu-item .webComponetsHoverColor:hover {
  background-color: #ccc;
}
.smart-style-2 #header .menu-item.no-hover:hover {
  background-color: #EDEDED;
}
.smart-style-2 .header-dropdown-list a.dropdown-toggle {
  color: #4C4F53 !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smart-style-2 .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smart-style-2 .theme-reverse-text-color {
  color: #0C1214 !important;
  color: #ffffff !important;
}
.smart-style-2 .theme-icon {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #4C4F53 !important;
}
.smart-style-2 .theme-subtext-color {
  color: #7A7A7A !important;
}
.smart-style-2 .dashboard .jarviswidget div {
  background-color: #F7F7F7 !important;
  border: 1px solid #ccc !important;
}
.smart-style-2 .profileBackground {
  background-color: #F7F7F7 !important;
  border: 1px solid #ccc !important;
}
.smart-style-2 #ribbon .breadcrumb li {
  color: #69737A;
}
.smart-style-2 #ribbon .breadcrumb li:last-child {
  color: #4C4F53 !important;
}
.smart-style-2 .userProfile .applicationBackGround {
  background-color: #ffffff !important;
  padding: 0;
}
.smart-style-2 .userProfile .theme-text-color {
  color: #202528 !important;
}
.smart-style-2 .border {
  border: 1px solid #ccc !important;
  border-radius: 10px;
}
.smart-style-2 .subList:not(:last-child) {
  border-bottom: 1px solid #ffffff !important;
}
.smart-style-2 .theme-fade-color {
  color: #7A7A7A !important;
}
.smart-style-2 .applicationHeadModal {
  background: #353D4B !important;
}
.smart-style-2 .modalBodyBgColor {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .spanBoderBottom {
  border-bottom: 1px solid #ccc !important;
}
.smart-style-2 .theme-bg-color {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .theme-header-color {
  color: #000 !important;
}
.smart-style-2 .login-info {
  color: #212529 !important;
}
.smart-style-2 .auth-text-color {
  color: #ccc !important;
}
.smart-style-2 .menuIconsOption img {
  filter: brightness(100) invert(1);
}
.smart-style-2 .menuSelected {
  background-color: #ccc;
}
.smart-style-2 .account-table-div .dt-toolbar-footer {
  border: 1px solid #EDEDED !important;
  margin-top: -6px;
  box-shadow: none !important;
  background-color: #EDEDED !important;
  width: 100% !important;
}
.smart-style-2 .account-table-div .dt-toolbar-footer .pagination .paginate_button.active a {
  background-color: #4C4F53 !important;
  border-color: #4C4F53 !important;
  color: #F7F7F7 !important;
}
.smart-style-2 .account-table .history_action {
  text-decoration: underline !important;
}
.smart-style-2 .account-table td {
  color: #4C4F53 !important;
  padding-left: 24px !important;
}
.smart-style-2 .account-table th {
  color: #4C4F53 !important;
  padding-left: 24px !important;
}
.smart-style-2 nav ul li.active > a:before {
  content: unset !important;
}
.smart-style-2 .dropdown-icon-menu > ul > li .btn {
  background: none;
}
.smart-style-2 .header-btn {
  background: none;
}
.smart-style-2 .body-image {
  border-radius: 100%;
}
.smart-style-2 .profileDeatils {
  border: 1px solid #ccc;
  margin-top: -42px;
  background-color: #666666;
  border-radius: 10px;
  height: 601px;
}
.smart-style-2 .theme-text-color {
  color: #202528;
}
.smart-style-2 .lstTrncnTbl .dt-toolbar {
  background: none !important;
  border-bottom: none;
}
.smart-style-2 .lstTrncnTbl td a {
  color: #8B9635;
}
.smart-style-2 .company_details_section {
  border: 1px solid #ccc !important;
}
.smart-style-2 .contact_infrmtn_sctn {
  border: 1px solid #ccc !important;
}
.smart-style-2 .contact_infrmtn_sctn .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .contact_infrmtn_sctn .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .comapny_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .comapny_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .company_detail_head {
  color: #000 !important;
}
.smart-style-2 .editEnabled .detailLbl {
  color: #000 !important;
  font-size: 14px !important;
}
.smart-style-2 .editEnabled .detailLbl .commoninputStyle .form-control {
  background-color: #ffffff !important;
}
.smart-style-2 .detail_save_btn,
.smart-style-2 .detail_save_Btn,
.smart-style-2 .approveBtn,
.smart-style-2 .detailInfo_save_btn,
.smart-style-2 .approveBtn,
.smart-style-2 .gotoEdi,
.smart-style-2 .iom_btn {
  color: #000;
}
.smart-style-2 .detail_save_btn:hover,
.smart-style-2 .detail_save_Btn:hover,
.smart-style-2 .approveBtn:hover,
.smart-style-2 .detailInfo_save_btn:hover,
.smart-style-2 .approveBtn:hover,
.smart-style-2 .gotoEdi:hover,
.smart-style-2 .iom_btn:hover {
  color: #ffffff;
}
.smart-style-2 .editDisabled {
  background: none;
}
.smart-style-2 .editEnabled {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .profile_details .prf_name {
  color: #000;
}
.smart-style-2 .theme-content-color {
  color: #666666;
}
.smart-style-2 .addressdiv {
  background: #EEEEEE 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .theme-button-color {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .tranhoverTable select {
  background-color: inherit;
  color: inherit;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.smart-style-2 .tranhoverTable select option {
  background: #EEEEEE 0% 0% no-repeat padding-box;
  color: #202528;
}
.smart-style-2 .tranhoverTable input[type=radio]:checked:after {
  border: 2px solid #ccc;
}
.smart-style-2 .butttonBluecolor {
  background-color: #ffffff !important;
  border: 2px solid #8B9635 !important;
  color: #171717 !important;
  border-radius: 20px;
  padding: 3px 15px;
}
.smart-style-2 .butttonBluecolor:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-2 .gridSectionFirst .payMethodSection {
  border: 1px solid #ccc;
}
.smart-style-2 .gridSectionFirst .payMethodSection .payHead span {
  color: #000;
  font-weight: 600;
}
.smart-style-2 .gridSectionFirst .techContactSection {
  border: 1px solid #ccc;
}
.smart-style-2 .gridSectionFirst .techContactSection .techHead {
  color: #000;
  font-weight: 600;
}
.smart-style-2 .technical_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .technical_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionTwo .billingContactSection {
  border: 1px solid #ccc;
}
.smart-style-2 .gridSectionTwo .billingContactSection .billHead {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.smart-style-2 .billing_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .billing_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionThree .estimatedBillSection {
  padding: 10px 30px;
  border: 1px solid #ccc;
  margin: 20px 0px 20px 20px;
  width: 96%;
  border-radius: 10px;
  height: 584px;
}
.smart-style-2 .gridSectionThree .estimatedBillSection hr {
  border-top: 1px solid #ccc !important;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .estBillHead {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .billItemDesc {
  font-size: 14px;
  color: #000;
}
.smart-style-2 .gridSectionThree .estimatedBillSection .billItemPrice {
  font-size: 14px;
  color: #000;
}
.smart-style-2 .cardNum,
.smart-style-2 .cardOwnerName,
.smart-style-2 .cardExp_Date {
  color: #000 !important;
}
.smart-style-2 .editPaymentHeader {
  color: #000 !important;
  font-size: 16px !important;
}
.smart-style-2 .payContent {
  background: #F7F7F7 !important;
}
.smart-style-2 .edit_details_form .detailLbl {
  color: #000;
  font-size: 14px;
}
.smart-style-2 .edit_details_form .detailLbl .commoninputStyle .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #ffffff;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #000;
}
.smart-style-2 .modal-content {
  background-color: #ffffff !important;
  border: 1px solid #666666;
  color: #171717;
  border-radius: 10px;
}
.smart-style-2 .underline {
  border-color: #ccc;
}
.smart-style-2 .subscription-sum {
  border-top: 2px solid #ccc !important;
}
.smart-style-2 .card-fields .form-fields-wrapper {
  border-bottom: 1px solid #d5e1ec;
}
.smart-style-2 .card-fields .form-fields-wrapper .icon {
  width: 25px;
  height: auto;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields {
  width: calc(100% - 35px);
  margin-left: auto;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input {
  outline: none;
  border: none;
  width: 100%;
  line-height: 50px;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  background-color: #F7F7F7;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input::-webkit-input-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input::-moz-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input:-ms-input-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-2 .card-fields .form-fields-wrapper .form-fields input :-moz-placeholder {
  color: #333333;
  font-size: 16px;
}
.smart-style-2 .card-fields .checkbox {
  color: #005397;
}
.smart-style-2 .card-fields .card-errors {
  color: #eb0042;
  font-size: 13px;
  margin: 10px 0 0 0;
}
.smart-style-2 .payment-detail {
  width: 100%;
  max-width: 1010px;
  background-color: #F7F7F7;
  margin: auto;
  padding: 10px 10px 6px 10px;
}
.smart-style-2 .nav ul .active > a {
  color: #202427 !important;
  font-weight: 700;
}
.smart-style-2 .ElementsApp,
.smart-style-2 .ElementsApp .InputElement {
  color: #000 !important;
  font-weight: 300;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
}
.smart-style-2 .prf_name,
.smart-style-2 .prf_url {
  color: #000 !important;
}
.smart-style-2 .sub-btn {
  border: 2px solid #8B9635 !important;
  color: #171717 !important;
}
.smart-style-2 .sub-btn:hover {
  background-color: #8B9635 !important;
  color: #ffffff !important;
}
.smart-style-2 .manage-btn {
  font-size: 13px;
  border: 2px solid #ccc !important;
  border-radius: 20px;
  background-color: #ffffff !important;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .manage-btn:hover {
  background: #8B9635 !important;
  color: #000 !important;
  border: 2px solid #8B9635 !important;
}
.smart-style-2 .ganerateButton {
  background: #ffffff;
  color: #000;
}
.smart-style-2 .stepsChecked {
  font-weight: 900;
  background-color: #333333;
}
.smart-style-2 .next_payment {
  background-color: #CCCCCC;
  color: #000;
}
.smart-style-2 .selectExpiry .form-control {
  height: 40px !important;
  border-radius: 6px !important;
  background-color: #ffffff;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #000;
  margin-top: 5px;
}
.smart-style-2 .form-control.is-invalid {
  background-image: none !important;
  border-color: #dc3545 !important;
}
.smart-style-2 label {
  font-weight: 400;
}
.smart-style-2 .profileEditTextBox {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .profileModalBtn {
  padding: 10px;
  background-color: #ffffff !important;
  border: 2px solid #8B9635 !important;
  color: #000 !important;
  border-radius: 19px;
}
.smart-style-2 .profileModalBtn:hover {
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-2 .sub-img {
  background: #666666 0% 0% no-repeat padding-box;
}
.smart-style-2 .row-divider {
  border-color: #ccc !important;
}
.smart-style-2 .dot-div {
  color: #666666 !important;
}
.smart-style-2 .theme-header-text-color {
  color: #212529 !important;
}
.smart-style-2 .theme-header-color {
  color: #4C4F53 !important;
}
.smart-style-2 .cancel {
  text-align: left;
  width: auto;
  font-size: 13px;
  border-radius: 15px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 2px solid #8B9635 !important;
  letter-spacing: 0px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .TotalLabel {
  top: 275px;
  left: 560px;
  height: 15px;
  text-align: left;
  font: normal normal bold 31px/23px Open Sans;
  letter-spacing: 0px;
  color: #202528;
  opacity: 1;
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.smart-style-2 .paymentSectionDetails {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  border-radius: 10px;
  margin-left: -1px;
}
.smart-style-2 .paymentOptions {
  display: none;
  border-radius: 10px;
}
.smart-style-2 .table-bordered > tbody > tr > td {
  border: 1px solid #ccc;
}
.smart-style-2 .table-bordered > tbody.headData > tr > td.switchCol,
.smart-style-2 .table-bordered > thead.headData > tr > th.switchCol,
.smart-style-2 .table-bordered > tbody.table-headData > tr > td.switchCol {
  box-shadow: -1px 0 0 0 #ccc;
}
.smart-style-2 .table-bordered > tbody.headData > tr > td:first-child.switchCol,
.smart-style-2 .table-bordered > tbody.table-headData > tr > td:first-child.switchCol {
  box-shadow: 0 -1px 0 0 #ccc;
}
.smart-style-2 .currentPlan {
  background-color: #ccc !important;
  color: #202528 !important;
}
.smart-style-2 .currentPlan:hover {
  background: #ccc !important;
}
.smart-style-2 .emptyCol {
  background-color: #ffffff !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.smart-style-2 .nextBtn {
  font-size: 13px;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-2 .cancelBtn {
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  background: #F7F7F7 !important;
  color: #000 !important;
  font-size: 13px;
  padding: 3px 15px !important;
}
.smart-style-2 .cancelBtn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-2 .jarviswidget > div {
  border: none;
}
.smart-style-2 .pricingTable table {
  color: #000 !important;
}
.smart-style-2 .nextstep {
  font-size: 13px;
  background: #F7F7F7 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .nextstep:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #000 !important;
}
.smart-style-2 .bootstrapWizard li .step {
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #202528;
}
.smart-style-2 .lightBackgroundColor {
  background-color: #ffffff !important;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-left: 13px;
}
.smart-style-2 .textColor {
  color: #4C4F53 !important;
}
.smart-style-2 .NextconfirmLabel {
  color: #171717 !important;
  border: 1px solid #ccc;
}
.smart-style-2 .cardconfirmLabel {
  color: #171717 !important;
  border: 1px solid #ccc;
}
.smart-style-2 .back-btn {
  background: #073E84 0% 0% no-repeat padding-box;
  border-color: #073E84;
  font-size: 13px;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a span {
  color: #000 !important;
}
.smart-style-2 .buy-addon .navigationList .nav.nav-tabs li a:hover {
  background-color: #8B9635 !important;
  border: 1px solid #8B9635 !important;
}
.smart-style-2 .package-name .packageHeader {
  color: #202528 !important;
}
.smart-style-2 .yearlyData span.price {
  color: #202528 !important;
}
.smart-style-2 .buy-addon .checkout {
  background-color: #ffffff;
}
.smart-style-2 .table-headData {
  background-color: #ffffff;
  color: #000;
  margin-top: 10px;
}
.smart-style-2 .cart-btn {
  background-color: #202528;
}
.smart-style-2 .blacktooltip {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ccc;
  color: #4C4F53;
}
.smart-style-2 .blacktooltip .insideDivUl li {
  border-bottom: 1px solid #EDEDED;
}
.smart-style-2 .blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-2 .error-blacktooltip {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ccc;
  color: #4C4F53;
}
.smart-style-2 .error-blacktooltip .insideDivUl li {
  border-bottom: 1px solid #EDEDED;
}
.smart-style-2 .error-blacktooltip .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-2 .optionSelect {
  background-color: #FFFFFF !important;
  color: #202124 !important;
  border-color: #ccc !important;
}
.smart-style-2 .table {
  width: 100%;
  color: #000;
  border-collapse: collapse;
  border-color: #ccc;
  border-radius: 5px;
}
.smart-style-2 .history-table th {
  border-color: #ccc !important;
  background: #EEEEEE 0% 0% no-repeat padding-box;
  color: #000;
}
.smart-style-2 .commonHead {
  background-color: #EEEEEE !important;
  color: #E2E2E2;
  font-size: 14px;
  border: unset !important;
}
.smart-style-2 .algntblTranscation {
  background: #ffffff !important;
}
.smart-style-2 .historyLabel {
  color: #000;
}
.smart-style-2 table.history-table {
  margin-top: 0 !important;
  border-radius: 5px !important;
  background-color: #ffffff;
}
.smart-style-2 table.history-table tr:hover {
  background-color: #EEEEEE !important;
  color: #000;
}
.smart-style-2 .history-table .history_action {
  text-decoration: underline !important;
}
.smart-style-2 .table-bordereds > tbody > tr > td {
  border: 1px solid #ccc;
}
.smart-style-2 #userChoiceSelect .container,
.smart-style-2 #userChoiceSelect .durationDetailFields,
.smart-style-2 #userChoiceSelect .successNote {
  background-color: #EEE !important;
}
.smart-style-2 #userChoiceSelect .commonTimeData {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE !important;
}
.smart-style-2 #userChoiceSelect .fieldHeader {
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect .commonFormField input,
.smart-style-2 #userChoiceSelect .commonFormField textarea {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 #userChoiceSelect textarea {
  background-color: #ffffff !important;
  border: 1px solid #EEEEEE;
  color: #000 !important;
}
.smart-style-2 .yearlabel {
  border: 1px solid #ccc !important;
  background-color: #ffffff;
}
.smart-style-2 .cancellabel {
  border: 1px solid #ccc !important;
  background-color: #ffffff;
}
.smart-style-2 .table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}
.smart-style-2 .gradientDiv {
  background: #EEEEEE 0% 0% no-repeat padding-box !important;
  border: 1px solid #ccc;
}
.smart-style-2 .hoverBtn {
  background: #ffffff !important;
}
.smart-style-2 .spnFilePath b {
  color: #ffffff !important;
}
.smart-style-2 .dataTables_processing {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .pagination > .active > a {
  background-color: #8B9635 !important;
  border-color: #8B9635 !important;
  color: #ffffff;
}
.smart-style-2 .pagination > li > a {
  background-color: #ffffff !important;
  color: #000;
}
.smart-style-2 .pagination > li:first-child > a,
.smart-style-2 .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: #ffffff !important;
}
.smart-style-2 .pagination > li:last-child > a,
.smart-style-2 .pagination > li:last-child > span {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #ffffff !important;
}
.smart-style-2 table.pending-list-table {
  border: 1px solid #ccc !important;
  margin-top: 0px !important;
  border-radius: 5px;
}
.smart-style-2 .table thead tr th {
  border-color: #ccc;
}
.smart-style-2 .table tbody tr td {
  border-color: #ccc;
}
.smart-style-2 .history-btn {
  color: #000;
}
.smart-style-2 .history-btn:hover {
  background-color: #8B9635 !important;
  color: #000;
}
.smart-style-2 .darkHeaderIcon,
.smart-style-2 .lightHeaderIcon {
  color: #000 !important;
}
.smart-style-2 .list-area {
  background-color: #0D0F12;
  border-radius: 0px;
}
.smart-style-2 .user_roles .c-btn {
  color: #ffffff;
  border-color: #424242;
  background: #616161;
}
.smart-style-2 .serviceTheme {
  background-color: #FFFFFF !important;
  border-radius: 0px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar {
  background-color: #FFFFFF !important;
  border-bottom: none !important;
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title {
  width: 100% !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .widget-header {
  display: flex;
  justify-content: normal;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-title .shortDescription {
  text-align: center;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-dialog-titlebar-close {
  position: absolute !important;
  top: 25px;
  right: 5px;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h5 {
  color: #000 !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar h1 {
  color: #000 !important;
}
.smart-style-2 .serviceTheme .ui-dialog-titlebar .ui-button {
  color: #8B9635;
  opacity: 1;
}
.smart-style-2 .serviceTheme .ui-dialog-content {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal {
  background-color: #FFFFFF !important;
}
.smart-style-2 .serviceTheme .ui-dialog-content .service_modal .modal-content {
  box-shadow: unset !important;
  -webkit-box-shadow: unset !important;
  border: unset !important;
  background-color: #FFFFFF !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .filter-textbox input {
  background: #ffffff !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #000 !important;
  border: 2px solid #000 !important;
}
.smart-style-2 .user-role-form .multiselect-dropdown .multiselect-item-checkbox input + div:after {
  border-color: #000 !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .filter-textbox input {
  background: #ffffff !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox {
  border-bottom: none !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:before {
  color: #000 !important;
  border: 2px solid #645b5b !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:focus {
  color: #000 !important;
  border: 2px solid #645b5b !important;
}
.smart-style-2 .price_calcultation_form .multiselect-dropdown .multiselect-item-checkbox input + div:after {
  border-color: #645b5b !important;
}
.smart-style-2 .reMark {
  color: #000;
  border: 1px solid #ccc;
}
.smart-style-2 .darkHeaderIcon,
.smart-style-2 .lightHeaderIcon,
.smart-style-2 .editButtonsLabel {
  color: #000 !important;
}
.smart-style-2 .spin-loader {
  background: url('spin-loader_small.b2cbaf97b93dc78ca6c3.svg') no-repeat;
}
.smart-style-2 .big-spin-loader {
  background: url('spin-loader.a01fb811724c25c2dd1e.svg') no-repeat !important;
}
.smart-style-2 .errorOccured {
  border-color: #dc3545 !important;
}
.smart-style-2 .NextconfirmLabels {
  color: #000;
  border: 1px solid #ccc;
}
.smart-style-2 .btnModalSave {
  float: right;
  background-color: #ffffff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid #8B9635;
}
.smart-style-2 .btnModalSave:hover {
  color: #000 !important;
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #ffffff;
  border: none;
  color: #202124 !important;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-2 .successDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successinviteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successappDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successcmpimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successcmpprofileimgDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .successuserdeleteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .disabledColor {
  background-color: #F7F7F7 !important;
  color: #000 !important;
  border-color: #ccc !important;
}
.smart-style-2 .cardimg {
  filter: brightness(100) invert(1);
}
.smart-style-2 .userInfromation,
.smart-style-2 .seperator,
.smart-style-2 .modal_header {
  color: #000 !important;
}
.smart-style-2 .inv-img {
  content: url('invoice.129e5b40d80804bcb275.svg') !important;
}
.smart-style-2 .badge-disable-iom {
  color: #111 !important;
}
.smart-style-2 .checkmark {
  border: 2px solid #666666;
}
.smart-style-2 .checkbox-container .checkmark:after {
  left: 7px;
  top: 1px;
  width: 8px;
  height: 16px;
  border: solid #959595;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.smart-style-2 .vertical-line {
  background-color: #CCCCCC;
}
.smart-style-2 .card_details_nocard p {
  color: #111;
  font-size: 14px;
}
.smart-style-2 .subPlanData {
  color: #111;
}
.smart-style-2 .table-wrapper {
  background-color: #EEEEEE 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .table-wrapper table tbody tr td {
  background-color: #ffffff !important;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole {
  visibility: visible;
  border: 1px solid #ccc;
  color: #000;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole:hover {
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole .insideDivUl li {
  border-bottom: 1px solid #ccc;
}
.smart-style-2 .tooltipRole:hover .tooltiptextRole .insideDivUl li:last-child {
  border-bottom: unset;
}
.smart-style-2 .tooltipRole .tooltiptextRole {
  background-color: #ffffff;
  color: #000;
}
.smart-style-2 .header-formbtn {
  background-color: #ffffff !important;
  border: 2px solid #8B9635 !important;
  color: #000 !important;
  padding: 3px 15px;
}
.smart-style-2 .header-formbtn:hover {
  background: #8B9635 !important;
  color: #202528 !important;
}
.smart-style-2 .companyField,
.smart-style-2 .comapnyLbl,
.smart-style-2 .manageHeader,
.smart-style-2 .comapnyHeaderLbl {
  color: #0c1214 !important;
}
.smart-style-2 .MessageBoxButtonSection button {
  color: #0c1214 !important;
}
.smart-style-2 .subscriptions-page .table-bordered > thead > tr {
  background: #ffffff !important;
}
.smart-style-2 .subscriptions-page .table-bordered > thead > tr > th:not(:first-child) {
  border: 1px solid #ccc;
}
.smart-style-2 .subscriptions-page table.noFeature.table-bordered > thead > tr {
  background: #ffffff !important;
}
.smart-style-2 .subscriptions-page table.noFeature.table-bordered > thead > tr > th {
  border: 1px solid #ccc;
}
.smart-style-2 .subscriptions-page td {
  background-color: #ffffff !important;
}
.smart-style-2 .top-div {
  border-bottom: 1px solid #ccc !important;
}
.smart-style-2 .top-div .description-div .description-content {
  border: 1px solid #ccc !important;
}
.smart-style-2 .step-div {
  background: #ffffff;
}
.smart-style-2 .footer-div {
  background: #ffffff;
}
.smart-style-2 .algntblTranscation {
  border-radius: 8px;
  border: 1px solid #ccc !important;
  overflow: hidden;
  background: #ffffff !important;
}
.smart-style-2 .setselectHeight {
  border: 1px solid #BDBDBD;
  background-color: #ffffff;
  color: #202528 !important;
}
.smart-style-2 .successdeleteDiv {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .btnModalClose {
  float: left;
  background-color: #ffffff;
  border: none;
  border-radius: 15px;
  border: 2px solid #8B9635 !important;
}
.smart-style-2 .ui-datepicker td .ui-state-default {
  color: #707070;
}
.smart-style-2 .ui-datepicker .ui-datepicker-header {
  background-color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-state-highlight {
  color: #ffffff;
}
.smart-style-2 .ui-datepicker td .ui-state-hover {
  color: #ffffff;
}
.smart-style-2 .iconForm {
  color: #000 !important;
}
.smart-style-2 .filter-top {
  color: #0C1214 !important;
}
.smart-style-2 .dashbaordlabel {
  background-color: unset !important;
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}
.smart-style-2 .companyslabel {
  background-color: unset !important;
  border-color: 1px solid #EEEEEE !important;
  border-radius: 5px !important;
}
.smart-style-2 .ediLbl,
.smart-style-2 .editoLbl {
  color: #000 !important;
}
.smart-style-2 .dashbaordlabel {
  color: #0C1214 !important;
}
.smart-style-2 table.positionTable {
  border: 1px solid #ccc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 5px;
}
.smart-style-2 table.bulkTable {
  border: 1px solid #ccc !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  border-radius: 5px;
}
.smart-style-2 .headerContents {
  border-radius: 8px;
  border: 1px solid #dadce0 !important;
  overflow: hidden;
}
.smart-style-2 .headerfilterContents {
  border-radius: 8px;
  border: 1px solid #dadce0 !important;
}
.smart-style-2 .headClr {
  color: #0C1214 !important;
}
.smart-style-2 .transactionHeads {
  color: #4C4F53 !important;
}
.smart-style-2 .detailLbl,
.smart-style-2 .lblWhite {
  color: #0C1214 !important;
}
.smart-style-2 .amount-div {
  color: #4C4F53 !important;
  background-color: #EDEDED !important;
}
.smart-style-2 .nav-tabs li {
  float: left;
  margin-bottom: -1px;
  width: 34%;
  text-align: center;
  border-bottom: 1px solid #ccc !important;
}
.smart-style-2 .invoiceOverview .nav-tabs li {
  border-bottom: 1px solid #ccc !important;
}
.smart-style-2 .invoiceOverview .nav-tabs > li.active {
  border-top: 1px solid #ccc !important;
}
.smart-style-2 .invoiceOverview .nav-tabs > li.active a,
.smart-style-2 .invoiceOverview .nav-tabs > li.active a:hover,
.smart-style-2 .invoiceOverview .nav-tabs > li.active a:focus {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
}
.smart-style-2 .invoiceOverview .nav-tabs li a:hover {
  background-color: transparent !important;
  border: none;
}
.smart-style-2 .headData {
  background-color: #EDEDED !important;
  color: #4C4F53 !important;
  border-color: #ccc !important;
}
.smart-style-2 .idTooltip,
.smart-style-2 .btnTooltip,
.smart-style-2 .errorTooltip,
.smart-style-2 .companyTooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
.smart-style-2 .advace-header-formbtn {
  background-color: #ffffff !important;
  border: 2px solid #8B9635 !important;
  color: #000 !important;
  padding: 3px 15px;
}
.smart-style-2 .advace-header-formbtn:hover {
  background: #8B9635 !important;
  color: #202528 !important;
}
.smart-style-2 .headtabData {
  color: #4C4F53 !important;
  border: none;
}
.smart-style-2 .topHead {
  background-color: #ffffff !important;
}
.smart-style-2 .error_text {
  color: #111 !important;
}
.smart-style-2 .tranhoverTable tbody tr:hover {
  color: #4C4F53 !important;
  background-color: #EDEDED !important;
  cursor: pointer;
}
.smart-style-2 #header.colorChangeTest .maintenance {
  color: #666666;
}
.smart-style-2 .filterSection .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #000 !important;
  font-size: 14px;
}
.smart-style-2 .filterSection .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: #000 transparent transparent !important;
  border-color: transparent transparent #000 !important;
}
.smart-style-2 .filterSection .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #000 !important;
  font-size: 12px !important;
}
.smart-style-2 .disableForm .panelSector .ng-select .ng-select-container {
  border: 1px solid #6666 !important;
}
.smart-style-2 .disableForm .panelSector .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #000 !important;
  font-size: 14px;
}
.smart-style-2 .disableForm .panelSector .ng-select .requiredSection .ng-select-container {
  border: 1px solid #dc3545;
}
.smart-style-2 .disableForm .panelSector .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border-color: #000 transparent transparent !important;
  border-color: transparent transparent #000 !important;
}
.smart-style-2 .disableForm .panelSector .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: #000 !important;
  font-size: 12px !important;
}
.smart-style-2 .disableForm .panelSector .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled {
  color: #000 !important;
}
.smart-style-2 .ebillFormFormly formly-field-select select option,
.smart-style-2 .disableForm formly-field-select select option,
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly .form-control,
.smart-style-2 .disableForm .form-control,
.smart-style-2 .ebillForm .form-control {
  background: #fff !important;
  border: 1px solid #6666 !important;
  color: #000;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.smart-style-2 #wizard .wizard-items {
  border: 1px solid #ccc;
  background: #fff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-billeritem {
  border: 1px solid #ccc;
  background: #fff 0% 0% no-repeat padding-box;
}
.smart-style-2 #wizard .wizard-items .wizardDiv {
  border: 1px solid transparent;
  background: #cccccc 0% 0% no-repeat padding-box;
}
.smart-style-2 .form-control:disabled {
  background-color: #EEEEEE !important;
}
.smart-style-2 .docInput {
  width: 97%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 #wizard .wizard-companyitem {
  border: 1px solid #ccc;
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 10px;
}
.smart-style-2 .docLbl {
  width: 100%;
  color: #000;
  font-size: 14px;
}
.smart-style-2 #wizard .line-1 {
  background: #ccc;
}
.smart-style-2 .billTabSection {
  border: 1px solid #ccc;
}
.smart-style-2 .ebillFormFormly formly-field-select select option,
.smart-style-2 .disableForm formly-field-select select option,
.smart-style-2 .ebillForm formly-field-select select option {
  background-color: unset !important;
}
.smart-style-2 .ebillFormFormly .form-control,
.smart-style-2 .disableForm .form-control,
.smart-style-2 .ebillForm .form-control {
  background: #fff !important;
  border: 1px solid #6666 !important;
  color: #000;
  height: 40px !important;
  border-radius: 6px !important;
  font-size: 14px;
}
.smart-style-2 #wizard {
  color: #666666;
}
.smart-style-2 .docInput {
  width: 97%;
  margin-right: 10px;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 .btn-primary {
  background: #fff !important;
  border: 2px solid #8B9635;
  color: #202124 !important;
  padding: 3px 15px;
  border-radius: 15px;
  font-size: 13px;
}
.smart-style-2 .common_wizardItemContainer .wizard_format {
  width: 50% !important;
}
.smart-style-2 .companySelect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  font-size: 13px !important;
}
.smart-style-2 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #6666 !important;
}
.smart-style-2 .companySelect .ng-select-container {
  border-radius: 10px !important;
  color: #000 !important;
  height: 42px !important;
  border: none;
}
.smart-style-2 .nav-tabs li {
  float: left;
  margin-bottom: -1px;
  width: 34%;
  text-align: center;
  border-bottom: 1px solid #ccc !important;
}
.smart-style-2 .nav-tabs li.active {
  border-bottom-color: transparent !important;
}
.smart-style-2 .biller_tabSection .nav-item {
  width: auto !important;
}
.smart-style-2 .biller_tabSection .nav-item a {
  margin-right: unset !important;
  background-color: unset !important;
  color: #000 !important;
  border-bottom: unset !important;
  border-left-color: #6666 !important;
  border-right-color: #6666 !important;
  border-top-color: #6666 !important;
}
.smart-style-2 .panelSector .ng-select .ng-select-container {
  background-color: transparent !important;
  color: #000 !important;
  border: 1px solid #6666 !important;
}
.smart-style-2 .emailMessage {
  border: 1px solid #ccc !important;
}
.smart-style-2 .fieldForm .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .comInput {
  width: 100%;
  border-radius: 10px !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE;
  border: unset !important;
}
.smart-style-2 .ng-dropdown-panel.ng-select-bottom {
  border: 1px solid #6666 !important;
}
.smart-style-2 .nav_btn {
  color: #fff !important;
}
.smart-style-2 .nav_btn:hover {
  color: #202427 !important;
}
.smart-style-2 .stepsinout-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .stepsinout-form.formly .connectorBorder select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.smart-style-2 .templatest-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border: 1px solid #ccc !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .templatest-form.formly .connectorBorder select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.smart-style-2 .connectorBorder select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.smart-style-2 .businessconnect-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .stepsinout-form .input-group-append .input-group-text {
  background-color: #EEEEEE;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.smart-style-2 .stepsinout-form .input-group-append .input-group-text::before {
  border-left: 1px solid #A2A2A2;
}
.smart-style-2 .businessconnect-form .input-group-append .input-group-text {
  background-color: #EEEEEE;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.smart-style-2 .businessconnect-form .input-group-append .input-group-text::before {
  border-left: 1px solid #A2A2A2;
}
.smart-style-2 .defaultDot {
  background-color: #666;
}
.smart-style-2 .statusSection tr td:not(:last-child) {
  border-right: 2px solid #333333;
}
.smart-style-2 .greyDot {
  background-color: black !important;
  opacity: 0.5;
}
.smart-style-2 .bp_connectorStatus,
.smart-style-2 .square {
  background-color: rgba(238, 238, 238, 0.9333333333) !important;
}
.smart-style-2 .statusSection tr td {
  color: #000 !important;
}
.smart-style-2 .bpHeader,
.smart-style-2 .bc_sectionHead,
.smart-style-2 .invitationLbl,
.smart-style-2 .searchLbl,
.smart-style-2 .netComID,
.smart-style-2 .businessLbl,
.smart-style-2 .comapnyLbl {
  color: #000 !important;
}
.smart-style-2 .company-select .ng-select-container,
.smart-style-2 .companySelect .ng-select-container {
  border-radius: 10px !important;
  color: #000 !important;
  height: 42px !important;
  border: none;
}
.smart-style-2 .invoice-tab-header {
  border: 1px solid #EDEDED !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active {
  border-bottom: none !important;
  box-shadow: 0 -2px 0 #8B9635 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active:hover {
  background-color: transparent !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead {
  background-color: transparent !important;
  color: #000 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:focus {
  background-color: #ffffff !important;
  color: #000 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li.active > a.invoice-tabhead:hover {
  background-color: #ffffff !important;
  color: #000 !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li {
  border-bottom: 1px solid #ccc !important;
  border-right: 1px solid #ccc !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:last-child {
  border-right: none !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li:hover {
  border-bottom: 1px solid #ccc !important;
  border-top: none !important;
  background-color: #CCCCCC !important;
}
.smart-style-2 .nav-tabs.invoice-tab > li > a:hover {
  color: #000 !important;
}
.smart-style-2 .commonBorderColor {
  border: 1px solid #ccc !important;
}
.smart-style-2 .formBorderColor {
  border: 1px solid #ccc !important;
}
.smart-style-2 .borderChangeColor {
  border-color: #ccc !important;
}
.smart-style-2 .dataTable tbody .shown + tr > td > table > tbody > tr:last-child {
  border-bottom: 1px solid #ccc;
}
.smart-style-2 .additional-details {
  border: 1px solid #ccc;
}
.smart-style-2 .devOptions {
  background-color: #f5f5f5;
  color: #171819;
}
.smart-style-2 .devOptions textarea {
  background-color: #f5f5f5;
}
.smart-style-2 .itemEditButton {
  background-color: #ffffff;
  color: #202528;
  border-color: #8B9635 !important;
}
.smart-style-2 .itemEditButton:active {
  background: #8B9635 !important;
  border-color: #8B9635 !important;
}
.smart-style-2 .graphDataLabel {
  color: #202528;
}
.smart-style-2 .tooltipA .tooltiptext {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  color: #4C4F53;
  border: 1px solid #EDEDED;
}
.smart-style-2 .notPaidHead,
.smart-style-2 .notPaidHead span {
  color: #4C4F53 !important;
}
.smart-style-2 .successDiv-status {
  background-color: #F7F7F7 !important;
}
.smart-style-2 .bs-datepicker-body table td {
  color: #000;
  text-align: center;
  position: relative;
  padding: 0;
}
.smart-style-2 .owl-dt-calendar-control {
  background-color: #fff;
  border: 1px solid #ccc !important;
  margin-top: 11px;
}
.smart-style-2 .tranheader {
  border: 1px solid #ccc !important;
  background: #ffffff !important;
  border-radius: 8px;
}
.smart-style-2 .owl-dt-calendar-control {
  background-color: #fff;
  border: 1px solid #ccc !important;
  margin-top: 11px;
}
.smart-style-2 .boxDiv {
  border: 1px solid #ccc !important;
}
.smart-style-2 .boxDiv .boxClass .box {
  border: 1px solid #ccc !important;
  color: #000 !important;
}
.smart-style-2 .filePattern {
  color: #000 !important;
}
.smart-style-2 .fuelux .wizard ul li .chevron:before {
  position: absolute;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
  /* background: #fff; */
}
.smart-style-2 .fuelux .wizard ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #000;
  cursor: default;
  background: #ededed;
}
.smart-style-2 .fuelux .wizard ul li.active {
  color: #fff;
  background: #8B9635;
}
.smart-style-2 .fuelux .wizard ul li.active .chevron:before {
  border-left: 14px solid #8B9635;
}
.smart-style-2 .btnAction,
.smart-style-2 .btnFile,
.smart-style-2 .iom_btn {
  font-size: 13px;
  background: transparent 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .btnAction:hover,
.smart-style-2 .btnFile:hover,
.smart-style-2 .iom_btn:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #ffffff !important;
}
.smart-style-2 .btnSendinvit, .smart-style-2 .btn-next {
  font: normal normal normal 12px/19.5px Open Sans;
  background: #fff 0% 0% no-repeat padding-box !important;
  border-color: #8B9635 !important;
  letter-spacing: 0px;
  border: 2px solid #8B9635 !important;
  border-radius: 15px;
  color: #171717 !important;
  padding: 3px 15px;
}
.smart-style-2 .btnSendinvit:hover, .smart-style-2 .btn-next:hover {
  background: #8B9635 !important;
  border: 2px solid #8B9635;
  color: #171717 !important;
}
.smart-style-2 .dropzonbox {
  background-color: #F7F7F7 !important;
  border: 1px solid #dadce0;
}
.smart-style-2 .company-select {
  border: none;
  border-radius: 10px;
  background-color: #EEEEEE !important;
}
.smart-style-2 .businessFields label {
  color: #000 !important;
  font-size: 14px;
}
.smart-style-2 .company-select {
  color: #0C1214 !important;
}
.smart-style-2 .xslt-path {
  border: 1px solid #666;
  background: transparent;
  color: #0C1214;
}
.smart-style-2 .dopzoneTxt {
  color: #000;
}
.smart-style-2 .detailLbls {
  color: #000 !important;
}
.smart-style-2 .archiveImport {
  background: #ffffff;
}
.smart-style-2 .transaction.nav-tabs > li.active a {
  cursor: default;
  background-color: transparent !important;
  border-top: 2px solid #8B9635 !important;
  border-left: 2px solid #8B9635 !important;
  border-right: 2px solid #8B9635 !important;
  border-bottom-color: transparent;
}
.smart-style-2 .transaction.nav-tabs > li.active a:hover {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #111;
  border-bottom-color: transparent;
}
.smart-style-2 .transaction.nav-tabs > li.active a:focus {
  cursor: default;
  background-color: transparent !important;
  border: 1px solid #111;
  border-bottom-color: transparent;
}
.smart-style-2 .bulkhoverTable tbody tr:hover {
  background-color: #8B9635 !important;
  cursor: pointer;
  color: #000 !important;
}
.smart-style-2 .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #202124 !important;
}
.smart-style-2 .fileuploadform-form.formly .form-control {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
  border-color: #EEEEEE !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  transition: border 0s ease-in-out 0s;
}
.smart-style-2 .fileuploadform-form .input-group-append .input-group-text {
  background-color: #fff;
  color: #495057;
  border: 1px solid #ced4da;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smart-style-2 .iom-button {
  color: #000;
}
.smart-style-2 .tempCompanyselect {
  color: #0C1214 !important;
  background-color: #EEEEEE !important;
}
.smart-style-2 .interConnPopupForm .form-control {
  background-color: #fff !important;
  border-radius: 5px !important;
}
.smart-style-2 .intertabData {
  color: #0C1214 !important;
  background-color: none;
}
.smart-style-2 .interConnPopupForm .form-control.is-invalid {
  border: 1px solid #ced4da !important;
}
.smart-style-2 .badge-iom {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 13px !important;
  padding: 4px 13px !important;
  background-color: #8B9635 !important;
  color: #ffffff;
  border-radius: 10rem;
}
.smart-style-2.minified #left-panel nav ul.navList li:hover a {
  color: #202427 !important;
}
.smart-style-2.minified .detail_save_btn:hover,
.smart-style-2.minified .detail_save_Btn:hover,
.smart-style-2.minified .approveBtn:hover,
.smart-style-2.minified .gotoEdi:hover,
.smart-style-2.minified .iom_btn:hover,
.smart-style-2.minified .detailInfo_save_btn:hover {
  color: #ffffff !important;
}
.smart-style-2.minified .ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #202124 !important;
}
.smart-style-2.minified #transaction-header .form-control::-moz-placeholder {
  color: #202124 !important;
}
.smart-style-2.minified #transaction-header .form-control::placeholder {
  color: #202124 !important;
}
.smart-style-2.minified .companyTooltip {
  background: #F7F7F7 0% 0% no-repeat padding-box;
  border: 1px solid #EDEDED;
  color: #4C4F53;
}
/*
---------------------------------------------------------------------------------------
    End White theme style
---------------------------------------------------------------------------------------
*/
.positionTable .bulkTable thead th {
  position: sticky;
  top: -5px;
}
.positionTable .bulkTable ::-webkit-scrollbar {
  width: 12px;
  background: red;
}
.positionTable .bulkTable ::-webkit-scrollbar-thumb {
  background: red;
  -webkit-border-radius: 1ex;
  -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
}
.positionTable .bulkTable ::-webkit-scrollbar-corner {
  background: #000;
}
.smallLang {
  display: block !important;
  position: absolute;
  top: 25px;
}
.smallLang .dropdown-toggle::after {
  color: #000 !important;
}
.smallLang .header-dropdown-list .dropdown-toggle .language-text {
  color: #212529 !important;
}
.smallLang .header-dropdown-list .dropdown-menu {
  background-color: #ffffff !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.smallLang .header-dropdown-list .dropdown-menu li a:hover {
  background-color: #EDEDED !important;
}
.smallLang .header-dropdown-list .dropdown-menu .active a {
  background-color: #EDEDED !important;
}
.smallLang .header-dropdown-list .dropdown-menu .dropdown-item {
  color: #212529 !important;
}
.commisionButton {
  margin-top: 23px !important;
}
.cancelButton {
  margin-left: 10px;
}
.copyIcon {
  padding-left: 10px;
}
.select-info {
  padding-left: 5px;
}
#header.colorChangeTest {
  background-color: #474d226e !important;
}
.company_nameField .ng-value-container input {
  margin-top: 5px;
}
.company_nameField .ng-select-container {
  min-height: 40px !important;
}
.cookie-text {
  font-size: 12px;
}
.show-consent {
  display: flex;
  display: -webkit-flex;
}
.cookie-consent {
  width: 100%;
  flex-wrap: wrap;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  color: #d6d6d6;
  background-color: #3c404d;
  padding: 0.6em 1.8em;
  z-index: 9999;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.consent-btn {
  color: #000;
  flex: 1 0 130px;
  background-color: #8B9635;
  border-color: transparent;
  padding: 0.2em 0.5em;
  font-size: 1.2em;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-weight: bolder;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  min-height: 20px;
  margin: 0 30px 10px 10px;
  max-width: 100px;
}
.priUrl {
  color: #8B9635 !important;
  text-decoration: none;
  font-weight: 500;
}
.notAllowed {
  cursor: not-allowed;
}
.notAllowed .ng-select-container {
  pointer-events: none;
}
.userProfile .applications .jarviswidget {
  margin: 0;
}
@media (max-width: 1200px) {
  .getClass {
    max-width: 33.33% !important;
  }
}
@media (max-width: 576px) {
  .getClass {
    max-width: 100% !important;
    margin-bottom: 5px;
  }
}
table thead tr th {
  font-size: 14px;
  font-weight: 400;
}
.dataTables_filter {
  display: none !important;
}
.dataTables_length {
  display: none;
}
.scroll-bulkdashboard .dataTables_length {
  display: block !important;
}
.scroll-bulkdashboard select.form-control.input-md {
  font-size: 14px;
  border-radius: 7px !important;
  margin-bottom: 15% !important;
}
.notPaidHead .idTooltip,
.notPaidList .idTooltip {
  height: auto;
  width: auto;
}
.notPaidPart .algntblTranscation {
  overflow: visible;
}
.notPaidHead,
.notPaidHead span {
  font-size: 12px;
}
.lstTrncnTbl tr.selected td a {
  color: #fff !important;
}
.deleteBtnDiv {
  position: relative;
}
.deleteBtnDiv .btnTooltip {
  position: absolute;
  left: 90px;
}
.disabledClass {
  pointer-events: none !important;
}
.disabledCursor {
  cursor: not-allowed !important;
}
.theme-green .bs-datepicker-head {
  background-color: #8B9635 !important;
}
.bs-datepicker-head button {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  height: 30px;
  line-height: 30px;
  border: 0;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: #000;
  transition: 0.3s;
}
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  color: #000;
}
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #8B9635;
  color: #000;
  transition: 0s;
}
.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #8B9635 !important;
  color: #000 !important;
  border: #666666 !important;
}
.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 0.5em;
  height: 0px;
  /* display: none; */
  /* box-shadow: 0 5px 5px -3px rgba(0,0,0,0.2), 0 8px 10px 1px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12); */
}
.infoFieldIcon {
  height: 14px;
  width: 14px;
  cursor: pointer;
}
.infoServiceFieldIcon {
  height: 14px;
  width: 14px;
  cursor: pointer;
}
.serviceForm formly-field .invalid-feedback formly-validation-message {
  font-size: 13px !important;
}
.form-group.has-error formly-wrapper-addons .input-group .input-group-append {
  border: 1px solid #dc3545 !important;
  border-left: none !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}
.form-group.has-error formly-wrapper-addons .input-group .input-addons {
  border: 1px solid #dc3545 !important;
  border-right: none !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}
.form-group.has-error formly-wrapper-addons .input-group .form-control.is-invalid {
  border: none !important;
}
.inputSection small,
.selectSection small {
  font-size: 13px !important;
}
.boxDiv {
  border-radius: 10px;
}
.boxDiv .boxClass {
  flex: 1 0 20%;
}
.boxDiv .boxClass .box {
  border-radius: 10px;
  margin: 10px;
}
.font-size-20 {
  font-size: 20px;
}
.font-size-14 {
  font-size: 14px;
}
@media (max-height: 770px) {
  ul.navList {
    height: 450px;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  ul.navList::-webkit-scrollbar-track {
    background-color: #000;
  }
  ul.navList::-webkit-scrollbar {
    width: 0px;
  }
}
@media (max-width: 657px) {
  .actions {
    display: contents !important;
  }

  .steps-container {
    display: flex !important;
  }
}
.dashboardTableList thead tr th {
  vertical-align: top !important;
}
.bulkinviteTableList thead tr th {
  vertical-align: middle !important;
}
.archiveButton {
  color: #fff !important;
  background-color: #202124 !important;
}
.templateBtn {
  border-radius: 15px !important;
  background: transparent !important;
  border: 2px solid #8B9635 !important;
  font-size: 13px !important;
  padding: 3px 15px !important;
  line-height: 19.5px !important;
  font-weight: 400 !important;
}
.templateBtn:hover {
  border-color: #8B9635 !important;
  background: #8B9635 !important;
  color: #000 !important;
}
.smart-style-1 .templateBtn {
  color: #ffffff;
}
.smart-style-2 .templateBtn {
  color: #000;
}
@media (max-width: 750px) {
  .menu-item.language-align {
    width: 50px !important;
    padding-left: 60px !important;
  }
}
.navFooter {
  position: absolute;
}
@media (max-width: 372px) {
  .navFooter {
    bottom: 170px !important;
    position: absolute;
  }
}
@media (min-width: 373px) and (max-width: 690px) {
  .navFooter {
    bottom: 120px !important;
    position: absolute;
  }
}
@media (max-width: 850px) {
  td.actionAlign {
    display: flex;
    flex-direction: column;
  }

  button.btn.gotoEdi.mb-1.ml-3 {
    margin-left: 0rem !important;
    margin-top: 5px !important;
  }
}
span.company_test-align {
  margin-top: 10px !important;
}
.smart-style-2 .lstTrncnTbl td a {
  color: #474D22;
}
@media (max-width: 478px) {
  button.btn.detail_save_Btn {
    margin-top: 10px !important;
  }
}
ng-dropdown-panel ::-webkit-scrollbar {
  width: 1px !important;
}
ng-dropdown-panel ::-webkit-scrollbar-track {
  background: #7e7e7e;
  /* color of the tracking area */
}
ng-dropdown-panel ::-webkit-scrollbar-thumb {
  background: #212529;
  /* color of the scroll thumb */
}
.status-align {
  text-align: center !important;
  width: 80px;
}
.subscription-style {
  border: 1px solid #333 !important;
  padding-bottom: 20px !important;
  border-radius: 8px !important;
  /* height: 200px; */
}
.smart-style-2 .subscription-style {
  border: 1px solid #c5c5c5 !important;
  padding-bottom: 20px !important;
  border-radius: 8px !important;
  /* height: 200px; */
}
.starter-align {
  margin-top: 20px !important;
}
.userDash_tileCount {
  font-size: 25px;
  font-weight: 500;
  margin-top: 12px !important;
}
.btn-align {
  margin-top: 0%;
  margin-bottom: 0%;
}
@media (max-width: 750px) {
  .commision-agent-align {
    flex: 100% !important;
  }
}
@media (max-width: 380px) {
  .commonFieldDiv.company_mode-align {
    display: block !important;
  }
}
label.ediLbl.commonLbl.companyModeStyle {
  margin-left: 0px !important;
}
@media (max-width: 1505px) {
  .ebilloverview-filterBtn {
    margin-bottom: 1rem !important;
  }
}
@media (max-width: 600px) {
  .divCl-align {
    width: 100% !important;
  }
}
@media (max-width: 597px) {
  .sectionDiv-align {
    display: block !important;
    width: 100% !important;
  }

  .Section-align {
    width: 100% !important;
  }

  .divCl-text-align {
    width: 100% !important;
  }
}
@media (max-width: 1898px) and (min-width: 598px) {
  .divCl-text-align {
    margin-top: -16px;
  }
}
@media (max-width: 597px) {
  .divCl-text-align {
    margin-top: -8px;
  }
}
@media (min-width: 1200px) {
  .transTable-align {
    height: 370px;
  }
}
@media (max-width: 1200px) {
  .tableDatas-align {
    padding-right: 0px !important;
  }

  .headerContent-align {
    margin-top: 20px !important;
  }
}
.smart-style-1 .card-header-align {
  background-color: #0C1214 !important;
}
@media (max-width: 1400px) {
  .wizardCircleWidth-align {
    flex: 1 0 50% !important;
  }
}
@media (max-width: 576px) {
  .communication_btn {
    margin-left: 15%;
    width: auto;
  }
}
.smart-style-1 .search_status-bg-clr {
  background-color: #171717 !important;
}
.algntblTranscation-style {
  margin-top: 1.5rem !important;
}
.tranheader-style {
  padding: 7px !important;
}
@media (max-width: 700px) {
  .btnSection-align {
    width: 100% !important;
  }
}
.item1 .multiselect-item-checkbox {
  border-bottom: 1px solid #333 !important;
  padding: 10px !important;
}
.tranheader-align {
  padding-bottom: 10px !important;
}
.page-title-align {
  margin-bottom: 7px !important;
}
.row-table-align {
  overflow-y: auto !important;
}
.invoice_status-align {
  padding-left: 5px !important;
}
.history-table-div {
  overflow-x: auto;
}
.history-table-div::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.history-table-div::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: transparent;
}
.history-table-div::-webkit-scrollbar-thumb {
  background-color: #CCC;
  border-radius: 5px;
}
.setCirclehold {
  color: orange;
  font-size: 10px !important;
}
.smart-style-1 a.user_mngmnt_resnd_pswd {
  color: #8B9635;
}
.user_mngmnt_pswrd_rsnd {
  display: flex;
  justify-content: end;
}
.user_toggle_style_active {
  color: #8B9635;
}
.user_toggle_style_inactive {
  color: #CCC;
}
.archive-data-table tbody tr.selected .user_toggle_style_active {
  color: #000000;
}
.archive-data-table tbody tr.selected .user_toggle_style_inactive {
  color: #000;
}
.archive-data-table tbody tr.selected .editRole {
  border: 2px solid #000000 !important;
  color: #000 !important;
}
.interConnPopupForm small {
  font-size: 12px !important;
  color: red !important;
}
.ellipsis_col {
  white-space: nowrap;
  width: calc(100% - 15px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.transactionsTable tbody tr td {
  padding: 8px 6px !important;
}
.select-info {
  display: none;
}
.g_id_signin {
  display: none !important;
}
select:disabled {
  opacity: 0.5;
}
td.company_adress {
  width: 15% !important;
}
.disabled-row {
  pointer-events: none !important;
  color: #484343 !important;
}
.main-title {
  font-size: 16px !important;
  font-family: Open Sans, Arial, Helvetica, sans-serif;
}
.needInput .custom-radio.custom-control {
  display: inline-block;
  margin-right: 10px;
}
nav ul li a {
  padding: 12px 0px 12px 12px !important;
}
nav ul ul li > a {
  padding-left: 40px !important;
}
.transactions-data-table th, .transactions-data-table td {
  white-space: nowrap;
}
.transactions-data-table th {
  display: table-cell !important;
}
/* Ensure all table data (td) cells are visible */
.transactions-data-table td {
  display: table-cell !important;
}
.sender_ellipsis_col {
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
/* Sender column */
.transactions-data-table th:nth-child(4),
.transactions-data-table td:nth-child(4) {
  width: 25% !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* Receiver column */
.transactions-data-table th:nth-child(5),
.transactions-data-table td:nth-child(5) {
  width: 25% !important;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.transactions-data-table {
  table-layout: fixed;
  width: 100%;
}
.document_ellipsis_col {
  white-space: nowrap;
  width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.smart-style-2 .emailLbl {
  color: #212529 !important;
}
.smart-style-1 .emailLbl {
  color: #fff !important;
}
.matchingCompany {
  word-break: break-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.create-time {
  word-wrap: break-word;
  width: 100%;
  white-space: normal;
}
